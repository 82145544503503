import { Currency, currencyEquals, ETHER, Token } from "seek-swap-sdk";
import React, { useCallback, useContext, useMemo } from "react";
import { FixedSizeList } from "react-window";

import {
  useSelectedTokenList,
  WrappedTokenInfo,
} from "../../../state/lists/hooks";
import {
  useAddUserToken,
  useRemoveUserAddedToken,
} from "../../../state/user/hooks";
import { useCurrencyBalance } from "../../../state/wallet/hooks.js";
import { useIsUserAddedToken } from "../../../hooks/Tokens";
import { isTokenOnList } from "../../../utils";
import CurrencyLogo from "../CurrencyLogo/CurrencyLogo";
import {
  Box,
  Button,
  CircularProgress,
  Divider,
  useTheme,
} from "@mui/material";
import theme from "../../../../theme";
import { AppContext } from "../../../utils/Utils";

function currencyKey(currency) {
  return currency instanceof Token
    ? currency.address
    : currency === ETHER
    ? "ETHER"
    : "";
}

function Balance({ balance }) {
  const theme = useTheme();
  return (
    <Box color={theme.palette.common.black} title={balance.toExact()}>
      {balance.toSignificant(4)}
    </Box>
  );
}

function CurrencyRow({ currency, onSelect, isSelected, otherSelected, style }) {
  const { account, chainId } = useContext(AppContext);

  const key = currencyKey(currency);
  const selectedTokenList = useSelectedTokenList();
  const isOnSelectedList = isTokenOnList(selectedTokenList, currency);
  const customAdded = useIsUserAddedToken(currency);
  const balance = useCurrencyBalance(account ?? undefined, currency);
  const removeToken = useRemoveUserAddedToken();
  const addToken = useAddUserToken();
  const theme = useTheme();
  // only show add or remove buttons if not on selected list
  return (
    <>
      <Box
        // style={style}
        className={`token-item-${key}`}
        onClick={() => (isSelected ? null : onSelect())}
        sx={{
          opacity: isSelected ? 0.5 : 1,
          cursor: isSelected ? "no-drop" : "pointer",
        }}
        selected={otherSelected}
        display="flex"
        justifyContent="space-between"
        p={2}
      >
        <Box display="flex" alignItems="center" color="primary.contrastText">
          <CurrencyLogo currency={currency} size="24px" />
          <Box ml={1} color="common.black" title={currency.name}>
            {currency.symbol}
          </Box>
          <Box color={theme.palette.common.black} ml={1}>
            {!isOnSelectedList &&
            customAdded &&
            !(currency instanceof WrappedTokenInfo) ? (
              <Box>
                Added by user
                <Button
                  onClick={(event) => {
                    event.stopPropagation();
                    if (chainId && currency instanceof Token)
                      removeToken(chainId, currency.address);
                  }}
                  style={{ color: theme.palette.common.black }}
                >
                  (Remove)
                </Button>
              </Box>
            ) : null}
            {!isOnSelectedList &&
            !customAdded &&
            !(currency instanceof WrappedTokenInfo) ? (
              <Box>
                Found by address
                <Button
                  onClick={(event) => {
                    event.stopPropagation();
                    if (currency instanceof Token) addToken(currency);
                  }}
                  style={{ color: theme.palette.common.black }}
                >
                  (Add)
                </Button>
              </Box>
            ) : null}
          </Box>
        </Box>
        {/* <TokenTags currency={currency} /> */}
        <Box color="primary.contrastText" style={{ justifySelf: "flex-end" }}>
          {balance ? (
            <Balance balance={balance} />
          ) : account ? (
            <CircularProgress size={20} />
          ) : null}
        </Box>
      </Box>
      <Divider
        style={{
          width: "100%",
          backgroundColor: theme.palette.secondary.main,
          height: "1px",
        }}
      />
    </>
  );
}

export default function CurrencyList({
  height,
  currencies,
  selectedCurrency,
  onCurrencySelect,
  otherCurrency,
  fixedListRef,
  showETH,
}) {
  const itemData = useMemo(
    () => (showETH ? [Currency.ETHER, ...currencies] : [...currencies]),
    [currencies, showETH]
  );

  const Row = useCallback(
    ({ data, index, style }) => {
      const currency = data[index];
      const isSelected = Boolean(
        selectedCurrency && currencyEquals(selectedCurrency, currency)
      );
      const otherSelected = Boolean(
        otherCurrency && currencyEquals(otherCurrency, currency)
      );
      const handleSelect = () => onCurrencySelect(currency);
      return (
        <CurrencyRow
          style={style}
          currency={currency}
          isSelected={isSelected}
          onSelect={handleSelect}
          otherSelected={otherSelected}
        />
      );
    },
    [onCurrencySelect, otherCurrency, selectedCurrency]
  );

  const itemKey = useCallback((index, data) => currencyKey(data[index]), []);

  return (
    <Box className="eth_modalItemsScroll">
      <FixedSizeList
        style={{ overflow: "hidden !important" }}
        height={290}
        ref={fixedListRef}
        width="100%"
        itemData={itemData}
        itemCount={itemData.length}
        itemSize={56}
        itemKey={itemKey}
      >
        {Row}
      </FixedSizeList>
    </Box>
  );
}
