import React from "react";
import { Box, Button } from "@mui/material";

export default function TradePrice({ price, showInverted, setShowInverted }) {
  const formattedPrice = showInverted
    ? price?.toSignificant(6)
    : price?.invert()?.toSignificant(6);

  const show = Boolean(price?.baseCurrency && price?.quoteCurrency);
  const label = showInverted
    ? `${price?.quoteCurrency?.symbol} per ${price?.baseCurrency?.symbol}`
    : `${price?.baseCurrency?.symbol} per ${price?.quoteCurrency?.symbol}`;

  return (
    <Box
      fontSize="14px"
      style={{
        justifyContent: "center",
        alignItems: "center",
        display: "flex",
        padding: "0px",
      }}
    >
      {show ? (
        <>
          {formattedPrice ?? "-"} {label}
          <Button onClick={() => setShowInverted(!showInverted)}>
            <i className="fad fa-exchange"></i>
          </Button>
        </>
      ) : (
        "-"
      )}
    </Box>
  );
}
