import React from "react";
import { withStyles } from "@mui/styles";
import Button from "@mui/material/Button";
import theme from "../../../../theme";

const CustomButton = withStyles({
  root: {
    fontFamily: "'Russo One'",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "12.7153px",
    lineHeight: "15px",
    background: "#F1620A",
    borderRadius: "47.063px",
    width: "70%",
    color: "white",
    height: "42px",
    "&:hover": {
      backgroundColor: "#F1620A",
      borderColor: "#F1620A",
      boxShadow: "none",
    },

    "&:disabled": {
      backgroundColor: "#ccc1ff",
      boxShadow: "#ffcdd6",
    },
  },
})(Button);

export default function CustomizedButton({ children, fun, disabled }) {
  return (
    <CustomButton
      onClick={fun}
      disabled={disabled}
      style={{
        fontFamily: "'Russo One'",
        fontStyle: "normal",
        fontWeight: 400,
        fontSize: "12.7153px",
        lineHeight: "15px",
        background: "#F1620A",
        borderRadius: "47.063px",
        width: "70%",
        color: "white",
        height: "42px",
      }}
    >
      {children}
    </CustomButton>
  );
}
