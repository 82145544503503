import React from "react";
import { TradeType } from "seek-swap-sdk";
import { Field } from "../../../state/swap/actions";
import { useUserSlippageTolerance } from "../../../state/user/hooks";
import {
  computeSlippageAdjustedAmounts,
  computeTradePriceBreakdown,
} from "../../../utils/prices";
import FormattedPriceImpact from "./FormattedPriceImpact";
import SwapRoute from "./SwapRoute";
import { Box, Container, Divider, IconButton, Tooltip } from "@mui/material";
import { withStyles } from "@mui/styles";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";

import theme from "../../../../theme";

export const LightTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.secondary.main,
    color: "#ffffff",
    boxShadow: theme.shadows[1],
    fontSize: 14,
    fontWeight: 400,
    padding: 20,
  },
}))(Tooltip);

function TradeSummary({ trade, allowedSlippage }) {
  const { priceImpactWithoutFee, realizedLPFee } =
    computeTradePriceBreakdown(trade);
  const isExactIn = trade.tradeType === TradeType.EXACT_INPUT;
  const slippageAdjustedAmounts = computeSlippageAdjustedAmounts(
    trade,
    allowedSlippage
  );
  return (
    <Container>
      <Box>
        <Box>
          <Box>
            <Box color={theme.palette.common.black} fontSize="14px">
              {isExactIn ? "Minimum received" : "Maximum sold"}
            </Box>
            <LightTooltip title="Your transaction will revert if there is a large, unfavorable price movement before it is confirmed.">
              <IconButton aria-label="delete">
                <HelpOutlineIcon
                  style={{ color: theme.palette.common.black }}
                />
              </IconButton>
            </LightTooltip>
          </Box>
          <Box>
            <Box color={theme.palette.common.black} fontSize="14px">
              {isExactIn
                ? `${slippageAdjustedAmounts[Field.OUTPUT]?.toSignificant(4)} ${
                    trade.outputAmount.currency.symbol
                  }` ?? "-"
                : `${slippageAdjustedAmounts[Field.INPUT]?.toSignificant(4)} ${
                    trade.inputAmount.currency.symbol
                  }` ?? "-"}
            </Box>
          </Box>
        </Box>
        <Box>
          <Box>
            <Box color={theme.palette.common.black} fontSize="14px">
              Price Impact
            </Box>
            <LightTooltip title="The difference between the market price and estimated price due to trade size.">
              <IconButton aria-label="delete">
                <HelpOutlineIcon
                  style={{ color: theme.palette.common.black }}
                />
              </IconButton>
            </LightTooltip>
          </Box>
          <FormattedPriceImpact priceImpact={priceImpactWithoutFee} />
        </Box>

        <Box>
          <Box>
            <Box color={theme.palette.common.black} fontSize="14px">
              {"Liquidity Provider Fee"}
            </Box>
            <LightTooltip title="For each trade a 0.2% fee is paid. 0.17% goes to liquidity providers and 0.03% goes to the PancakeSwap treasury.">
              <IconButton aria-label="delete">
                <HelpOutlineIcon
                  style={{ color: theme.palette.common.black }}
                />
              </IconButton>
            </LightTooltip>
          </Box>
          <Box color={theme.palette.common.black} fontSize="14px">
            {realizedLPFee
              ? `${realizedLPFee.toSignificant(4)} ${
                  trade.inputAmount.currency.symbol
                }`
              : "-"}
          </Box>
        </Box>
      </Box>
    </Container>
  );
}

export function AdvancedSwapDetails({ trade }) {
  const [allowedSlippage] = useUserSlippageTolerance();
  const showRoute = Boolean(trade && trade.route.path.length > 2);

  return (
    <Container gap="md">
      {trade && (
        <>
          <TradeSummary trade={trade} allowedSlippage={allowedSlippage} />
          {showRoute && (
            <>
              <Divider
                style={{ width: "100%", height: "1px", color: "#000" }}
              />
              <Box style={{ padding: "0 24px" }}>
                <Box>
                  <Box color={theme.palette.common.black} fontSize="14px">
                    Route
                  </Box>
                  <LightTooltip title="Routing through these tokens resulted in the best price for your trade.">
                    <IconButton aria-label="delete">
                      <HelpOutlineIcon
                        style={{ color: theme.palette.common.black }}
                      />
                    </IconButton>
                  </LightTooltip>
                </Box>
                <SwapRoute trade={trade} />
              </Box>
            </>
          )}
        </>
      )}
    </Container>
  );
}
