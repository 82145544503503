import React from "react";
import { AlertTriangle } from "react-feather";
import { Box, Button, IconButton, useTheme } from "@mui/material";
import { Close } from "@mui/icons-material";

export const ContentHeader = ({ children, onDismiss }) => {
  const theme = useTheme();
  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      color="#fff"
      width="100%"
      bgcolor={theme.palette.secondary.main}
      p={1}
    >
      <Box
        color={theme.palette.secondary.contrastText}
        fontSize="19px"
        textAlign="center"
        fontWeight="600"
        fontFamily="Segoe UI"
      >
        Confirm Swap
      </Box>
      <IconButton onClick={onDismiss} variant="text">
        <Close style={{ color: theme.palette.secondary.contrastText }} />
      </IconButton>
    </Box>
  );
};

const TransactionErrorContent = ({ message, onDismiss }) => {
  const theme = useTheme();
  return (
    <Box>
      <Box width="400px">
        <ContentHeader onDismiss={onDismiss}>Error</ContentHeader>
        <Box
          style={{ marginTop: 20, padding: "2rem 0" }}
          gap="24px"
          justify="center"
        >
          <AlertTriangle color="red" style={{ strokeWidth: 1.5 }} size={64} />
          <Box
            color={theme.palette.common.black}
            fontSize="19px"
            textAlign="center"
            fontWeight="400"
            fontFamily="Segoe UI"
          >
            {message}
          </Box>
        </Box>
      </Box>
      <Button
        style={{
          background: theme.palette.secondary.main,
          width: "90%",
          height: "50px",
          color: "#fff",
          fontSize: "17px",
          fontWeight: 400,
        }}
        onClick={onDismiss}
      >
        Dismiss
      </Button>
    </Box>
  );
};

export default TransactionErrorContent;
