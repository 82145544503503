import { Box, useTheme } from "@mui/material";
import React, { useContext, useState } from "react";
import useTotalSupply from "../../../data/TotalSupply";

import { useTokenBalance } from "../../../state/wallet/hooks";
import { unwrappedToken } from "../../../utils/wrappedCurrency";
import { JSBI } from "seek-swap-sdk";
import DoubleCurrencyLogo from "../DoubleCurrencyLogo/DoubleCurrencyLogo";
import { AppContext } from "../../../utils/Utils";

export default function LPTokensBox({ pair, showUnwrapped = false }) {
  const theme = useTheme();
  const { account, chainId } = useContext(AppContext);

  const currency0 = showUnwrapped ? pair.token0 : unwrappedToken(pair.token0);
  const currency1 = showUnwrapped ? pair.token1 : unwrappedToken(pair.token1);

  const [showMore, setShowMore] = useState(false);

  const userPoolBalance = useTokenBalance(
    account ?? undefined,
    pair.liquidityToken
  );
  const totalPoolTokens = useTotalSupply(pair.liquidityToken);

  const [token0Deposited, token1Deposited] =
    !!pair &&
    !!totalPoolTokens &&
    !!userPoolBalance &&
    // this condition is a short-circuit in the case where useTokenBalance updates sooner than useTotalSupply
    JSBI.greaterThanOrEqual(totalPoolTokens.raw, userPoolBalance.raw)
      ? [
          pair.getLiquidityValue(
            pair.token0,
            totalPoolTokens,
            userPoolBalance,
            false
          ),
          pair.getLiquidityValue(
            pair.token1,
            totalPoolTokens,
            userPoolBalance,
            false
          ),
        ]
      : [undefined, undefined];
  return (
    <Box display="flex" justifyContent="center" mt="40px">
      <Box
        display="flex"
        flexDirection="column"
        borderRadius="20px"
        width="300px"
        bgcolor={"#202A80"}
        p={2}
      >
        <Box
          sx={{
            fontFamily: "'Roboto'",
            fontStyle: "normal",
            fontWeight: 400,
            fontSize: "16.7335px",
            // textAlign: "center",
            color: "white",
          }}
        >
          LP Tokens In Your Wallet
        </Box>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Box
            display="flex"
            justifyContent="space-between"
            textAlign="center"
            sx={{
              fontFamily: "'Roboto'",
              fontStyle: "normal",
              fontWeight: 400,
              fontSize: "16.7335px",
              // textAlign: "center",
              color: "white",
            }}
            mt={1}
            onClick={() => setShowMore(!showMore)}
          >
            <DoubleCurrencyLogo
              currency0={currency0}
              currency1={currency1}
              size={20}
            />
            {currency0.symbol}/{currency1.symbol}
          </Box>
          <Box
            display="flex"
            justifyContent="space-between"
            textAlign="center"
            sx={{
              fontFamily: "'Roboto'",
              fontStyle: "normal",
              fontWeight: 400,
              fontSize: "16.7335px",
              // textAlign: "center",
              color: "white",
            }}
            mt={1}
          >
            {userPoolBalance ? userPoolBalance.toSignificant(4) : "-"}
          </Box>
        </Box>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Box
            display="flex"
            justifyContent="space-between"
            textAlign="center"
            sx={{
              fontFamily: "'Roboto'",
              fontStyle: "normal",
              fontWeight: 400,
              fontSize: "16.7335px",
              // textAlign: "center",
              color: "white",
            }}
            mt={1}
          >
            {currency0.symbol}:
          </Box>
          <Box
            display="flex"
            justifyContent="space-between"
            textAlign="center"
            sx={{
              fontFamily: "'Roboto'",
              fontStyle: "normal",
              fontWeight: 400,
              fontSize: "16.7335px",
              // textAlign: "center",
              color: "white",
            }}
            mt={1}
          >
            {token0Deposited ? token0Deposited?.toSignificant(6) : "-"}
          </Box>
        </Box>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Box
            display="flex"
            justifyContent="space-between"
            textAlign="center"
            sx={{
              fontFamily: "'Roboto'",
              fontStyle: "normal",
              fontWeight: 400,
              fontSize: "16.7335px",
              // textAlign: "center",
              color: "white",
            }}
            mt={1}
          >
            {currency1.symbol}:
          </Box>
          <Box
            display="flex"
            justifyContent="space-between"
            textAlign="center"
            sx={{
              fontFamily: "'Roboto'",
              fontStyle: "normal",
              fontWeight: 400,
              fontSize: "16.7335px",
              // textAlign: "center",
              color: "white",
            }}
            mt={1}
          >
            {token1Deposited ? token1Deposited?.toSignificant(6) : "-"}
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
