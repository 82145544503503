import { Box, Button, Container, Grid, Typography } from "@mui/material";
import { Stack } from "@mui/system";
import React from "react";
import winningbg from "./assets/winning.png";

function WinningCriteria() {
  return (
    <Container maxWidth="md">
      <Grid container direction="row-reverse" my={5} spacing={4}>
        <Grid item xs={12} sm={6} md={6}>
          <Box height="100%" my={2} borderRadius="15px" position="relative">
            <Typography variant="h5" fontFamily="Russo One" color="white">
              Winning Criteria
            </Typography>
            <Typography
              variant="body1"
              mt={1}
              color="white"
              textAlign="justify"
            >
              <ul>
                <li>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                </li>
                <li>Phasellus eu tortor a eros malesuada cursus.</li>
                <li>
                  Proin eu erat facilisis, pharetra lectus ac, efficitur est.
                </li>
                <li>
                  Nullam non ex imperdiet, feugiat sapien eget, viverra orci.
                </li>
                <li>Sed pulvinar enim id libero pulvinar varius.</li>
              </ul>
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12} sm={6} md={6}>
          <Box
            sx={{
              background: `url(${winningbg})`,
              minHeight: { xs: "250px", sm: "300px" },
              width: "100%",
              backgroundRepeat: "no-repeat",
              backgroundSize: "100% 100%",
            }}
          ></Box>
        </Grid>
      </Grid>
    </Container>
  );
}

export default WinningCriteria;
