import React, { useContext } from "react";
import { withStyles } from "@mui/styles";
import {
  Dialog,
  DialogContent,
  Box,
  useMediaQuery,
  CircularProgress,
  Button,
} from "@mui/material";
import { useTheme } from "@mui/material";

import CustomizedButton from "../small components/CustomButton/CustomButton";
import { AppContext } from "../../utils/Utils";
const StyledModal = withStyles((theme) => ({
  root: {
    "& .MuiDialog-root": {
      zIndex: "1301 !important",
    },
    "&.MuiDialog-container": {
      overflowY: "hidden !important",
    },
    "& .MuiDialog-paperScrollPaper": {
      backgroundColor: "tranparent !important",
      height: "auto",

      boxShadow: "0px 0px 13px 0px  rgb(0, 0 ,0)",
      borderRadius: "30px !important",
    },
    "& .dialoge__content__section": {
      background: "#202A80 0% 0% no-repeat padding-box",
      // border: "1px solid #f1f5f91f",
      borderRadius: "20px",
    },
  },
}))(Dialog);

function TransectionModal({
  isOpen,
  onDismiss,
  attemptingTxn,
  hash,
  pendingText,

  modalBottom,
  modalHeader,
  swapErrorMessage,
  errorContent,
}) {
  const theme = useTheme();

  const { account, signer } = useContext(AppContext);
  const matches = useMediaQuery("(max-width:600px)");

  const handleClose = () => {
    onDismiss();
  };
  const PendingContent = (
    <>
      <Box
        paddingLeft="20px"
        paddingRight="20px"
        paddingTop="25px"
        display="flex"
        alignItems="center"
        bgcolor={"#202A80"}
        justifyContent="space-between"
        pb="20px"
      >
        <Box
          sx={{
            fontFamily: "'Roboto'",
            fontStyle: "normal",
            fontWeight: 400,
            fontSize: "16px",
            color: "white",
          }}
        >
          Processing Transaction
        </Box>
        <Box>
          <i
            onClick={handleClose}
            style={{
              color: theme.palette.secondary.contrastText,
              textAlign: "right",
              fontSize: "23px",
              cursor: "pointer",
              paddingTop: "7px",
            }}
            className="fal fa-times"
          ></i>
        </Box>
      </Box>
      <Box mt={3}>
        <CircularProgress size="70px" />
      </Box>
      <Box
        mt={3}
        sx={{
          fontFamily: "'Roboto'",
          fontStyle: "normal",
          fontWeight: 400,
          fontSize: "16px",
          color: "white",
        }}
      >
        {pendingText}
      </Box>
    </>
  );
  const CompletedContent = (
    <>
      <Box
        paddingLeft="20px"
        paddingRight="20px"
        paddingTop="25px"
        display="flex"
        alignItems="center"
        bgcolor={"#202A80"}
        justifyContent="space-between"
        pb="20px"
      >
        <Box
          sx={{
            fontFamily: "'Roboto'",
            fontStyle: "normal",
            fontWeight: 400,
            fontSize: "16px",
            color: "white",
          }}
        >
          Waiting for confirmation
        </Box>
        <Box>
          <i
            onClick={handleClose}
            style={{
              color: "white",
              textAlign: "right",
              fontSize: "23px",
              cursor: "pointer",
              paddingTop: "7px",
            }}
            className="fal fa-times"
          ></i>
        </Box>
      </Box>
      <Box display="flex" flexDirection="column" alignItems="center" mt={4}>
        <Box>
          <i
            style={{
              color: "white",
              textAlign: "center",
              fontSize: "60px",
              fontWeight: "600",
            }}
            className="fal fa-arrow-circle-up"
          ></i>
        </Box>
      </Box>
      <Box my={3}>
        <a
          href={`https://bscscan.com/tx/${hash}`}
          target="_blank"
          style={{
            cursor: "pointer",
            color: "white",
            fontFamily: "Segoe UI",
            fontSize: "17px",
            fontWeight: "600",
            borderRadius: "10px",
            padding: "5px 10px",
            textDecoration: "none",
          }}
        >
          View On BscScan
          <i
            style={{
              color: "white",
              textAlign: "right",
              fontWeight: "500",
              fontSize: "20px",
              cursor: "pointer",
              marginLeft: "10px",
            }}
            className="fal fa-external-link"
          ></i>
        </a>
      </Box>
      <CustomizedButton
        sx={{
          fontFamily: "'Roboto'",
          fontStyle: "normal",
          fontWeight: 400,
          fontSize: "16px",
          color: "white",
        }}
        fun={onDismiss}
      >
        Close
      </CustomizedButton>
    </>
  );
  const { chainId } = useContext(AppContext);

  if (!chainId) return null;
  return (
    <>
      <StyledModal
        keepMounted
        open={isOpen}
        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogContent className="dialoge__content__section">
          <div
            style={{
              textAlign: "center",
              paddingBottom: "30px",
              width: matches ? "300px" : "400px",
            }}
          >
            {attemptingTxn ? (
              [PendingContent]
            ) : hash ? (
              [CompletedContent]
            ) : swapErrorMessage ? (
              <>{errorContent}</>
            ) : (
              <>
                {modalHeader}
                {modalBottom}
              </>
            )}
          </div>
        </DialogContent>
      </StyledModal>
    </>
  );
}
export default TransectionModal;
