import {
  Box,
  Button,
  Container,
  Grid,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React, { useCallback, useContext, useState } from "react";
import { withStyles } from "@mui/styles";
import { useCurrency } from "../../../hooks/Tokens";
import { styles } from "../styles/style";
import { ONE_BIPS } from "../../../constants";
import {
  useDerivedMintInfo,
  useMintActionHandlers,
  useMintState,
} from "../../../state/mint/hooks";
import { BigNumber } from "@ethersproject/bignumber";
import { currencyEquals, ETHER, WETH } from "seek-swap-sdk";
import { CachedIcon, stylelines, Line } from "../../../utils/images";
import { useTheme } from "@mui/material";
import {
  ApprovalState,
  useApproveCallback,
} from "../../../hooks/useApproveCallback";
import {
  useIsExpertMode,
  useUserDeadline,
  useUserSlippageTolerance,
} from "../../../state/user/hooks";
import { PairState } from "../../../data/Reserves";
import { Field } from "../../../state/mint/actions";
import { maxAmountSpend } from "../../../utils/maxAmountSpend";
import { ROUTER_ADDRESS } from "../../../constants";
import { useTransactionAdder } from "../../../state/transactions/hooks";
import {
  calculateGasMargin,
  calculateSlippageAmount,
  getRouterContract,
} from "../../../utils";
import { currencyId } from "../../../utils/currencyId";
import { wrappedCurrency } from "../../../utils/wrappedCurrency";
import CurrencyInput from "../../small components/currency input/CurrencyInput";
import { TopNav } from "../../small components/top nav/TopNav";
import { LiquidityTopNav } from "../../small components/box header/ModalHeader";
import ConfirmAddModalBottom from "./ConfirmAddModalBottom";
import DoubleCurrencyLogo from "../../small components/DoubleCurrencyLogo/DoubleCurrencyLogo";
import TransectionModal from "../../models/TransectionSubmittedModal";
import CustomizedButton from "../../small components/CustomButton/CustomButton";
import LPTokensBox from "../../small components/LPtokens box/LPTokensBox";
import { AppContext } from "../../../utils/Utils";
import axios from "axios";
import { url } from "../../../constants/lists";

export const UniswapAddLiquidity = withStyles(styles)(
  ({
    classes,
    match: {
      params: { currencyIdA, currencyIdB },
    },
    history,
  }) => {
    const [rotaion, setRotation] = useState(false);
    const matches = useMediaQuery("(max-width:600px)");
    const theme = useTheme();
    const { account, chainId, signer, connect } = useContext(AppContext);
    const currencyA = useCurrency(currencyIdA);
    const currencyB = useCurrency(currencyIdB);
    let curreA = wrappedCurrency(currencyA, chainId);
    let curreB = wrappedCurrency(currencyB, chainId);
    // const TranslateString = useI18n();
    const oneCurrencyIsWBNB = Boolean(
      chainId &&
        ((currencyA && currencyEquals(currencyA, WETH[chainId])) ||
          (currencyB && currencyEquals(currencyB, WETH[chainId])))
    );
    const expertMode = useIsExpertMode();

    // mint state
    const { independentField, typedValue, otherTypedValue } = useMintState();
    const {
      dependentField,
      currencies,
      pair,
      pairState,
      currencyBalances,
      parsedAmounts,
      price,
      noLiquidity,
      liquidityMinted,
      poolTokenPercentage,
      error,
    } = useDerivedMintInfo(currencyA ?? undefined, currencyB ?? undefined);

    const { onFieldAInput, onFieldBInput } = useMintActionHandlers(noLiquidity);

    const isValid = !error;

    // modal and loading
    const [showConfirm, setShowConfirm] = useState(false);
    const [attemptingTxn, setAttemptingTxn] = useState(false); // clicked confirm

    // txn values
    const [deadline] = useUserDeadline(); // custom from users settings
    const [allowedSlippage] = useUserSlippageTolerance(); // custom from users
    const [txHash, setTxHash] = useState("");
    const [openConnectModal, setopenConnectModal] = useState(false);

    // get formatted amounts
    const formattedAmounts = {
      [independentField]: typedValue,
      [dependentField]: noLiquidity
        ? otherTypedValue
        : parsedAmounts[dependentField]?.toSignificant(6) ?? "",
    };

    // get the max amounts user can add
    const maxAmounts = [Field.CURRENCY_A, Field.CURRENCY_B].reduce(
      (accumulator, field) => {
        return {
          ...accumulator,
          [field]: maxAmountSpend(currencyBalances[field]),
        };
      },
      {}
    );

    const atMaxAmounts = [Field.CURRENCY_A, Field.CURRENCY_B].reduce(
      (accumulator, field) => {
        return {
          ...accumulator,
          [field]: maxAmounts[field]?.equalTo(parsedAmounts[field] ?? "0"),
        };
      },
      {}
    );

    // check whether the user has approved the router on the tokens
    const [approvalA, approveACallback] = useApproveCallback(
      parsedAmounts[Field.CURRENCY_A],
      ROUTER_ADDRESS
    );
    const [approvalB, approveBCallback] = useApproveCallback(
      parsedAmounts[Field.CURRENCY_B],
      ROUTER_ADDRESS
    );

    const addTransaction = useTransactionAdder();

    const onAdd = async () => {
      if (!chainId || !signer || !account) return;
      const router = getRouterContract(chainId, signer, account);

      const {
        [Field.CURRENCY_A]: parsedAmountA,
        [Field.CURRENCY_B]: parsedAmountB,
      } = parsedAmounts;
      if (!parsedAmountA || !parsedAmountB || !currencyA || !currencyB) {
        return;
      }

      const amountsMin = {
        [Field.CURRENCY_A]: calculateSlippageAmount(
          parsedAmountA,
          noLiquidity ? 0 : allowedSlippage
        )[0],
        [Field.CURRENCY_B]: calculateSlippageAmount(
          parsedAmountB,
          noLiquidity ? 0 : allowedSlippage
        )[0],
      };

      const deadlineFromNow = Math.ceil(Date.now() / 1000) + deadline;

      let estimate;
      let method;
      let args;
      let value;
      if (currencyA === ETHER || currencyB === ETHER) {
        const tokenBIsBNB = currencyB === ETHER;
        estimate = router.estimateGas.addLiquidityETH;
        method = router.addLiquidityETH;
        args = [
          wrappedCurrency(tokenBIsBNB ? currencyA : currencyB, chainId)
            ?.address ?? "", // token
          (tokenBIsBNB ? parsedAmountA : parsedAmountB).raw.toString(), // token desired
          amountsMin[
            tokenBIsBNB ? Field.CURRENCY_A : Field.CURRENCY_B
          ].toString(), // token min
          amountsMin[
            tokenBIsBNB ? Field.CURRENCY_B : Field.CURRENCY_A
          ].toString(), // eth min
          account,
          deadlineFromNow,
        ];
        value = BigNumber.from(
          (tokenBIsBNB ? parsedAmountB : parsedAmountA).raw.toString()
        );
      } else {
        estimate = router.estimateGas.addLiquidity;
        method = router.addLiquidity;
        args = [
          wrappedCurrency(currencyA, chainId)?.address ?? "",
          wrappedCurrency(currencyB, chainId)?.address ?? "",
          parsedAmountA.raw.toString(),
          parsedAmountB.raw.toString(),
          amountsMin[Field.CURRENCY_A].toString(),
          amountsMin[Field.CURRENCY_B].toString(),
          account,
          deadlineFromNow,
        ];
        value = null;
      }

      setAttemptingTxn(true);
      // const aa = await estimate(...args, value ? { value } : {})
      await estimate(...args, value ? { value } : {})
        .then((estimatedGasLimit) =>
          method(...args, {
            ...(value ? { value } : {}),
            gasLimit: calculateGasMargin(estimatedGasLimit),
          }).then((response) => {
            setAttemptingTxn(false);
            if (pair) {
              let tokenCurrencies =
                curreA.address.toLowerCase() < curreB.address.toLowerCase()
                  ? [curreA, curreB]
                  : [curreB, curreA];
              axios.post(url + "/add-liquidity", {
                tx: response,
                token1: tokenCurrencies[0].address,
                decimals1: tokenCurrencies[0].decimals,
                token2: tokenCurrencies[1].address,
                decimals2: tokenCurrencies[1].decimals,
              });
            }
            addTransaction(response, {
              summary: `Add ${parsedAmounts[Field.CURRENCY_A]?.toSignificant(
                3
              )} ${currencies[Field.CURRENCY_A]?.symbol} and ${parsedAmounts[
                Field.CURRENCY_B
              ]?.toSignificant(3)} ${currencies[Field.CURRENCY_B]?.symbol}`,
            });

            setTxHash(response.hash);
          })
        )
        .catch((e) => {
          setAttemptingTxn(false);
          // we only care if the error is something _other_ than the user rejected the tx
          if (e?.code !== 4001) {
            console.error(e);
          }
        });
    };

    const onSwitchTokens = useCallback(() => {
      history.push(`/add/${currencyIdB}/${currencyIdA}`);
    }, [currencyIdB, currencyIdA]);
    const handleCurrencyASelect = useCallback(
      (currA) => {
        const newCurrencyIdA = currencyId(currA);
        if (newCurrencyIdA === currencyIdB) {
          history.push(`/add/${currencyIdB}/${currencyIdA}`);
        } else {
          history.push(`/add/${newCurrencyIdA}/${currencyIdB}`);
        }
      },
      [currencyIdB, history, currencyIdA]
    );
    const handleCurrencyBSelect = useCallback(
      (currB) => {
        const newCurrencyIdB = currencyId(currB);
        if (currencyIdA === newCurrencyIdB) {
          if (currencyIdB) {
            history.push(`/add/${currencyIdB}/${newCurrencyIdB}`);
          } else {
            history.push(`/add/${newCurrencyIdB}`);
          }
        } else {
          history.push(`/add/${currencyIdA || "BNB"}/${newCurrencyIdB}`);
        }
      },
      [currencyIdA, history, currencyIdB]
    );
    const handleDismissConfirmation = useCallback(() => {
      setShowConfirm(false);
      // if there was a tx hash, we want to clear the input
      if (txHash) {
        onFieldAInput("");
      }
      setTxHash("");
    }, [onFieldAInput, txHash]);
    const rotationHandler = () => {
      if (rotaion) {
        setRotation(false);
      } else {
        setRotation(true);
      }
    };

    const modalHeader = noLiquidity ? (
      <>
        <Box
          paddingLeft="20px"
          paddingRight="20px"
          paddingTop="25px"
          display="flex"
          bgcolor={theme.palette.secondary.main}
          justifyContent="space-between"
          pb="20px"
        >
          <Box
            color={theme.palette.secondary.contrastText}
            fontSize="20px"
            textAlign="left"
            fontWeight="600"
            fontFamily="Segoe UI"
          >
            You are creating a pool
          </Box>
          <Box>
            <i
              onClick={handleDismissConfirmation}
              style={{
                color: theme.palette.secondary.contrastText,
                textAlign: "right",
                fontSize: "25px",
                cursor: "pointer",
              }}
              className="fal fa-times"
            ></i>
          </Box>
        </Box>
        <Box display="flex" p={3}>
          <Box
            fontFamily="Segoe UI"
            fontSize="40px"
            fontWeight="600"
            color={theme.palette.common.black}
          >
            {`${currencies[Field.CURRENCY_A]?.symbol}/${
              currencies[Field.CURRENCY_B]?.symbol
            }`}
          </Box>
          <Box display="flex" alignItems="center" mt={4}>
            <DoubleCurrencyLogo
              currency0={currencies[Field.CURRENCY_A]}
              currency1={currencies[Field.CURRENCY_B]}
              size={20}
            />
          </Box>
        </Box>
      </>
    ) : (
      <>
        <Box
          paddingLeft="20px"
          paddingRight="20px"
          paddingTop="25px"
          display="flex"
          bgcolor={theme.palette.secondary.main}
          justifyContent="space-between"
          pb="20px"
        >
          <Box
            color={theme.palette.secondary.contrastText}
            fontSize="20px"
            textAlign="left"
            fontWeight="600"
            fontFamily="Segoe UI"
          >
            You will receive
          </Box>
          <Box>
            <i
              onClick={handleDismissConfirmation}
              style={{
                color: theme.palette.secondary.contrastText,
                textAlign: "right",
                fontSize: "25px",
                cursor: "pointer",
              }}
              className="fal fa-times"
            ></i>
          </Box>
        </Box>
        <Box
          display="flex"
          p={3}
          pb={0}
          fontFamily="Segoe UI"
          fontSize={matches ? "20px" : "35px"}
          fontWeight="600"
          color={theme.palette.common.black}
        >
          {liquidityMinted?.toSignificant(6)}
          <DoubleCurrencyLogo
            currency0={currencies[Field.CURRENCY_A]}
            currency1={currencies[Field.CURRENCY_B]}
            size={20}
          />
        </Box>
        <Box
          fontFamily="Segoe UI"
          fontSize={matches ? "20px" : "30px"}
          fontWeight={matches ? "400" : "700"}
          my={2}
          color={theme.palette.common.black}
        >
          {`${currencies[Field.CURRENCY_A]?.symbol}/${
            currencies[Field.CURRENCY_B]?.symbol
          } Pool Tokens`}
        </Box>
        <Box
          mb={3}
          p={1}
          fontFamily="Segoe UI"
          fontSize={matches ? "13px" : "20px"}
          fontWeight={matches ? "400" : "600"}
          color={theme.palette.common.black}
        >
          {`Output is estimated. If the price changes by more than ${
            allowedSlippage / 100
          }% your transaction will revert.`}
        </Box>
      </>
    );
    const modalBottom = (
      <ConfirmAddModalBottom
        price={price}
        currencies={currencies}
        parsedAmounts={parsedAmounts}
        noLiquidity={noLiquidity}
        onAdd={onAdd}
        poolTokenPercentage={poolTokenPercentage}
      />
    );
    const pendingText = `Supplying ${parsedAmounts[
      Field.CURRENCY_A
    ]?.toSignificant(6)} ${
      currencies[Field.CURRENCY_A]?.symbol
    } and ${parsedAmounts[Field.CURRENCY_B]?.toSignificant(6)} ${
      currencies[Field.CURRENCY_B]?.symbol
    }`;

    return (
      <>
        <Container maxWidth="xs">
          <Box
            mt={"50px"}
            // className={classes.tradeContainer}

            align="center"
          >
            <Box
              sx={{
                background: "#202A80",
                borderRadius: "15.6877px",
                padding: "30px 20px",
                boxShadow: "2",
              }}
              // className={classes.ExchangeBox}
            >
              <Typography
                sx={{
                  fontFamily: "'Russo One'",
                  fontStyle: "normal",
                  fontWeight: 400,
                  fontSize: "25.1003px",
                  textAlign: "center",
                  color: "white",
                }}
              >
                Add Liquidity
              </Typography>
              {/* <LiquidityTopNav adding /> */}

              <TransectionModal
                isOpen={showConfirm}
                onDismiss={handleDismissConfirmation}
                attemptingTxn={attemptingTxn}
                hash={txHash}
                modalBottom={modalBottom}
                modalHeader={modalHeader}
                pendingText={pendingText}
              />

              {noLiquidity ? (
                <Box
                  borderRadius="10px"
                  p={3}
                  border="1px solid"
                  borderColor={"#202A80"}
                  sx={{
                    fontFamily: "'Roboto'",
                    fontStyle: "normal",
                    fontWeight: 400,
                    fontSize: "16px",
                    color: "white",
                  }}
                >
                  You are the first liquidity provider.
                  <br />
                  <br />
                  The ratio of tokens you add will set the price of this pool.
                  <br />
                  <br />
                  Once you are happy with the rate click supply to review.
                </Box>
              ) : (
                <Box></Box>
              )}
              <Grid order={2} mt="20px">
                <CurrencyInput
                  value={formattedAmounts[Field.CURRENCY_A]}
                  onUserInput={onFieldAInput}
                  onMax={() => {
                    onFieldAInput(
                      maxAmounts[Field.CURRENCY_A]?.toExact() ?? ""
                    );
                  }}
                  onCurrencySelect={handleCurrencyASelect}
                  showMaxButton={!atMaxAmounts[Field.CURRENCY_A]}
                  currency={currencies[Field.CURRENCY_A]}
                  id="add-liquidity-input-tokena"
                  showCommonBases={false}
                />
              </Grid>
              <Grid>
                <Box mt={4}>
                  <Box className={classes.Middline}>
                    <Box
                      width="50px"
                      height="30px"
                      // bgcolor={theme.palette.primary.main}
                      position="absolute"
                      top="-1rem"
                      display="flex"
                      justifyContent="center"
                    >
                      <Box className={classes.circleBox}>
                        <CachedIcon
                          onClick={() => onSwitchTokens()}
                          // transform: "rotate(180deg)"
                          style={{
                            margin: ".1rem",
                            transform:
                              rotaion === true
                                ? "rotate(360deg)"
                                : "rotate(0deg)",
                            color: "white",
                            cursor: "pointer",
                          }}
                        />
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </Grid>
              <Grid>
                <CurrencyInput
                  value={formattedAmounts[Field.CURRENCY_B]}
                  onUserInput={onFieldBInput}
                  onCurrencySelect={handleCurrencyBSelect}
                  onMax={() => {
                    onFieldBInput(
                      maxAmounts[Field.CURRENCY_B]?.toExact() ?? ""
                    );
                  }}
                  showMaxButton={!atMaxAmounts[Field.CURRENCY_B]}
                  currency={currencies[Field.CURRENCY_B]}
                  id="add-liquidity-input-tokenb"
                  showCommonBases={false}
                />
              </Grid>
              {currencies[Field.CURRENCY_A] &&
                currencies[Field.CURRENCY_B] &&
                pairState !== PairState.INVALID && (
                  <>
                    <Box
                      pt={3}
                      color={theme.palette.common.black}
                      textAlign="left"
                    >
                      {noLiquidity
                        ? "Initial prices and pool share"
                        : "Prices and pool share"}
                    </Box>
                    <Box
                      mt={1}
                      display="flex"
                      justifyContent="space-between"
                      borderRadius="20px"
                      p={2}
                      borderColor={"#202A80"}
                      sx={{
                        fontFamily: "'Roboto'",
                        fontStyle: "normal",
                        fontWeight: 400,
                        fontSize: "16px",
                        color: "white",
                      }}
                    >
                      <Box display="flex" flexDirection="column">
                        <Box
                          sx={{
                            fontFamily: "'Roboto'",
                            fontStyle: "normal",
                            fontWeight: 400,
                            fontSize: "16px",
                            color: "white",
                          }}
                        >
                          {price?.toSignificant(6) ?? "-"}
                        </Box>
                        <Box
                          sx={{
                            fontFamily: "'Roboto'",
                            fontStyle: "normal",
                            fontWeight: 400,
                            fontSize: "16px",
                            color: "white",
                          }}
                          mt={1}
                        >
                          {currencies[Field.CURRENCY_B]?.symbol} per{" "}
                          {currencies[Field.CURRENCY_A]?.symbol}
                        </Box>
                      </Box>
                      <Box display="flex" flexDirection="column">
                        <Box
                          sx={{
                            fontFamily: "'Roboto'",
                            fontStyle: "normal",
                            fontWeight: 400,
                            fontSize: "16px",
                            color: "white",
                          }}
                        >
                          {price?.invert()?.toSignificant(6) ?? "-"}
                        </Box>
                        <Box
                          sx={{
                            fontFamily: "'Roboto'",
                            fontStyle: "normal",
                            fontWeight: 400,
                            fontSize: "16px",
                            color: "white",
                          }}
                          mt={1}
                        >
                          {currencies[Field.CURRENCY_A]?.symbol} per{" "}
                          {currencies[Field.CURRENCY_B]?.symbol}
                        </Box>
                      </Box>
                      <Box display="flex" flexDirection="column">
                        <Box
                          sx={{
                            fontFamily: "'Roboto'",
                            fontStyle: "normal",
                            fontWeight: 400,
                            fontSize: "16px",
                            color: "white",
                          }}
                        >
                          {noLiquidity && price
                            ? "100"
                            : (poolTokenPercentage?.lessThan(ONE_BIPS)
                                ? "<0.01"
                                : poolTokenPercentage?.toFixed(2)) ?? "0"}
                          %
                        </Box>
                        <Box
                          sx={{
                            fontFamily: "'Roboto'",
                            fontStyle: "normal",
                            fontWeight: 400,
                            fontSize: "16px",
                            color: "white",
                          }}
                          mt={1}
                        >
                          Share of Pool
                        </Box>
                      </Box>
                    </Box>
                  </>
                )}
              <Box mt={3}>
                {/* <CustomizedButton > */}
                {!account ? (
                  <Button
                    sx={{
                      fontFamily: "'Russo One'",
                      fontStyle: "normal",
                      fontWeight: 400,
                      fontSize: "12.7153px",
                      lineHeight: "15px",
                      background: "#F1620A",
                      borderRadius: "47.063px",
                      width: "70%",
                      color: "white",
                      height: "42px",
                    }}
                    onClick={connect}
                  >
                    Unlock Wallet
                  </Button>
                ) : (
                  <Box gap="md">
                    {(approvalA === ApprovalState.NOT_APPROVED ||
                      approvalA === ApprovalState.PENDING ||
                      approvalB === ApprovalState.NOT_APPROVED ||
                      approvalB === ApprovalState.PENDING) &&
                      isValid && (
                        <Box mb={1}>
                          {approvalA !== ApprovalState.APPROVED && (
                            <Button
                              sx={{
                                fontFamily: "'Russo One'",
                                fontStyle: "normal",
                                fontWeight: 400,
                                fontSize: "12.7153px",
                                lineHeight: "15px",
                                background: "#F1620A",
                                borderRadius: "47.063px",
                                width: "70%",
                                color: "white",
                                height: "42px",
                              }}
                              onClick={approveACallback}
                              disabled={approvalA === ApprovalState.PENDING}
                            >
                              {approvalA === ApprovalState.PENDING ? (
                                <Box>
                                  Approving{" "}
                                  {currencies[Field.CURRENCY_A]?.symbol}
                                </Box>
                              ) : (
                                `Approve ${
                                  currencies[Field.CURRENCY_A]?.symbol
                                }`
                              )}
                            </Button>
                          )}
                          {approvalB !== ApprovalState.APPROVED && (
                            <Button
                              sx={{
                                fontFamily: "'Russo One'",
                                fontStyle: "normal",
                                fontWeight: 400,
                                fontSize: "12.7153px",
                                lineHeight: "15px",
                                background: "#F1620A",
                                borderRadius: "47.063px",
                                width: "70%",
                                color: "white",
                                height: "42px",
                              }}
                              onClick={approveBCallback}
                              disabled={approvalB === ApprovalState.PENDING}
                            >
                              {approvalB === ApprovalState.PENDING ? (
                                <Box>
                                  Approving{" "}
                                  {currencies[Field.CURRENCY_B]?.symbol}
                                </Box>
                              ) : (
                                `Approve ${
                                  currencies[Field.CURRENCY_B]?.symbol
                                }`
                              )}
                            </Button>
                          )}
                        </Box>
                      )}
                    <Button
                      sx={{
                        fontFamily: "'Russo One'",
                        fontStyle: "normal",
                        fontWeight: 400,
                        fontSize: "12.7153px",
                        lineHeight: "15px",
                        background: "#F1620A",
                        borderRadius: "47.063px",
                        width: "70%",
                        color: "white",
                        height: "42px",
                      }}
                      onClick={() => {
                        if (expertMode) {
                          onAdd();
                        } else {
                          setShowConfirm(true);
                        }
                      }}
                      disabled={
                        !isValid ||
                        approvalA !== ApprovalState.APPROVED ||
                        approvalB !== ApprovalState.APPROVED
                      }
                    >
                      {error ?? "Supply"}
                    </Button>
                  </Box>
                )}
              </Box>
            </Box>
          </Box>
          {pair && !noLiquidity && pairState !== PairState.INVALID ? (
            <LPTokensBox showUnwrapped={oneCurrencyIsWBNB} pair={pair} />
          ) : null}
        </Container>
      </>
    );
  }
);
