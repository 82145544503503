import React, { useState, createContext, useContext } from "react";
import PropTypes from "prop-types";
import {
  Box,
  useMediaQuery,
  Collapse,
  Container,
  MenuItem,
  Menu,
  useTheme,
  Divider,
  Typography,
  Button,
} from "@mui/material";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import AppBar from "@mui/material/AppBar";
import Drawer from "@mui/material/Drawer";
import Hidden from "@mui/material/Hidden";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";
import { makeStyles } from "@mui/styles";
import logo from "../../assets/logoMain.png";

import { links } from "./NavData";
import { Link, useLocation } from "react-router-dom";

import { trade } from "./icons";
import { swap } from "./icons";
import { AppContext } from "../../utils/Utils";
// import SettingsIcon from "@mui/icons-material/Settings";
// import LanguageIcon from "@mui/icons-material/Language";
import tele from "../../assets/sidetele.png";
import fb from "../../assets/sidefb.png";
import insta from "../../assets/sideinsta.png";

import SettingsIcon from "@mui/icons-material/Settings";
import LanguageIcon from "@mui/icons-material/Language";
import SlippingTolrance from "../../../uniswap/components/models/Slippage";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    justifyContent: "space-between",
  },
  drawer: {
    [theme.breakpoints.up("md")]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  appBar: {
    "&.MuiPaper-elevation4": {
      boxShadow: "none",
    },
    [theme.breakpoints.up("md")]: {
      width: `calc(100% - ${drawerWidth}px)`,
      // paddingLeft: drawerWidth,
    },
  },
  menuButton: {
    marginRight: "2px",
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
  drawerPaper: {
    // background: "linear-gradient(180deg,#010101 0,#1d1d1d 100%)",
    background: "rgba(32, 42, 128, 0.5)",

    width: drawerWidth,
    "&::-webkit-scrollbar": {
      width: "5px",
      backgroundColor: "",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#202A80",
      borderRadius: "4px",
    },
    "&::-webkit-scrollbar-track": {
      borderRadius: "10px",
    },
    "& span": {
      fontFamily: "Segoe UI",
      fontSize: "13px",
      color: theme.palette.common.black,
    },
    "& hr": {
      backgroundColor: theme.palette.common.black,
    },
    "& svg": {
      color: theme.palette.common.black,
    },
  },

  content: {
    width: "100%",
    position: "relative",
    height: "100% !important",
    // padding: "50px 0px",
    background: "rgba(32, 42, 128, 0.5)",
  },
  nested: {
    textAlign: "center",
  },
}));

function Bar({ children, openConnect, setopenConnect, init }) {
  const { account, signer, connect, disconnect } = useContext(AppContext);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [network, setnetwork] = useState("ETH");
  const [selectedIndex, setSelectedIndex] = useState(0);

  const openNetworkMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const { window } = children;
  const matches = useMediaQuery("(max-width:960px)");
  const matches1 = useMediaQuery("(max-width:900px)");
  const [open, setOpen] = useState(true);
  const [open1, setOpen1] = useState(true);
  const classes = useStyles();
  const theme = useTheme();

  const [mobileOpen, setMobileOpen] = useState(false);
  const [openLogout, setopenLogout] = useState(false);
  const [openSlippage, setopenSlippage] = useState(false);
  const location = useLocation();
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleClick = () => {
    setOpen(!open);
  };
  const handleClickUni = () => {
    setOpen1(!open1);
  };

  const drawer = (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        height: "100%",
        backgroundColor: "rgba(32, 42, 128, 0.5)",
      }}
      className={classes.toolbar}
    >
      <Box>
        <Box
          style={{
            display: "flex",
            justifyContent: "center",
          }}
          px={2}
          py={3}
          component={Link}
          to="/"
        >
          <img width="100%" src={logo} alt="" />
        </Box>
        {/* </a> */}
        <Divider style={{ backgroundColor: "#010524" }} />
        <Link
          to={"/"}
          style={{
            color: theme.palette.common.black,
            textDecoration: "none",
          }}
        >
          <Typography
            variant="body1"
            fontWeight="bold"
            color="white"
            mt={3}
            ml={1}
            textAlign="center"
          >
            Dashboard
          </Typography>
        </Link>
        <List key={0}>
          <ListItem
            style={{
              fontFamily: "'Roboto', sans-serif",
              fontWeight: "bold",
              textAlign: "center",
            }}
            button
            onClick={handleClick}
          >
            <ListItemText
              primaryTypographyProps={{
                color: "White",
                fontWeight: "bold",
                variant: "body1",
              }}
            >
              Trade
            </ListItemText>
            {open ? <ExpandLess /> : <ExpandMore />}
          </ListItem>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <List key={0} disablePadding sx={{ ml: 4 }}>
              <Link
                to={"/"}
                style={{
                  color: theme.palette.common.black,
                  textDecoration: "none",
                }}
              >
                <ListItem
                  button
                  sx={{
                    display: "flex ",
                    justifyContent: "center",
                    textAlign: "center",
                  }}
                  // className={classes.nested}
                >
                  <ListItemText
                    primaryTypographyProps={{
                      color: "White",
                      variant: "body1",
                    }}
                  >
                    Swap
                  </ListItemText>
                </ListItem>
              </Link>
              <Link
                to={"/swap/pool"}
                style={{
                  color: theme.palette.common.black,
                  textDecoration: "none",
                }}
              >
                <ListItem
                  button
                  // className={classes.nested}
                  sx={{ textAlign: "center" }}
                >
                  <ListItemText
                    primaryTypographyProps={{
                      color: "White",
                      variant: "body1",
                    }}
                  >
                    Liquidity
                  </ListItemText>
                </ListItem>
              </Link>
            </List>
          </Collapse>
        </List>

        <List key={1}>
          <>
            <a href="http://stake.seekglobalnetworks.com" target="_blank">
              <ListItem>
                <ListItemText
                  primaryTypographyProps={{
                    color: "White",
                    fontWeight: "bold",
                    variant: "body1",
                    textAlign: "center",
                    ml: -3,
                  }}
                >
                  Stake
                </ListItemText>
              </ListItem>
            </a>
            <Link
              to={"/lottery"}
              style={{
                color: theme.palette.common.black,
                textDecoration: "none",
              }}
            >
              <ListItem>
                <ListItemText
                  primaryTypographyProps={{
                    color: "White",
                    fontWeight: "bold",
                    variant: "body1",
                    textAlign: "center",
                    ml: -2,
                  }}
                >
                  Lottery
                </ListItemText>
              </ListItem>
            </Link>
            <Divider style={{ backgroundColor: "#010524" }} />
            <ListItem>
              <ListItemText
                primaryTypographyProps={{
                  color: "White",
                  fontWeight: "bold",
                  variant: "body1",
                  textAlign: "center",
                  ml: -5,
                }}
              >
                FAQ
              </ListItemText>
            </ListItem>
            <ListItem>
              <ListItemText
                primaryTypographyProps={{
                  color: "White",
                  fontWeight: "bold",
                  variant: "body1",
                  textAlign: "center",
                  ml: -2,
                }}
              >
                Contact
              </ListItemText>
            </ListItem>
            <ListItem button onClick={handleClickUni}>
              <ListItemText
                primaryTypographyProps={{
                  color: "White",
                  fontWeight: "bold",
                  variant: "body1",
                  textAlign: "center",
                  ml: -2,
                }}
              >
                Sign out
              </ListItemText>
            </ListItem>
          </>
          <Box color="white" textAlign="center">
            <Typography mb={2}>Share</Typography>
            <Box display="flex" justifyContent="center" alignItems="center">
              <img src={tele} alt="" />
              <img
                src={fb}
                alt=""
                style={{
                  paddingLeft: "9px",
                  paddingRight: "9px",
                }}
              />
              <img src={insta} alt="" />
            </Box>
          </Box>
        </List>
      </Box>
    </div>
  );
  const container =
    window !== undefined ? () => window().document.body : undefined;
  return (
    <div className={classes.root}>
      <SlippingTolrance
        openSlippage={openSlippage}
        setopenSlippage={setopenSlippage}
      />
      <AppBar
        position="fixed"
        // className={classes.appBar}
        sx={{
          backgroundColor: "transparent",
          width: matches1 ? "100%" : `calc(100% + ${drawerWidth}px)`,
        }}
      >
        <Toolbar
          sx={{
            justifyContent: "space-between",
            background: "transparent",
          }}
        >
          <Box display="flex">
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
              sx={{ color: { xs: "#010524", md: "transparent" } }}
            >
              <MenuIcon sx={{ color: { xs: "#ffffff", md: "transparent" } }} />
            </IconButton>
          </Box>
          <Box display="flex" justifyContent="space-evenly" alignItems="center">
            {account ? (
              <Box
                width={matches1 ? "100px" : "171px"}
                height={matches1 ? "30px" : "40px"}
                bgcolor={"#F1620A"}
                color="#fff"
                display="flex"
                justifyContent="center"
                alignItems="center"
                borderRadius="30px"
                fontFamily="Russo One"
                fontSize={matches1 ? "11px" : "15px"}
                ml={matches ? 1 : 2}
                // onClick={() => setopenLogout(true)}
                onClick={() => disconnect()}
                sx={{
                  cursor: "pointer",
                  textShadow: "0px 5px 10px #00000029",
                  overflow: "hidden",
                }}
                overflow="hidden"
                position="relative"
              >
                {account.slice(0, 6) + "..." + account.slice(-4)}
              </Box>
            ) : (
              <Button
                onClick={() => connect()}
                sx={{
                  bgcolor: "#F1620A",
                  cursor: "pointer",
                  borderRadius: "30px",
                  textTransform: "capitalize",
                  textShadow: "0px 5px 10px #00000029",
                  fontFamily: "Russo One",
                  px: 3,
                  "&:hover": {
                    bgcolor: "#F1620Aa1",
                  },
                }}
              >
                Connect wallet
              </Button>
            )}

            <IconButton>
              <LanguageIcon
                sx={{
                  // marginLeft: "15px",
                  // marginRight: "15px",
                  color: "white",
                }}
              />
            </IconButton>
            <IconButton onClick={() => setopenSlippage(true)}>
              <SettingsIcon
                sx={{
                  color: "white",
                }}
              />
            </IconButton>
          </Box>
        </Toolbar>
      </AppBar>
      <nav className={classes.drawer} aria-label="mailbox folders">
        <Hidden mdUp implementation="css">
          <Drawer
            container={container}
            variant="temporary"
            anchor={theme.direction === "rtl" ? "right" : "left"}
            open={mobileOpen}
            onClose={handleDrawerToggle}
            classes={{
              paper: classes.drawerPaper,
            }}
            ModalProps={{
              keepMounted: true,
            }}
          >
            {drawer}
          </Drawer>
        </Hidden>
        <Hidden mdDown implementation="css">
          <Drawer
            classes={{
              paper: classes.drawerPaper,
            }}
            PaperProps={{ sx: { background: "rgba(32, 42, 128, 0.5)" } }}
            variant="permanent"
            open
          >
            {drawer}
          </Drawer>
        </Hidden>
      </nav>
      <main
        style={{
          width: matches1 ? "100%" : `calc(100% - ${drawerWidth}px)`,
        }}
      >
        {children}
      </main>
    </div>
  );
}
Bar.propTypes = {
  window: PropTypes.func,
};
export default Bar;
