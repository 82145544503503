import React, { useEffect, useState } from "react";
import { withStyles } from "@mui/styles";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";

import {
  Dialog,
  DialogContent,
  Box,
  InputBase,
  Divider,
  useMediaQuery,
  IconButton,
  Button,
} from "@mui/material";
import { useTheme } from "@mui/material";
import {
  useUserDeadline,
  useUserSlippageTolerance,
} from "../../state/user/hooks";
import { LightTooltip } from "../small components/swap/AdvancedSwapDetails";
const StyledModal = withStyles((theme) => ({
  root: {
    "& .MuiDialog-root": {
      zIndex: "1301 !important",
    },
    "&.MuiDialog-container": {
      overflowY: "hidden !important",
    },
    "& .MuiDialog-paperScrollPaper": {
      backgroundColor: "tranparent !important",
      height: "auto",
      boxShadow: "0px 0px 13px 0px  rgb(0, 0 ,0)",
      borderRadius: "30px !important",
    },
    "& .dialoge__content__section": {
      background: "#000 0% 0% no-repeat padding-box",
      border: "1px solid #000",
      borderRadius: 18,
    },
  },
}))(Dialog);

const MAX_SLIPPAGE = 5000;
const RISKY_SLIPPAGE_LOW = 50;
const RISKY_SLIPPAGE_HIGH = 500;

function SlippingTolrance({ openSlippage, setopenSlippage }) {
  const theme = useTheme();
  const [userSlippageTolerance, setUserslippageTolerance] =
    useUserSlippageTolerance();
  const matches = useMediaQuery("(max-width:760px)");
  const [percentage, setpercentage] = useState(0);
  const handleClose = () => {
    setopenSlippage(false);
  };
  const [value, setValue] = useState(userSlippageTolerance / 100);
  const [error, setError] = useState(null);
  const handleChange = (evt) => {
    const { value: inputValue } = evt.target;
    setValue(parseFloat(inputValue));
  };
  const [deadline, setDeadline] = useUserDeadline();
  const [value1, setValue1] = useState(deadline / 60); // deadline in minutes
  const [error1, setError1] = useState(null);

  const handleChange1 = (evt) => {
    const { value: inputValue } = evt.target;
    if (value > 0 && value) {
      setValue1(parseInt(inputValue, 10));
    }
  };

  // Updates local storage if value is valid
  useEffect(() => {
    try {
      const rawValue = value * 60;
      if (!Number.isNaN(rawValue) && rawValue > 0) {
        setDeadline(rawValue);
        setError1(null);
      } else {
        setError1("Enter a valid deadline");
      }
    } catch {
      setError("Enter a valid deadline");
    }
  }, [value, setError, setDeadline]);

  useEffect(() => {
    try {
      const rawValue = value * 100;
      if (!Number.isNaN(rawValue) && rawValue > 0 && rawValue < MAX_SLIPPAGE) {
        setUserslippageTolerance(rawValue);
        setError(null);
      } else {
        setError("Enter a valid slippage percentage");
      }
    } catch {
      setError("Enter a valid slippage percentage");
    }
  }, [value, setError, setUserslippageTolerance]);

  // Notify user if slippage is risky
  useEffect(() => {
    if (userSlippageTolerance < RISKY_SLIPPAGE_LOW) {
      setError("Your transaction may fail");
    } else if (userSlippageTolerance > RISKY_SLIPPAGE_HIGH) {
      setError("Your transaction may be frontrun");
    }
  }, [userSlippageTolerance, setError]);
  const predefinedValues = [
    { label: "0.1%", value: 0.1 },
    { label: "0.5%", value: 0.5 },
    { label: "1%", value: 1 },
  ];
  return (
    <>
      <StyledModal
        keepMounted
        open={openSlippage}
        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogContent className="dialoge__content__section">
          <div
            style={{
              textAlign: "center",
              paddingBottom: "30px",
              width: matches ? "295px" : "500px",
            }}
          >
            <Box
              paddingLeft="20px"
              paddingRight="20px"
              paddingTop="25px"
              display="flex"
              bgcolor={theme.palette.secondary.main}
              justifyContent="space-between"
              sx={{ borderRadius: "20px" }}
              pb="20px"
            >
              <Box
                color={theme.palette.secondary.contrastText}
                fontSize="20px"
                textAlign="left"
                fontWeight="600"
                fontFamily="Segoe UI"
              >
                Settings
              </Box>
              <Box>
                <i
                  onClick={handleClose}
                  style={{
                    color: theme.palette.secondary.contrastText,
                    textAlign: "right",
                    fontSize: "25px",
                    cursor: "pointer",
                  }}
                  className="fal fa-times"
                ></i>
              </Box>
            </Box>
            <Divider
              style={{
                width: "100%",
                backgroundColor: "#F1F5F91F",
                height: "1px",
              }}
            />
            <Box
              color={theme.palette.common.black}
              fontSize="17px"
              textAlign="left"
              fontWeight="600"
              paddingLeft="20px"
              mt={3}
              fontFamily="Segoe UI"
            >
              Slippage Tolerance
              <LightTooltip
                title={
                  "Your transaction will revert if the price changes unfavorably by more than this percentage."
                }
              >
                <IconButton aria-label="delete">
                  <HelpOutlineIcon
                    style={{ color: theme.palette.common.black }}
                  />
                </IconButton>
              </LightTooltip>
            </Box>
            <Box
              paddingLeft="20px"
              paddingRight="20px"
              mt={3}
              display="flex"
              alignItems="center"
              justifyContent="space-evenly"
              flexWrap="wrap"
            >
              {predefinedValues.map(({ label, value: predefinedValue }) => {
                const handleClick = () => setValue(predefinedValue);
                return (
                  <Box
                    key={predefinedValue}
                    // fontFamily="Segoe UI"
                    component={Button}
                    fontSize="18px"
                    padding="5px 10px"
                    onClick={handleClick}
                    // borderColor={theme.palette.secondary.main}
                    // border="1px solid"
                    bgcolor={"#F1620A"}
                    style={{
                      borderRadius: "10px",
                      cursor: "pointer",
                      color: "white",
                      background: "#F1620A",
                    }}
                  >
                    {label}
                  </Box>
                );
              })}

              <Box
                display="flex"
                justifyContent="space-between"
                bgcolor={"#F1620A"}
                alignItems="center"
                borderRadius="10px"
                padding="1px 10px"
                marginTop={matches ? "15px" : "0px"}
              >
                <InputBase
                  style={{
                    fontSize: "18px",
                    fontFamily: "Segoe UI",
                    textAlign: "center",
                    color: "white",
                  }}
                  type="number"
                  placeholder="5%"
                  value={value}
                  onChange={handleChange}
                  isWarning={error !== null}
                />
              </Box>
              <Box
                color={theme.palette.common.black}
                fontFamily="Segoe UI"
                fontSize="20px"
              >
                %
              </Box>
            </Box>
            {error && (
              <Box mt="8px" color="secondary.main">
                {error}
              </Box>
            )}
            <Box
              color={theme.palette.common.black}
              sx={{
                fontFamily: "'Roboto'",
                fontStyle: "normal",
                fontWeight: 400,
                fontSize: "16px",
                color: "white",
              }}
              textAlign="left"
              paddingLeft="20px"
              mt={3}
            >
              Transaction deadline
              <LightTooltip
                title={
                  "Your transaction will revert if it is pending for more than this long."
                }
              >
                <IconButton aria-label="delete">
                  <HelpOutlineIcon
                    style={{ color: theme.palette.common.black }}
                  />
                </IconButton>
              </LightTooltip>
            </Box>
            <Box display="flex">
              <Box
                bgcolor={"#F1620A"}
                borderRadius="10px"
                padding="1px 10px"
                marginTop="20px"
                marginLeft="32px"
                width={matches ? "150px" : "auto"}
              >
                <InputBase
                  sx={{
                    fontFamily: "'Roboto'",
                    fontStyle: "normal",
                    fontWeight: 400,
                    fontSize: "16px",
                    color: "white",
                  }}
                  type="number"
                  placeholder="20"
                  step="1"
                  min="1"
                  value={value1}
                  onChange={handleChange1}
                />
              </Box>
              <Box
                color={theme.palette.common.black}
                marginTop="23px"
                marginLeft="10px"
                sx={{
                  fontFamily: "'Roboto'",
                  fontStyle: "normal",
                  fontWeight: 400,
                  fontSize: "16px",
                  color: "white",
                }}
              >
                Minutes
              </Box>
            </Box>
            {error1 && (
              <Box mt="8px" color="secondary.main">
                {error1}
              </Box>
            )}
          </div>
        </DialogContent>
      </StyledModal>
    </>
  );
}
export default SlippingTolrance;
