import theme from "../../../theme";

// export { default as HomeIcon } from "./icons/Home";
// export { default as InfoIcon } from "./icons/Info";
// export { default as BridgeIcon } from "./icons/Bridge";
// export { default as TradeIcon } from "./icons/Trade";
// export { default as StakeIcon } from "./icons/Stake";
// export { default as CentroIcon } from "./icons/Centro";
// export { default as ContactarIcon } from "./icons/Contactar";
// export { default as CerrarIcon } from "./icons/Cerrar";

export const home = (
  <>
    <i
      style={{
        color: theme.palette.common.black,
        fontSize: "20px",
      }}
      className="fas fa-home-lg-alt"
    ></i>
  </>
);
export const trade = (
  <>
    <i
      style={{
        color: theme.palette.common.black,
        fontSize: "20px",
      }}
      className="fas fa-repeat"
    ></i>
  </>
);
export const swap = (
  <>
    <i
      style={{
        color: theme.palette.common.black,
        fontSize: "20px",
      }}
      class="fa-solid fa-arrow-right-arrow-left"
    ></i>
  </>
);
export const stake = (
  <>
    <i
      style={{
        color: theme.palette.common.black,
        fontSize: "20px",
      }}
      className="fas fa-mars-stroke"
    ></i>
  </>
);
export const bridge = (
  <>
    <i
      style={{
        color: theme.palette.common.black,
        fontSize: "20px",
        fontWeight: "500",
      }}
      className="fal fa-stroopwafel"
    ></i>
  </>
);
export const info = (
  <>
    <i
      style={{
        color: theme.palette.common.black,
        fontSize: "20px",
      }}
      className="fas fa-chart-line"
    ></i>
  </>
);

export const centro = (
  <>
    <i
      style={{
        color: theme.palette.common.black,
        fontSize: "20px",
        fontWeight: "500",
      }}
      className="fal fa-question-circle"
    ></i>
  </>
);

export const contact = (
  <>
    <i
      style={{
        color: theme.palette.common.black,
        fontSize: "20px",
        fontWeight: "500",
      }}
      className="fal fa-phone"
    ></i>
  </>
);

export const carrar = (
  <>
    <i
      style={{
        color: theme.palette.common.black,
        fontSize: "20px",
        fontWeight: "500",
      }}
      className="fal fa-sign-out"
    ></i>
  </>
);
