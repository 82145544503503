import React from "react";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import {
  Box,
  Button,
  Container,
  IconButton,
  Link,
  Tooltip,
  Typography,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useTheme } from "@mui/material";
import { withStyles } from "@mui/styles";
import { useHistory } from "react-router";

const LightTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.secondary.main,
    color: "#ffffff",
    boxShadow: theme.shadows[1],
    fontSize: 14,
    fontWeight: 400,
    padding: 20,
  },
}))(Tooltip);

export function FindPoolTabs() {
  const theme = useTheme();
  return (
    <Container maxWidth="sm">
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <a
          style={{ color: theme.palette.common.black }}
          component={Link}
          href="/synergyswap/pool"
        >
          <ArrowBackIcon />
        </a>
        <Typography style={{ color: theme.palette.common.black }}>
          Import Pool
        </Typography>
        <LightTooltip title="Use this tool to find pairs that do not automatically appear in the interface.">
          <IconButton aria-label="delete">
            <HelpOutlineIcon style={{ color: theme.palette.common.black }} />
          </IconButton>
        </LightTooltip>
      </Box>
    </Container>
  );
}

export function LiquidityTopNav({ adding }) {
  const theme = useTheme();
  const history = useHistory();
  return (
    <Box position="relative" zIndex="3">
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Link
          style={{
            color: theme.palette.common.black,
            cursor: "pointer",
          }}
          onClick={() => history.push("/synergyswap/pool")}
        >
          <ArrowBackIcon style={{ color: theme.palette.common.black }} />
        </Link>
        <Typography style={{ color: theme.palette.common.black }}>
          {adding ? "Add  " : "Remove  "}
          Liquidity
        </Typography>
        <LightTooltip
          title={
            adding
              ? "When you add liquidity, you are given pool tokens representing your position. These tokens automatically earn fees proportional to your share of the pool, and can be redeemed at any time."
              : "Removing pool tokens converts your position back into underlying tokens at the current rate, proportional to your share of the pool. Accrued fees are included in the amounts you receive."
          }
        >
          <IconButton aria-label="delete">
            <HelpOutlineIcon style={{ color: theme.palette.common.black }} />
          </IconButton>
        </LightTooltip>
      </Box>
    </Box>
  );
}
