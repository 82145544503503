import { Box, Button, CircularProgress, Typography } from "@mui/material";
import { Stack } from "@mui/system";
import moment from "moment";
import React, { useEffect, useState } from "react";
import Countdown from "react-countdown";
import { useLotteryContract } from "../ConnectivityAss/hooks";
import mainbg from "./assets/lottery.png";
import ticketbg from "./assets/ticket.png";
import Timer from "./timer";

function MainPage() {
  const lotteryContract = useLotteryContract();
  const [lotteryData, setlotteryData] = useState({
    status: 0,
    startTime: 0,
    endTime: 0,
    priceTicketInSEEK: 0,
    discountDivisor: 0,
    rewardsBreakdown: 0, // 0: 1 matching number // 5: 6 matching numbers
    treasuryFee: 0, // 500: 5% // 200: 2% // 50: 0.5%
    SEEKPerBracket: 0,
    countWinnersPerBracket: 0,
    firstTicketId: 0,
    firstTicketIdNextLottery: 0,
    amountCollectedInSEEK: 0,
    finalNumber: 0,
  });
  let init = async () => {
    try {
      let lotteryId = await lotteryContract.currentLotteryId();
      let [
        status,
        startTime,
        endTime,
        priceTicketInSEEK,
        discountDivisor,
        rewardsBreakdown, // 0: 1 matching number // 5: 6 matching numbers
        treasuryFee, // 500: 5% // 200: 2% // 50.5%
        SEEKPerBracket,
        countWinnersPerBracket,
        firstTicketId,
        firstTicketIdNextLottery,
        amountCollectedInSEEK,
        finalNumber,
      ] = await lotteryContract.viewLottery(lotteryId);
      let obj = {
        status: +status,
        startTime: +startTime,
        endTime: +endTime,
        priceTicketInSEEK: +priceTicketInSEEK,
        discountDivisor: +discountDivisor,
        rewardsBreakdown, // 0: 1 matching number // 5: 6 matching numbers
        treasuryFee: +treasuryFee, // 500: 5% // 200: 2% // 50.5%
        SEEKPerBracket,
        countWinnersPerBracket,
        firstTicketId: +firstTicketId,
        firstTicketIdNextLottery: +firstTicketIdNextLottery,
        amountCollectedInSEEK: +amountCollectedInSEEK,
        finalNumber: +finalNumber,
      };
      setlotteryData({ ...obj });
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    init();
  }, []);
  let endTime = +moment.unix(lotteryData?.endTime)?.format("x");
  return (
    <>
      <Box
        sx={{
          backgroundImage: `url(${mainbg})`,
          minHeight: { xs: "auto", sm: "500px" },
          backgroundRepeat: "no-repeat",
          backgroundSize: { xs: "contain", sm: "100% 100%" },
          backgroundposition: "100% 100%",
        }}
      >
        <Stack alignItems="center">
          <Typography variant="h6" mt={{ xs: 2, sm: 5 }} color="white">
            The SEEKSWAP Lottery
          </Typography>{" "}
          <Typography
            variant="h3"
            fontFamily="Russo One"
            mt={{ xs: 2, sm: 8 }}
            color="white"
          >
            $90,990
          </Typography>
          <Typography variant="h6" mt={0} color="white">
            in Prizes
          </Typography>{" "}
          <Box
            sx={{
              backgroundImage: `url(${ticketbg})`,
              backgroundRepeat: "no-repeat",
              backgroundSize: "100% 100%",
              py: 3,
              px: 5,
            }}
          >
            <Button
              sx={{
                cursor: "pointer",
                textShadow: "0px 5px 10px #00000029",
                bgcolor: "#F1620A",
                color: "#fff",
                borderRadius: "30px",
                fontFamily: "Russo One",
                textDecoration: "none",
                px: 5,
                py: 0.5,
                "&:hover": {
                  bgcolor: "#F1620Ab1",
                },
              }}
              component="a"
              href="#buy-ticket"
            >
              Buy Ticket
            </Button>
          </Box>
        </Stack>
      </Box>
      <Stack alignItems="center">
        <Typography variant="h6" fontFamily="Russo One" mt={5} color="white">
          Get Your Tickets Now!
        </Typography>
        {lotteryData.endTime ? (
          <Countdown zeroPadTime={true} date={endTime} renderer={Timer} />
        ) : (
          <CircularProgress variant="indeterminate" />
        )}
        <Typography variant="h6" mt={5} color="white">
          Until The Draw
        </Typography>{" "}
      </Stack>
    </>
  );
}

export default MainPage;
