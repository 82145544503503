import React from "react";
import { Contract } from "@ethersproject/contracts";

import lotteryAbi from "./lotteryAbi.json";
import randomNumberAbi from "./randomNumberAbi.json";

import {
  airDropAddress,
  tokenAddress,
  presaleAddress,
  busdTokenAddress,
  busdAddress,
  seekGoldAddress,
  seekLotteryAddress,
  randomNumberAddress,
} from "./environment";
import { ethers } from "ethers";
import { Alert, Slide, Snackbar } from "@mui/material";
let walletAddress = "0x8ba1f109551bD432803012645Ac136ddd64DBA72";

const provider = new ethers.providers.JsonRpcProvider(
  "https://data-seed-prebsc-1-s1.binance.org:8545/"
  // "https://bsc-dataseed.binance.org/"
  // "https://api.avax-test.network/ext/bc/C/rpc"
);
export const voidAccount = new ethers.VoidSigner(walletAddress, provider);
function useContract(address, ABI, signer) {
  return React.useMemo(() => {
    if (signer) {
      return new Contract(address, ABI, signer);
    } else {
      return new Contract(address, ABI, voidAccount);
    }
  }, [address, ABI, signer]);
}

function SlideTransition(props) {
  return <Slide {...props} direction="down" />;
}
export function useLotteryContract(signer) {
  return useContract(seekLotteryAddress, lotteryAbi, signer);
}
export function useRandomNumberContract(signer) {
  return useContract(randomNumberAddress, randomNumberAbi, signer);
}

export const changeNetwork = async () => {
  try {
    await window.ethereum.request({
      method: "wallet_switchEthereumChain",
      params: [{ chainId: "0x38" }],
    });
  } catch (error) {
    if (+error.code === 4902) {
      try {
        await window.ethereum.request({
          method: "wallet_addEthereumChain",
          params: [
            {
              chainId: "0x38",
              chainName: "Binance Mainnet",
              rpcUrls: ["https://bsc-dataseed.binance.org/"],
              nativeCurrency: {
                name: "BNB COIN",
                symbol: "BNB",
                decimals: 18,
              },
              blockExplorerUrls: ["https://bscscan.com"],
            },
          ],
        });
      } catch (addError) {}
    }
  }
};

// export const changeNetwork = async () => {
//   try {
//     await window.ethereum.request({
//       method: "wallet_switchEthereumChain",
//       params: [{ chainId: "0x61" }],
//     });
//   } catch (error) {
//     if (+error.code === 4902) {
//       try {
//         await window.ethereum.request({
//           method: "wallet_addEthereumChain",
//           params: [
//             {
//               chainId: "0x61",
//               chainName: "Binance  testnet",
//               rpcUrls: ["https://data-seed-prebsc-1-s1.binance.org:8545/"],
//               nativeCurrency: {
//                 name: "BNB COIN",
//                 symbol: "BNB",
//                 decimals: 18,
//               },
//               blockExplorerUrls: ["https://testnet.bscscan.com"],
//             },
//           ],
//         });
//       } catch (addError) {}
//     }
//   }
// };
