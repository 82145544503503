import { TradeType } from "seek-swap-sdk";
import React, { useMemo, useState } from "react";
import { Repeat } from "react-feather";

import { Field } from "../../../state/swap/actions";
import {
  computeSlippageAdjustedAmounts,
  computeTradePriceBreakdown,
  formatExecutionPrice,
  warningSeverity,
} from "../../../utils/prices";
import { Box, Button, IconButton, useMediaQuery } from "@mui/material";
import { LightTooltip } from "./AdvancedSwapDetails";
import theme from "../../../../theme";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import { ONE_BIPS } from "../../../constants";

export default function SwapModalFooter({
  trade,
  onConfirm,
  allowedSlippage,
  disabledConfirm,
}) {
  const [showInverted, setShowInverted] = useState(false);
  const matches = useMediaQuery("(max-width:760px)");

  const slippageAdjustedAmounts = useMemo(
    () => computeSlippageAdjustedAmounts(trade, allowedSlippage),
    [allowedSlippage, trade]
  );
  const { priceImpactWithoutFee, realizedLPFee } = useMemo(
    () => computeTradePriceBreakdown(trade),
    [trade]
  );
  const severity = warningSeverity(priceImpactWithoutFee);

  return (
    <>
      <Box mt={5}>
        <Box
          mt={3}
          px={3}
          display="flex"
          alignItems="center"
          justifyContent="space-between"
        >
          <Box
            color={theme.palette.common.black}
            fontSize={matches ? "12px" : "17px"}
            textAlign="center"
            fontWeight="400"
            fontFamily="Segoe UI"
          >
            Price
          </Box>
          <Box
            color={theme.palette.common.black}
            fontSize={matches ? "12px" : "17px"}
            textAlign="center"
            fontWeight="400"
            fontFamily="Segoe UI"
            marginRight="-15px"
          >
            {formatExecutionPrice(trade, showInverted)}
            <Button onClick={() => setShowInverted(!showInverted)}>
              <Repeat size={18} color={theme.palette.common.black} />
            </Button>
          </Box>
        </Box>

        <Box
          px={3}
          display="flex"
          alignItems="center"
          justifyContent="space-between"
        >
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <Box
              color={theme.palette.common.black}
              fontSize={matches ? "12px" : "17px"}
              textAlign="center"
              fontWeight="400"
              fontFamily="Segoe UI"
            >
              {trade.tradeType === TradeType.EXACT_INPUT
                ? "Minimum received"
                : "Maximum sold"}
            </Box>
            <LightTooltip title="Your transaction will revert if there is a large, unfavorable price movement before it is confirmed.">
              <IconButton>
                <HelpOutlineIcon
                  style={{
                    color: theme.palette.common.black,
                    fontSize: matches ? "17px" : "22px",
                  }}
                />
              </IconButton>
            </LightTooltip>
          </Box>

          <Box
            color={theme.palette.common.black}
            fontSize={matches ? "12px" : "17px"}
            textAlign="center"
            fontWeight="400"
            fontFamily="Segoe UI"
          >
            {trade.tradeType === TradeType.EXACT_INPUT
              ? slippageAdjustedAmounts[Field.OUTPUT]?.toSignificant(4) ?? "-"
              : slippageAdjustedAmounts[Field.INPUT]?.toSignificant(4) ??
                "-"}{" "}
            {trade.tradeType === TradeType.EXACT_INPUT
              ? trade.outputAmount.currency.symbol
              : trade.inputAmount.currency.symbol}
          </Box>
        </Box>
        <Box
          px={3}
          display="flex"
          alignItems="center"
          justifyContent="space-between"
        >
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <Box
              color={theme.palette.common.black}
              fontSize={matches ? "12px" : "17px"}
              textAlign="center"
              fontWeight="400"
              fontFamily="Segoe UI"
            >
              Price Impact
            </Box>
            <LightTooltip title="The difference between the market price and your price due to trade size.">
              <IconButton>
                <HelpOutlineIcon
                  style={{
                    color: theme.palette.common.black,
                    fontSize: matches ? "17px" : "22px",
                  }}
                />
              </IconButton>
            </LightTooltip>
          </Box>
          <Box
            color={
              severity === 3 || severity === 4
                ? "#ED4B9E"
                : severity === 2
                ? "#F0B90B"
                : severity === 1
                ? "#EAE2FC"
                : "#31D0AA"
            }
            fontSize={matches ? "12px" : "17px"}
            textAlign="center"
            fontWeight="600"
            fontFamily="Segoe UI"
          >
            {priceImpactWithoutFee
              ? priceImpactWithoutFee.lessThan(ONE_BIPS)
                ? "<0.01%"
                : `${priceImpactWithoutFee.toFixed(2)}%`
              : "-"}
          </Box>
        </Box>
        <Box
          px={3}
          display="flex"
          alignItems="center"
          justifyContent="space-between"
        >
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <Box
              color={theme.palette.common.black}
              fontSize={matches ? "12px" : "17px"}
              textAlign="center"
              fontWeight="400"
              fontFamily="Segoe UI"
            >
              Liquidity Provider Fee
            </Box>
            <LightTooltip title="For each trade a 0.2% fee is paid. 0.17% goes to liquidity providers and 0.03% goes to the PancakeSwap treasury.">
              <IconButton>
                <HelpOutlineIcon
                  style={{
                    color: theme.palette.common.black,
                    fontSize: matches ? "17px" : "22px",
                  }}
                />
              </IconButton>
            </LightTooltip>
          </Box>
          <Box
            color={theme.palette.common.black}
            fontSize={matches ? "12px" : "17px"}
            textAlign="center"
            fontWeight="400"
            fontFamily="Segoe UI"
          >
            {realizedLPFee
              ? `${realizedLPFee?.toSignificant(6)} ${
                  trade.inputAmount.currency.symbol
                }`
              : "-"}
          </Box>
        </Box>
      </Box>

      <Box mt={2}>
        <Button
          onClick={onConfirm}
          disabled={disabledConfirm}
          variant={severity > 2 ? "danger" : "primary"}
          style={{
            background: severity > 2 ? "red" : theme.palette.secondary.main,
            width: "90%",
            color: "#fff",
            fontWeight: matches ? "400" : "500",
            height: "50px",
            fontSize: matches ? "13px" : "17px",
          }}
          mt="10px"
          id="confirm-swap-or-send"
          width="100%"
        >
          {severity > 2 ? "Swap Anyway" : "Confirm Swap"}
        </Button>
      </Box>
    </>
  );
}
