import { Box, IconButton, useTheme } from "@mui/material";
import React from "react";
import { TradeType } from "seek-swap-sdk";
import { useLastTruthy } from "../../../hooks/useLast";
import { useUserSlippageTolerance } from "../../../state/user/hooks";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";

import {
  computeSlippageAdjustedAmounts,
  computeTradePriceBreakdown,
  warningSeverity,
} from "../../../utils/prices";
import { LightTooltip } from "../swap/AdvancedSwapDetails";
import { ONE_BIPS } from "../../../constants";
import { Field } from "../../../state/swap/actions";

export default function AdvancedSwapDetailsDropdown({ trade, ...rest }) {
  const theme = useTheme();
  const lastTrade = useLastTruthy(trade) || undefined;
  const [allowedSlippage] = useUserSlippageTolerance();
  const { priceImpactWithoutFee, realizedLPFee } =
    computeTradePriceBreakdown(lastTrade);

  const isExactIn = lastTrade
    ? lastTrade?.tradeType === TradeType.EXACT_INPUT
    : false;
  const slippageAdjustedAmounts = computeSlippageAdjustedAmounts(
    lastTrade,
    allowedSlippage
  );
  const severity = warningSeverity(priceImpactWithoutFee);
  return (
    <Box display="flex" justifyContent="center" mb={5} mt="40px">
      {trade ? (
        <Box
          display="flex"
          flexDirection="column"
          borderRadius="20px"
          width={{ xs: "100%", sm: "100%" }}
          bgcolor={"#202A80"}
          // border={`1px solid ${theme.palette.secondary.main}`}
          p={2}
        >
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              fontSize="14px"
              textAlign="center"
              fontWeight="500"
              fontFamily="Segoe UI"
              mt={1}
              color={theme.palette.common.black}
            >
              {isExactIn ? "Minimum received" : "Maximum sold"}
              <LightTooltip
                title={
                  "Your transaction will revert if there is a large, unfavorable price movement before it is confirmed."
                }
              >
                <IconButton aria-label="delete">
                  <HelpOutlineIcon
                    style={{ color: theme.palette.common.black }}
                  />
                </IconButton>
              </LightTooltip>
            </Box>
            <Box
              display="flex"
              justifyContent="space-between"
              fontSize="14px"
              textAlign="center"
              fontWeight="500"
              fontFamily="Segoe UI"
              mt={1}
              color={theme.palette.common.black}
            >
              {isExactIn
                ? `${slippageAdjustedAmounts[Field?.OUTPUT]?.toSignificant(
                    4
                  )} ${lastTrade?.outputAmount.currency.symbol}` ?? "-"
                : `${slippageAdjustedAmounts[Field?.INPUT]?.toSignificant(4)} ${
                    lastTrade?.inputAmount.currency.symbol
                  }` ?? "-"}
            </Box>
          </Box>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Box
              display="flex"
              justifyContent="space-between"
              fontSize="14px"
              alignItems="center"
              textAlign="center"
              fontWeight="500"
              fontFamily="Segoe UI"
              color={theme.palette.common.black}
            >
              Price Impact
              <LightTooltip
                title={
                  "The difference between the market price and estimated price due to trade size."
                }
              >
                <IconButton aria-label="delete">
                  <HelpOutlineIcon
                    style={{ color: theme.palette.common.black }}
                  />
                </IconButton>
              </LightTooltip>
            </Box>
            <Box
              display="flex"
              justifyContent="space-between"
              fontSize="14px"
              textAlign="center"
              fontWeight="500"
              fontFamily="Segoe UI"
              mt={1}
              color={
                severity === 3 || severity === 4
                  ? "#ED4B9E"
                  : severity === 2
                  ? "#F0B90B"
                  : severity === 1
                  ? "#EAE2FC"
                  : "#31D0AA"
              }
            >
              {priceImpactWithoutFee
                ? priceImpactWithoutFee.lessThan(ONE_BIPS)
                  ? "<0.01%"
                  : `${priceImpactWithoutFee.toFixed(2)}%`
                : "-"}
            </Box>
          </Box>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Box
              display="flex"
              justifyContent="space-between"
              fontSize="14px"
              alignItems="center"
              textAlign="center"
              fontWeight="500"
              fontFamily="Segoe UI"
              color={theme.palette.common.black}
            >
              Liquidity Provider Fee
              <LightTooltip
                title={
                  "For each trade a 0.2% fee is paid. 0.17% goes to liquidity providers and 0.03% goes to the PancakeSwap treasury."
                }
              >
                <IconButton aria-label="delete">
                  <HelpOutlineIcon
                    style={{ color: theme.palette.common.black }}
                  />
                </IconButton>
              </LightTooltip>
            </Box>
            <Box
              display="flex"
              justifyContent="space-between"
              fontSize="14px"
              textAlign="center"
              fontWeight="500"
              fontFamily="Segoe UI"
              color={theme.palette.common.black}
            >
              {realizedLPFee
                ? `${realizedLPFee.toSignificant(4)} ${
                    lastTrade.inputAmount.currency.symbol
                  }`
                : "-"}
            </Box>
          </Box>
        </Box>
      ) : null}
    </Box>
  );
}
