import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  CircularProgress,
  Container,
  Divider,
  useMediaQuery,
} from "@mui/material";
import { JSBI, Pair, Percent } from "seek-swap-sdk";
import { useTheme } from "@mui/material";
import { currencyId } from "../../utils/currencyId";
import React, { useContext, useState } from "react";
import { useTokenBalance } from "../../state/wallet/hooks";
import { ExpandMoreIcon } from "../../utils/images";
import { unwrappedToken } from "../../utils/wrappedCurrency";
import bnbImg from "./../home/assests/bnbImg.png";
import { useTotalSupply } from "../../data/TotalSupply";
import DoubleCurrencyLogo from "../small components/DoubleCurrencyLogo/DoubleCurrencyLogo";
import CurrencyLogo from "../small components/CurrencyLogo/CurrencyLogo";
import { Link } from "react-router-dom";
import { AppContext } from "../../utils/Utils";

function AddRemoveLiquidityAccordian({ pair }) {
  const theme = useTheme();
  const [expanded, setExpanded] = React.useState(false);
  const matches = useMediaQuery("(max-width:750px)");
  const { account } = useContext(AppContext);

  const currency0 = unwrappedToken(pair.token0);
  const currency1 = unwrappedToken(pair.token1);

  const [showMore, setShowMore] = useState(false);

  const userPoolBalance = useTokenBalance(
    account ?? undefined,
    pair.liquidityToken
  );
  const totalPoolTokens = useTotalSupply(pair.liquidityToken);

  const poolTokenPercentage =
    !!userPoolBalance &&
    !!totalPoolTokens &&
    JSBI.greaterThanOrEqual(totalPoolTokens.raw, userPoolBalance.raw)
      ? new Percent(userPoolBalance.raw, totalPoolTokens.raw)
      : undefined;

  const [token0Deposited, token1Deposited] =
    !!pair &&
    !!totalPoolTokens &&
    !!userPoolBalance &&
    // this condition is a short-circuit in the case where useTokenBalance updates sooner than useTotalSupply
    JSBI.greaterThanOrEqual(totalPoolTokens.raw, userPoolBalance.raw)
      ? [
          pair.getLiquidityValue(
            pair.token0,
            totalPoolTokens,
            userPoolBalance,
            false
          ),
          pair.getLiquidityValue(
            pair.token1,
            totalPoolTokens,
            userPoolBalance,
            false
          ),
        ]
      : [undefined, undefined];

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <>
      <Box width="100%">
        <Container maxWidth="sm">
          <Box width="100%">
            <Box
              width="100%"
              marginTop="20px"
              bgcolor={theme.palette.primary.contrastText}
              style={{
                borderStyle: "solid",
                borderWidth: "1px",
                borderColor: theme.palette.secondary.main,
                borderRadius: "10px",
              }}
            >
              <Accordion
                expanded={expanded === "panel1"}
                onChange={handleChange("panel1")}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1bh-content"
                  id="panel1bh-header"
                >
                  <Box display="flex" alignItems="center">
                    <DoubleCurrencyLogo
                      currency0={currency0}
                      currency1={currency1}
                      size={20}
                    />
                    <Box
                      fontSize={matches ? "14px" : "17px"}
                      color={theme.palette.common.black}
                      fontFamily="'Roboto', sans-serif"
                      ml={1}
                    >
                      {!currency0 || !currency1
                        ? `Loading ${(<CircularProgress size={20} />)}`
                        : `${currency0.symbol}/${currency1.symbol}`}
                    </Box>
                  </Box>
                </AccordionSummary>
                <Divider
                  style={{
                    width: "100%",
                    backgroundColor: theme.palette.common.black,
                    height: "1px",
                    marginTop: "-6px",
                  }}
                />
                <AccordionDetails>
                  <Box
                    display="flex"
                    flexDirection="column"
                    width="100%"
                    mt={1}
                  >
                    <Box
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                      width="100%"
                    >
                      <Box
                        fontFamily="Segoe UI"
                        fontSize="15px"
                        fontWeight="400"
                        color={theme.palette.common.black}
                      >
                        Pooled {currency0.symbol}:
                      </Box>
                      <Box
                        alignSelf="right"
                        display="flex"
                        alignItems="center"
                        fontFamily="Segoe UI"
                        fontSize="15px"
                        fontWeight="400"
                        color={theme.palette.common.black}
                      >
                        {token0Deposited
                          ? token0Deposited?.toSignificant(6)
                          : "-"}
                        <CurrencyLogo size="20px" currency={currency0} />
                      </Box>
                    </Box>
                    <Box
                      mt={1}
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                      width="100%"
                    >
                      <Box
                        fontFamily="Segoe UI"
                        fontSize="15px"
                        fontWeight="400"
                        color={theme.palette.common.black}
                      >
                        Pooled {currency1.symbol}:
                      </Box>
                      <Box
                        alignSelf="right"
                        display="flex"
                        alignItems="center"
                        fontFamily="Segoe UI"
                        fontSize="15px"
                        fontWeight="400"
                        color={theme.palette.common.black}
                      >
                        {token1Deposited?.toSignificant(6)}
                        <CurrencyLogo
                          size="20px"
                          style={{ marginLeft: "8px" }}
                          currency={currency1}
                        />
                      </Box>
                    </Box>
                    <Box
                      mt={1}
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                      width="100%"
                    >
                      <Box
                        fontFamily="Segoe UI"
                        fontSize="15px"
                        fontWeight="400"
                        color={theme.palette.common.black}
                      >
                        Your Pool Tokens:
                      </Box>
                      <Box
                        alignSelf="right"
                        fontFamily="Segoe UI"
                        fontSize="15px"
                        fontWeight="400"
                        color={theme.palette.common.black}
                      >
                        {userPoolBalance
                          ? userPoolBalance.toSignificant(4)
                          : "-"}
                      </Box>
                    </Box>
                    <Box
                      mt={1}
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                      width="100%"
                    >
                      <Box
                        fontFamily="Segoe UI"
                        fontSize="15px"
                        fontWeight="400"
                        color={theme.palette.common.black}
                      >
                        Your Pool Share:
                      </Box>
                      <Box
                        alignSelf="right"
                        display="flex"
                        alignItems="center"
                        fontFamily="Segoe UI"
                        fontSize="15px"
                        fontWeight="400"
                        color={theme.palette.common.black}
                      >
                        {poolTokenPercentage
                          ? `${poolTokenPercentage.toFixed(2)}%`
                          : "-"}
                      </Box>
                    </Box>
                    <Box
                      mt={2}
                      display="flex"
                      flexDirection="row"
                      justifyContent="space-between"
                    >
                      <Box
                        width="120px"
                        borderRadius="10px"
                        bgcolor={theme.palette.secondary.main}
                        px={2}
                        py={1}
                        style={{ cursor: "pointer", textDecoration: "none" }}
                        fontFamily="Segoe UI"
                        fontSize="18px"
                        fontWeight="600"
                        color={theme.palette.secondary.contrastText}
                        component={Link}
                        to={`/add/${currencyId(currency0)}/${currencyId(
                          currency1
                        )}`}
                      >
                        Add
                      </Box>
                      <Box
                        width="120px"
                        borderRadius="10px"
                        bgcolor={theme.palette.secondary.main}
                        px={2}
                        py={1}
                        style={{ cursor: "pointer", textDecoration: "none" }}
                        fontFamily="Segoe UI"
                        fontSize="18px"
                        fontWeight="600"
                        color={theme.palette.secondary.contrastText}
                        component={Link}
                        to={`/remove/${currencyId(currency0)}/${currencyId(
                          currency1
                        )}`}
                      >
                        Remove
                      </Box>
                    </Box>
                  </Box>
                </AccordionDetails>
              </Accordion>
            </Box>
          </Box>
        </Container>
      </Box>
    </>
  );
}

export default AddRemoveLiquidityAccordian;
