import React, { useState, useEffect, useContext } from "react";
import { Redirect, Route } from "react-router-dom";
import { UniswapFindPools } from "./uniswap/components/trade/FindPools";
import { UniswapPool } from "./uniswap/components/trade/pool";
import { UniSwap } from "./uniswap/components/trade/swap";
import { UniswapAddLiquidity } from "./uniswap/components/trade/add liquidity";
import {
  UniswapRedirectDuplicateTokenIds,
  UniswapRedirectOldAddLiquidityPathStructure,
} from "./uniswap/components/trade/add liquidity/redirects";
import { UniswapRedirectOldRemoveLiquidityPathStructure } from "./uniswap/components/trade/RemoveLiquidity/redirects";

import { UniswapRemoveLiquidity } from "./uniswap/components/trade/RemoveLiquidity/index";
import { Switch } from "react-router-dom";
import NetworkChange from "./networkSwitch";
import { AppContext } from "./uniswap/utils/Utils";
import TransectionModal from "./uniswap/components/models/TransectionSubmittedModal";
import Lottery from "./uniswap/components/lottery";
import { Box } from "@mui/material";
// synergyswap
function App({ open, setOpen, setopenConnect, setbg }) {
  const { account, connect } = useContext(AppContext);
  const [changeNetwork, setChangeNetwork] = useState(false);
  useEffect(() => {
    const tag = document.getElementsByTagName("iframe");
    if (tag?.length > 0) {
    }
    // if (!account) {
    //   connect();
    // }
  }, [account]);

  return (
    <>
      <Box
        style={{
          width: "100%",
          height: "100%",
          padding: "70px  0px",
          mixBlendMode: "normal",
          minHeight: "100vh",
        }}
      >
        <TransectionModal
          openTransectionModa={open}
          setopenTransectionModa={setOpen}
        />

        {/* <Route exact path="#">
        <ComingSoon />
      </Route> */}
        <Switch>
          <Route exact path="/">
            <Box style={{ opacity: 1 }}>
              <UniSwap setbg={setbg} />
            </Box>
          </Route>
          <Route exact path="/swap/pool">
            <UniswapPool setopenConnect={setopenConnect} setbg={setbg} />
          </Route>
          <Route
            exact
            path="/swap/add"
            component={<UniswapAddLiquidity setopenConnect={setopenConnect} />}
          />
          <Route
            exact
            strict
            path="/remove/:currencyIdA/:currencyIdB"
            component={UniswapRemoveLiquidity}
          />
          <Route
            exact
            path="/add/:currencyIdA"
            component={UniswapRedirectOldAddLiquidityPathStructure}
          />
          <Route
            exact
            path="/add/:currencyIdA/:currencyIdB"
            component={UniswapRedirectDuplicateTokenIds}
          />
          <Route
            exact
            path="/remove/:tokens"
            component={UniswapRedirectOldRemoveLiquidityPathStructure}
          />
          <Route exact path="/findpools">
            <UniswapFindPools />
          </Route>
          <Route exact path="/lottery">
            <Lottery />
          </Route>
          <Route exact path="*">
            <Redirect to="/" />
          </Route>
        </Switch>
      </Box>
    </>
  );
}

export default App;
