import {
  Box,
  Container,
  Grid,
  IconButton,
  Table,
  TableContainer,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import moment from "moment";
import c3 from "./assets/c3.png";
import chart from "./assets/chart.png";
// import { motion } from "framer-motion";

// TABS
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
// import "./styles.css";
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
} from "recharts";
import CurrencyLogo from "../../small components/CurrencyLogo/CurrencyLogo";
import axios from "axios";
import { url } from "../../../constants/lists";
import { Stack } from "@mui/system";
import { useContext } from "react";
import { AppContext } from "../../../utils/Utils";
import { wrappedCurrency } from "../../../utils/wrappedCurrency";

const tabsStyles = {
  "& .Mui-selected": {
    color: "#fff !important",
    background: "#F1620A",
    borderRadius: "21.1433px",
    fontSize: { xs: "12px", sm: "15px" },
  },
  "& .MuiTabs-indicator": {
    display: "none",
  },
  backgroundColor: "#060C3B",
  minHeight: "10px",
  borderRadius: "21.1433px",
};

const tabStyles = {
  fontFamily: "'Roboto'",
  fontStyle: "normal",
  fontWeight: 400,
  fontSize: "15.1024px",
  borderRadius: "21.1433px",
  minHeight: "20px",
};

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}
TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};
function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const chartData = [
  {
    // name: "Page A",
    uv: 4000,
    pv: 2400,
    amt: 2400,
  },
  {
    // name: "Page B",
    uv: 3000,
    pv: 1398,
    amt: 2210,
  },
  {
    // name: "Page C",
    uv: 2000,
    pv: 9800,
    amt: 2290,
  },
  {
    // name: "Page D",
    uv: 2780,
    pv: 3908,
    amt: 2000,
  },
  {
    // name: "Page E",
    uv: 1890,
    pv: 4800,
    amt: 2181,
  },
  {
    // name: "Page F",
    uv: 2390,
    pv: 3800,
    amt: 2500,
  },
  {
    // name: "Page G",
    uv: 3490,
    pv: 4300,
    amt: 2100,
  },
  {
    // name: "Page G",
    uv: 2090,
    pv: 40,
    amt: 100,
  },
  {
    // name: "Page G",
    uv: 2790,
    pv: 4300,
    amt: 2100,
  },
];

const CustomChart = ({ data }) => {
  const [value, setValue] = useState(0);
  const [chartData, setchartData] = useState([]);
  const [changePrice, setchangePrice] = useState(0);
  let { chainId } = useContext(AppContext);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  let seconds = {
    0: 86400,
    1: 604800,
    2: 2592000,
    3: 31536000,
  };
  let { currency1, currency2 } = data;

  let getChartData = async () => {
    let curreA = wrappedCurrency(currency1, 56);
    let curreB = wrappedCurrency(currency2, 56);
    let tokenCurrencies =
      curreA?.address?.toLowerCase() < curreB?.address?.toLowerCase()
        ? [curreA, curreB]
        : [curreB, curreA];
    console.log(tokenCurrencies, "data");
    let timeframe = +moment().format("X") - seconds[value];
    let { data } = await axios(
      url +
        `/get-history?token1=${tokenCurrencies[0]?.address}&token2=${tokenCurrencies[1]?.address}&time=${timeframe}`
    );
    data = data.map(({ price, time }) => {
      return {
        price: price[curreA.address],
        time,
      };
    });
    if (+data[data.length - 1]?.price && data[0]?.price) {
      let difference = +data[data.length - 1]?.price - +data[0]?.price;
      let change_price = ((difference / +data[0]?.price) * 100).toFixed(2);
      setchangePrice(change_price);
    }
    console.log(data, "data");
    setchartData(data);
  };

  useEffect(() => {
    if (currency1 && currency2) {
      getChartData();
    }
  }, [currency1, currency2, value]);

  console.log(currency1, currency2);
  const CustomTooltip = ({ active, payload, label }) => {
    console.log(payload, "payload");
    if (active && payload && payload.length) {
      return (
        <Box
          sx={{
            backgroundColor: "#202A80",
            p: 2,
            border: "1px solid #F1620A",
            outline: "none",
            borderRadius: "10px",
          }}
        >
          <Box
            sx={{ fontSize: "15px", fontWeight: 600, color: "#F1620A" }}
          >{`Price : ${payload[0].value}`}</Box>
          <Box
            sx={{ fontSize: "15px", fontWeight: 600, color: "#F1620A" }}
          >{`Date : ${moment.unix(payload[0].payload.time).format("ll")}`}</Box>
        </Box>
      );
    }

    return null;
  };

  let num = chartData[chartData.length - 1]?.price;
  const decimalStr = num?.toString()?.split(".")[0];

  num = num
    ? +decimalStr < 1
      ? parseFloat(num).toFixed(6)
      : parseFloat(num).toFixed(1)
    : "-";
  console.log(num, decimalStr, "numDecimals");
  return (
    <div>
      <Container maxWidth="md">
        <Box
          sx={{
            background: "#202A80",
            borderRadius: "22.6535px",
            py: "20px",
            px: "30px",
          }}
        >
          <Grid container>
            <Grid item md={6} xs={12}>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: { md: "flex-start", xs: "center" },
                }}
              >
                <Box>
                  {" "}
                  <CurrencyLogo currency={currency1} size="20px" />
                </Box>
                <Box mr={1}>
                  <CurrencyLogo currency={currency2} size="23px" />
                </Box>
                <Box>
                  <Typography
                    sx={{
                      fontFamily: "'Roboto'",
                      fontStyle: "normal",
                      fontWeight: 400,
                      fontSize: "18.1228px",
                      color: "white",
                    }}
                  >
                    {" "}
                    {currency1?.symbol ? currency1?.symbol : "-"}/
                    {currency2?.symbol ? currency2?.symbol : "-"}
                  </Typography>
                </Box>
                <Box ml={1}>
                  {/* <motion.div whileTap={{ transition: 1, rotate: 360 }}> */}
                  <IconButton
                    onClick={() => {
                      if (currency1 && currency2) {
                        getChartData();
                      }
                    }}
                  >
                    <img src={c3} alt="" />{" "}
                  </IconButton>
                  {/* </motion.div> */}
                </Box>
              </Box>
            </Grid>

            <Grid item md={6} xs={12}>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: { md: "flex-end", xs: "center" },
                  mt: { xs: "20px", md: "0px" },
                }}
              >
                {" "}
                <Tabs
                  variant="scrollable"
                  value={value}
                  sx={tabsStyles}
                  onChange={handleChange}
                  aria-label="basic tabs example"
                >
                  <Tab
                    style={{ color: "white" }}
                    sx={tabStyles}
                    label="24H"
                    {...a11yProps(0)}
                  />
                  <Tab
                    style={{ color: "white" }}
                    sx={tabsStyles}
                    label="1W"
                    {...a11yProps(1)}
                  />
                  <Tab
                    style={{ color: "white" }}
                    sx={tabsStyles}
                    label="1M"
                    {...a11yProps(2)}
                  />
                  <Tab
                    style={{ color: "white" }}
                    sx={tabsStyles}
                    label="1Y"
                    {...a11yProps(3)}
                  />
                </Tabs>{" "}
              </Box>
            </Grid>
          </Grid>
          <Stack direction="row" alignItems="center" spacing={2}>
            {" "}
            <Typography
              sx={{
                fontFamily: "'Roboto'",
                fontStyle: "normal",
                fontWeight: 700,
                fontSize: { md: "41.0474px", xs: "25px" },
                color: "white",
                textAlign: { xs: "center", md: "left" },
                mt: { xs: "20px", md: "0px" },
              }}
            >
              {num}
            </Typography>{" "}
            <Typography sx={{ fontSize: "20px", color: "#7381EB" }}>
              {" "}
              {currency1?.symbol ? currency1?.symbol : "-"}/
              {currency2?.symbol ? currency2?.symbol : "-"}{" "}
            </Typography>
            <Typography
              variant="h6"
              mb={0}
              pb={0}
              color={changePrice < 0 ? "red" : "green"}
              textTransform="uppercase"
            >
              ({changePrice < 0 ? "-" : "+"}
              {(+changePrice).toFixed(1)}%)
            </Typography>
          </Stack>
          <Box>
            <Typography
              sx={{
                fontFamily: "'Roboto'",
                fontStyle: "normal",
                fontWeight: 400,
                fontSize: { md: "20.5237px", xs: "16px" },
                color: "#F1620A",
                textAlign: { xs: "center", md: "left" },
                mt: { xs: "20px", md: "0px" },
              }}
            >
              {moment().format("LLLL")}
            </Typography>
          </Box>
          <Box sx={{ mt: { xs: "20px", md: "0px" } }}>
            {" "}
            {/* <img src={chart} width="100%" alt="" />{" "} */}
            {chartData.length > 0 ? (
              <TableContainer
                sx={{
                  overflowX: "scroll",
                  // minWidth: 500,
                  "::-webkit-scrollbar": {
                    width: "0px",
                    height: "0px",
                  },
                }}
              >
                <Table
                  sx={{
                    minWidth: 650,
                  }}
                  aria-label="simple table"
                >
                  <AreaChart
                    width={820}
                    height={400}
                    data={chartData}
                    margin={{
                      top: 10,
                      right: 30,
                      left: 0,
                      bottom: 0,
                    }}
                  >
                    {" "}
                    <defs>
                      {/* <linearGradient id="colorUv" x1="1" y1="1" x2="0" y2="0">
                  <stop offset="30%" stopColor="#202A7F" stopOpacity={0.5} />
                  <stop offset="100%" stopColor=" #0D144A" stopOpacity={0.5} />
                </linearGradient> */}
                    </defs>
                    {/* <CartesianGrid strokeDasharray="3 3" /> */}
                    {/* <XAxis dataKey="name" /> */}
                    {/* <YAxis /> */}
                    <Tooltip content={<CustomTooltip />} />
                    <Area
                      type="monotone"
                      dataKey="price"
                      stroke="#F1620A"
                      fill="#0D144A"
                    />
                  </AreaChart>
                </Table>
              </TableContainer>
            ) : (
              <Stack
                textAlign="center"
                justifyContent="center"
                height="100px"
                fontSize="18px"
                fontWeight={600}
                color="white"
              >
                No Chart Data Found.
              </Stack>
            )}
          </Box>
        </Box>
      </Container>
    </div>
  );
};

export default CustomChart;
