import { Box, Button, Container, Grid, Typography } from "@mui/material";
import { Stack } from "@mui/system";
import React from "react";
import prizebg from "./assets/prize.png";

function PrizeFund() {
  return (
    <Container maxWidth="md">
      <Grid
        container
        my={5}
        spacing={4}
        justifyContent={{ xs: "center", sm: "initial" }}
      >
        <Grid item xs={12} sm={6} md={6}>
          <Box height="100%" my={2} borderRadius="15px" position="relative">
            <Typography variant="h5" fontFamily="Russo One" color="white">
              Prize Funds
            </Typography>
            <Typography
              variant="body1"
              mt={1}
              color="white"
              textAlign="justify"
            >
              <ul>
                <li>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                </li>
                <li>Phasellus eu tortor a eros malesuada cursus.</li>
                <li>
                  Proin eu erat facilisis, pharetra lectus ac, efficitur est.
                </li>
                <li>
                  Nullam non ex imperdiet, feugiat sapien eget, viverra orci.
                </li>
                <li>Sed pulvinar enim id libero pulvinar varius.</li>
              </ul>
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12} sm={6} md={6}>
          <Stack alignItems="center">
            <Box
              sx={{
                backgroundImage: `url(${prizebg})`,
                minHeight: "250px",
                width: "250px",
                backgroundRepeat: "no-repeat",
                backgroundSize: "100% 100%",
                ml: { xs: 0, sm: 4 },
              }}
            ></Box>
          </Stack>
        </Grid>
      </Grid>
    </Container>
  );
}

export default PrizeFund;
