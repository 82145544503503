import React, { useState, useEffect, useContext } from "react";
import App from "./App";
import Web3 from "web3";
import { BrowserRouter } from "react-router-dom";
import ETHProviders from "./Eth-Providers";
import SideBar from "./uniswap/components/NavBar/SiderBar";
import { AppContext } from "./uniswap/utils/Utils";

import bg1 from "./assets/bg1.png";
import { Box } from "@mui/material";

function Main() {
  const [openConnect, setopenConnect] = useState(false);
  const [bg, setbg] = useState(false);

  const [open, setOpen] = useState(true);
  const { chainId } = useContext(AppContext);

  return (
    <BrowserRouter>
      <ETHProviders>
        <Box
          style={{
            backgroundImage: `url(${bg1})`,
            backgroundAttachment: "fixed",
            minHeight: "500px",
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
          }}
        >
          <SideBar openConnect={openConnect} setopenConnect={setopenConnect}>
            <App
              open={open}
              setOpen={setOpen}
              setopenConnect={setopenConnect}
              setbg={setbg}
            />
          </SideBar>
        </Box>
      </ETHProviders>
    </BrowserRouter>
  );
}

export default Main;
