import React, { useMemo } from "react";
import { TradeType } from "seek-swap-sdk";
import { AlertTriangle } from "react-feather";
import { Field } from "../../../state/swap/actions";
import { isAddress, shortenAddress } from "../../../utils";
import {
  computeSlippageAdjustedAmounts,
  computeTradePriceBreakdown,
  warningSeverity,
} from "../../../utils/prices";
import CurrencyLogo from "../CurrencyLogo/CurrencyLogo";
import { Box, Button, useMediaQuery, useTheme } from "@mui/material";

export default function SwapModalHeader({
  trade,
  allowedSlippage,
  recipient,
  showAcceptChanges,
  onAcceptChanges,
  onDismiss,
}) {
  const matches = useMediaQuery("(max-width:760px)");

  const slippageAdjustedAmounts = useMemo(
    () => computeSlippageAdjustedAmounts(trade, allowedSlippage),
    [trade, allowedSlippage]
  );
  const { priceImpactWithoutFee } = useMemo(
    () => computeTradePriceBreakdown(trade),
    [trade]
  );
  const priceImpactSeverity = warningSeverity(priceImpactWithoutFee);

  const theme = useTheme();

  return (
    <div
      style={{
        textAlign: "center",
        paddingBottom: "15x",
        width: "100%",
      }}
    >
      <Box
        mb={5}
        paddingLeft="20px"
        paddingRight="20px"
        paddingTop="25px"
        display="flex"
        bgcolor={theme.palette.secondary.main}
        justifyContent="space-between"
        pb="20px"
      >
        <Box
          color={theme.palette.secondary.contrastText}
          fontSize="20px"
          textAlign="left"
          fontWeight="600"
          fontFamily="Segoe UI"
        >
          Confirm Swap
        </Box>
        <Box>
          <i
            onClick={onDismiss}
            style={{
              color: theme.palette.secondary.contrastText,
              textAlign: "right",
              fontSize: "25px",
              cursor: "pointer",
            }}
            className="fal fa-times"
          ></i>
        </Box>
      </Box>
      <Box
        mt={3}
        px={3}
        display="flex"
        alignItems="center"
        justifyContent="space-between"
      >
        <Box
          color={
            showAcceptChanges && trade.tradeType === TradeType.EXACT_OUTPUT
              ? theme.palette.common.black
              : theme.palette.common.black
          }
          display="flex"
          alignItems="center"
          fontSize={matches ? "12px" : "17px"}
          textAlign="center"
          fontWeight="400"
          fontFamily="Segoe UI"
        >
          <CurrencyLogo
            currency={trade.inputAmount.currency}
            size="22px"
            style={{ marginRight: "10px" }}
          />
          {trade.inputAmount.toSignificant(6)}
        </Box>

        <Box
          color={theme.palette.common.black}
          fontSize={matches ? "12px" : "17px"}
          textAlign="center"
          fontWeight="400"
          fontFamily="Segoe UI"
        >
          {trade.inputAmount.currency.symbol}
        </Box>
      </Box>
      <Box>
        {/* <ArrowDownward
          size="16"
          color={theme.colors.textSubtle}
          style={{ marginLeft: "4px", minWidth: "16px" }}
        /> */}
      </Box>
      <Box
        mt={3}
        px={3}
        display="flex"
        alignItems="center"
        justifyContent="space-between"
      >
        <Box
          display="flex"
          alignItems="center"
          fontSize={matches ? "12px" : "17px"}
          textAlign="center"
          fontWeight="400"
          fontFamily="Segoe UI"
          color={
            priceImpactSeverity > 2
              ? "#ED4B9E"
              : showAcceptChanges && trade.tradeType === TradeType.EXACT_INPUT
              ? theme.palette.common.black
              : theme.palette.common.black
          }
        >
          <CurrencyLogo
            currency={trade.outputAmount.currency}
            size="22px"
            style={{ marginRight: "10px" }}
          />
          {trade.outputAmount.toSignificant(6)}
        </Box>
        <Box
          color={theme.palette.common.black}
          fontSize={matches ? "12px" : "17px"}
          textAlign="center"
          fontWeight="400"
          fontFamily="Segoe UI"
        >
          {trade.outputAmount.currency.symbol}
        </Box>
      </Box>
      {showAcceptChanges ? (
        <Box justify="flex-start" gap="0px">
          <Box>
            <Box>
              <AlertTriangle
                size={20}
                style={{ marginRight: "8px", minWidth: 24 }}
              />
              <Box> Price Updated</Box>
            </Box>
            <Button onClick={onAcceptChanges}>Accept</Button>
          </Box>
        </Box>
      ) : null}
      <Box
        color={theme.palette.common.black}
        fontSize={matches ? "12px" : "17px"}
        textAlign="center"
        fontWeight="400"
        fontFamily="Segoe UI"
        style={{ padding: "16px 0 0" }}
      >
        {trade.tradeType === TradeType.EXACT_INPUT ? (
          <Box
            fontSize={matches ? "12px" : "17px"}
            fontFamily="Segoe UI"
            fontWeight="400"
            color={theme.palette.common.black}
          >
            Output is estimated. You will receive at least <br />
            <Box
              display="inline"
              color={theme.palette.secondary.main}
              fontWeight={700}
              fontSize={matches ? "14px" : "18px"}
              fontFamily="Segoe UI"
            >
              {slippageAdjustedAmounts[Field.OUTPUT]?.toSignificant(6)}{" "}
              {trade.outputAmount.currency.symbol}
            </Box>
            {" or the transaction will revert."}
          </Box>
        ) : (
          <Box
            fontSize={matches ? "12px" : "17px"}
            fontFamily="Segoe UI"
            fontWeight="400"
            color={theme.palette.common.black}
          >
            {`Input is estimated. You will sell at most `}
            <span>
              {slippageAdjustedAmounts[Field.INPUT]?.toSignificant(6)}{" "}
              {trade.inputAmount.currency.symbol}
            </span>
            {" or the transaction will revert."}
          </Box>
        )}
      </Box>
      {recipient !== null ? (
        <Box
          color={theme.palette.common.black}
          fontSize={matches ? "13px" : "19px"}
          textAlign="center"
          fontWeight="400"
          fontFamily="Segoe UI"
          style={{ padding: "16px 0 0" }}
        >
          <Box>
            Output will be sent to{" "}
            <Box
              title={recipient}
              color={theme.palette.secondary.main}
              fontSize={matches ? "14px" : "18px"}
              fontWeight={700}
            >
              {isAddress(recipient) ? shortenAddress(recipient) : recipient}
            </Box>
          </Box>
        </Box>
      ) : null}
    </div>
  );
}
