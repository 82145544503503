import React from "react";
import TopBanner from "../trade/TopBanner/TopBanner";
import FinishedRounds from "./finishedRounds";
import HowToPlay from "./howToPlay";
import Lottery from "./lottery";
import MainPage from "./mainPage";
import PrizeFund from "./prizeFund";
import WinningCriteria from "./winningCriteria";

function Index() {
  return (
    <>
      <TopBanner />
      <MainPage />
      <Lottery />
      <FinishedRounds />
      <HowToPlay />
      <WinningCriteria />
      <PrizeFund />
    </>
  );
}

export default Index;
