import { Box, Stack, Typography } from "@mui/material";
import React from "react";

function Timer({ hours, minutes, seconds }) {
  console.log(hours, minutes);
  return (
    <Stack alignItems="center" direction="row" spacing={5}>
      <Box>
        <Typography
          variant="h5"
          mt={5}
          color="white"
          bgcolor="#18287F"
          px={2}
          py={1.5}
          mb={0}
          textAlign="center"
        >
          {hours}
        </Typography>{" "}
        <Typography
          variant="h6"
          color="white"
          bgcolor="#111852"
          px={3}
          py={1}
          mt={0}
          textAlign="center"
        >
          Hours
        </Typography>
      </Box>{" "}
      <Box>
        <Typography
          variant="h5"
          mt={5}
          color="white"
          bgcolor="#18287F"
          px={2}
          py={1.5}
          mb={0}
          textAlign="center"
        >
          {minutes}
        </Typography>{" "}
        <Typography
          variant="h6"
          color="white"
          bgcolor="#111852"
          px={3}
          py={1}
          mt={0}
          textAlign="center"
        >
          Minutes
        </Typography>
      </Box>
      <Box>
        <Typography
          variant="h5"
          mt={5}
          color="white"
          bgcolor="#18287F"
          px={2}
          py={1.5}
          mb={0}
          textAlign="center"
        >
          {seconds}
        </Typography>{" "}
        <Typography
          variant="h6"
          color="white"
          bgcolor="#111852"
          px={3}
          py={1}
          mt={0}
          textAlign="center"
        >
          Seconds
        </Typography>
      </Box>
    </Stack>
  );
}
export default Timer;
