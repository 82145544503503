import {
  Box,
  Button,
  Collapse,
  Container,
  Grid,
  IconButton,
  Skeleton,
  Typography,
} from "@mui/material";
import { Stack } from "@mui/system";
import React, { useContext, useEffect, useState } from "react";
import treasurebg from "./assets/treasure-coins.png";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { AppContext } from "../../utils/Utils";
import { useLotteryContract } from "../ConnectivityAss/hooks";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import CountUp from "react-countup";
import moment from "moment";
function Lottery() {
  const { account, connect, disconnect, signer } = useContext(AppContext);
  const lotteryContract = useLotteryContract(signer);
  const [loading, setloading] = useState(true);
  const [showDetails, setshowDetails] = useState(false);
  const [first, setfirst] = useState("");
  const [lotteryData, setlotteryData] = useState({
    status: 0,
    startTime: 0,
    endTime: 0,
    priceTicketInSEEK: 0,
    discountDivisor: 0,
    rewardsBreakdown: 0, // 0: 1 matching number // 5: 6 matching numbers
    treasuryFee: 0, // 500: 5% // 200: 2% // 50: 0.5%
    SEEKPerBracket: 0,
    countWinnersPerBracket: 0,
    firstTicketId: 0,
    firstTicketIdNextLottery: 0,
    amountCollectedInSEEK: 0,
    finalNumber: 0,
    lotteryId: 0,
  });
  let init = async () => {
    try {
      let lotteryId = await lotteryContract.currentLotteryId();
      let [
        status,
        startTime,
        endTime,
        priceTicketInSEEK,
        discountDivisor,
        rewardsBreakdown, // 0: 1 matching number // 5: 6 matching numbers
        treasuryFee, // 500: 5% // 200: 2% // 50.5%
        SEEKPerBracket,
        countWinnersPerBracket,
        firstTicketId,
        firstTicketIdNextLottery,
        amountCollectedInSEEK,
        finalNumber,
      ] = await lotteryContract.viewLottery(lotteryId);
      let obj = {
        lotteryId: +lotteryId,
        status: +status,
        startTime: +startTime,
        endTime: +endTime,
        priceTicketInSEEK: +priceTicketInSEEK,
        discountDivisor: +discountDivisor,
        rewardsBreakdown, // 0: 1 matching number // 5: 6 matching numbers
        treasuryFee: +treasuryFee, // 500: 5% // 200: 2% // 50.5%
        SEEKPerBracket,
        countWinnersPerBracket,
        firstTicketId: +firstTicketId,
        firstTicketIdNextLottery: +firstTicketIdNextLottery,
        amountCollectedInSEEK: +amountCollectedInSEEK,
        finalNumber: +finalNumber,
      };
      setlotteryData({ ...obj });
      setloading(false);
    } catch (error) {
      setloading(false);
      console.log(error);
    }
  };
  useEffect(() => {
    // init();
  }, []);
  return (
    <Container maxWidth="md">
      {loading ? (
        <Skeleton sx={{ height: 500 }} />
      ) : (
        <Box bgcolor="#202A80" my={2} borderRadius="15px" p={2} id="buy-ticket">
          <Box border="1px solid #F1620A" borderRadius="15px">
            <Stack alignItems="center" p={2}>
              <Stack
                alignItems="center"
                direction="row"
                justifyContent="space-between"
                width="100%"
                borderBottom="2px solid rgba(0,0,0,0.4)"
                pb={1}
              >
                <Typography
                  variant="body1"
                  fontWeight="bold"
                  color="#7381EB"
                  fontSize={{ xs: "0.8rem", sm: "1rem" }}
                >
                  Next Draw
                </Typography>
                <Typography
                  variant="body1"
                  color="#F1620A"
                  fontSize={{ xs: "0.8rem", sm: "1rem" }}
                >
                  #{lotteryData?.lotteryId} | Draw:{" "}
                  {moment.unix(lotteryData?.endTime).format("llll")}
                </Typography>
              </Stack>
              <Typography variant="body1" mt={1} color="white">
                Prize Pot
              </Typography>
              <Stack alignItems="baseline" direction="row" spacing={2}>
                <Typography
                  variant="h3"
                  fontFamily="Russo One"
                  mt={3}
                  color="white"
                >
                  $
                  <CountUp
                    delay={0}
                    start={lotteryData.amountCollectedInSEEK ? null : 0}
                    end={lotteryData.amountCollectedInSEEK}
                  />
                </Typography>
                <Typography variant="body1" mb={0} color="white">
                  in Prizes
                </Typography>{" "}
              </Stack>
              <Typography variant="body1" mb={0} color="white">
                <CountUp
                  delay={0}
                  start={lotteryData.amountCollectedInSEEK ? null : 0}
                  end={lotteryData.amountCollectedInSEEK}
                />{" "}
                SEEK
              </Typography>
              <Button
                sx={{
                  cursor: "pointer",
                  textShadow: "0px 5px 10px #00000029",
                  bgcolor: "#F1620A",
                  color: "#fff",
                  borderRadius: "30px",
                  fontFamily: "Russo One",
                  px: 5,
                  py: 0.5,
                  my: 2,
                  "&:hover": {
                    bgcolor: "#F1620Ab1",
                  },
                }}
                onClick={account && connect}
              >
                {account ? "Buy Ticket" : "Connect Wallet"}
              </Button>
            </Stack>
          </Box>
          {!showDetails && (
            <Stack
              alignItems="center"
              direction="row"
              justifyContent="center"
              width="100%"
              pb={1}
              my={2}
            >
              <IconButton
                onClick={() => setshowDetails(true)}
                sx={{
                  borderRadius: "5px",
                }}
              >
                <Typography
                  variant="body1"
                  fontWeight="bold"
                  color="#7381EB"
                  mr={1}
                >
                  Details
                </Typography>
                <Typography variant="body2" fontWeight="bold" color="#7381EB">
                  <KeyboardArrowDownIcon />
                </Typography>
              </IconButton>
            </Stack>
          )}
          <Collapse in={showDetails}>
            <Box p={4}>
              <Typography variant="body1" color="white" mt={4}>
                Match the winning number in the same order to share prizes.
                Current prizes up for grabs:
              </Typography>

              <Grid container width="100%" mt={3}>
                {lotteryData?.SEEKPerBracket?.map((number, index) => (
                  <Grid item xs={6} sm={4} md={3} key={+number}>
                    <Box mt={3}>
                      <Typography
                        variant="body1"
                        fontWeight="bold"
                        color="#7381EB"
                      >
                        Match first {index + 1}
                      </Typography>
                      <Typography
                        variant="h6"
                        fontFamily="Russo One"
                        color="white"
                      >
                        <CountUp
                          delay={0}
                          start={showDetails ? null : 0}
                          end={+number}
                        />{" "}
                        SEEK
                      </Typography>
                      <Typography variant="body2" color="#F1620A">
                        ~${" "}
                        <CountUp
                          delay={0}
                          start={showDetails ? null : 0}
                          end={+number}
                        />
                      </Typography>
                    </Box>
                  </Grid>
                ))}
                <Grid item xs={6} sm={4} md={3}>
                  <Box mt={3}>
                    <Typography variant="body1" fontWeight="bold" color="red">
                      Burn
                    </Typography>
                    <Typography
                      variant="h6"
                      fontFamily="Russo One"
                      color="white"
                    >
                      <CountUp
                        delay={0}
                        start={showDetails ? null : 0}
                        end={+lotteryData?.treasuryFee}
                      />{" "}
                      SEEK
                    </Typography>
                    <Typography variant="body2" color="#F1620A">
                      ~${" "}
                      <CountUp
                        delay={0}
                        start={showDetails ? null : 0}
                        end={+lotteryData?.treasuryFee}
                      />
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
            </Box>
            <Stack
              alignItems="center"
              direction="row"
              justifyContent="center"
              width="100%"
              pb={1}
              my={2}
            >
              <IconButton
                onClick={() => setshowDetails(false)}
                sx={{
                  borderRadius: "5px",
                }}
              >
                <Typography
                  variant="body1"
                  fontWeight="bold"
                  color="#7381EB"
                  mr={1}
                >
                  Hide
                </Typography>
                <Typography variant="body2" fontWeight="bold" color="#7381EB">
                  <KeyboardArrowUpIcon />
                </Typography>
              </IconButton>
            </Stack>
          </Collapse>
        </Box>
      )}
      <Box
        sx={{
          backgroundImage: `url(${treasurebg})`,
          minHeight: { xs: "200px", sm: "300px" },
          backgroundRepeat: "no-repeat",
          backgroundSize: "100% 100%",
          mt: 4,
        }}
      >
        <Stack alignItems="center">
          <Typography
            variant="h5"
            width={{ xs: "100%", sm: "40%" }}
            mt={5}
            color="white"
            textAlign="center"
            fontWeight="bold"
          >
            Connect your wallet to check if you've won!
          </Typography>
          <Button
            sx={{
              cursor: "pointer",
              textShadow: "0px 5px 10px #00000029",
              bgcolor: "#F1620A",
              color: "#fff",
              borderRadius: "30px",
              fontFamily: "Russo One",
              px: 4,
              py: 0.5,
              mt: 4,
              "&:hover": {
                bgcolor: "#F1620Ab1",
              },
            }}
            onClick={account ? disconnect : connect}
          >
            {account
              ? account.slice(0, 6) + "..." + account.slice(-4)
              : "Connect Wallet"}
          </Button>
        </Stack>
      </Box>
    </Container>
  );
}

export default Lottery;
