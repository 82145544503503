import { Web3Provider } from "@ethersproject/providers";
import { InjectedConnector } from "@web3-react/injected-connector";
import { WalletConnectConnector } from "@web3-react/walletconnect-connector";
import { WalletLinkConnector } from "@web3-react/walletlink-connector";
import { BscConnector } from "@binance-chain/bsc-connector";
import { NetworkConnector } from "./NetworkConnector";

const NETWORK_URL =
  // "https://kovan.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161";
  "https://mainnet.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161";

export const NETWORK_CHAIN_ID = 1;

if (typeof NETWORK_URL === "undefined") {
  throw new Error(
    `REACT_APP_NETWORK_URL must be a defined environment variable`
  );
}

export const network = new NetworkConnector({
  urls: { [NETWORK_CHAIN_ID]: NETWORK_URL },
});

let networkLibrary;
export function getNetworkLibrary() {
  // eslint-disable-next-line no-return-assign
  return (networkLibrary =
    networkLibrary ?? new Web3Provider(network.provider));
}

export const injected = new InjectedConnector({
  supportedChainIds: [1],
});

export const bscConnector = new BscConnector({ supportedChainIds: [1] });

// mainnet only
export const walletconnect = new WalletConnectConnector({
  rpc: { 1: "https://mainnet.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161" },
  bridge: "https://bridge.walletconnect.org",
  qrcode: true,
  pollingInterval: 15000,
  logo: "https://mpng.pngfly.com/20181202/bex/kisspng-emoji-domain-unicorn-pin-badges-sticker-unicorn-tumblr-emoji-unicorn-iphoneemoji-5c046729264a77.567167931543792151569.jpg",
});

// mainnet only
export const walletlink = new WalletLinkConnector({
  url: "https://mainnet.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161",
  appName: "Uniswap",
  appLogoUrl:
    "https://mpng.pngfly.com/20181202/bex/kisspng-emoji-domain-unicorn-pin-badges-sticker-unicorn-tumblr-emoji-unicorn-iphoneemoji-5c046729264a77.567167931543792151569.jpg",
});

export const connectorsByName = {
  injected: injected,
  walletConnect: walletconnect,
  bsc: bscConnector,
};
