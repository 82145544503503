import {
  Grid,
  Typography,
  Box,
  useMediaQuery,
  CircularProgress,
} from "@mui/material";
import { Container } from "@mui/system";
import axios from "axios";
import React, { useEffect } from "react";
import { useState } from "react";
import Marquee from "react-fast-marquee";
// import { Box } from "react-feather";

const TopBanner = () => {
  const matches = useMediaQuery("(max-width:700px)");
  const [data, setData] = useState([]);
  const getData = async () => {
    let { data } = await axios.get(
      "https://api.coingecko.com/api/v3/coins/markets?per_page=10&page=1&order=market_cap_desc&vs_currency=usd&price_change_percentage=24h,7d,30d&sparkline=true"
    );
    setData(data);
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <div style={{ width: "100%" }}>
      <Container sx={{ display: "flex", justifyContent: "center" }} maxWidth>
        {" "}
        <Marquee
          style={{
            background: "#202A80",
            boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.25)",
            width: "100%",
            borderRadius: { xs: "22.6535px", md: "0px" },

            padding: "10px 10px",
            borderRadius: "30px",
          }}
        >
          {data ? (
            data.map(
              (
                {
                  current_price,
                  price_change_percentage_24h_in_currency,
                  price_change_percentage_30d_in_currency,
                  price_change_percentage_7d_in_currency,
                  symbol,
                  name,
                },
                i
              ) => (
                <Box
                  sx={{
                    color: "white",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    borderRight: matches ? "none" : "3px solid #010524",
                    padding: "5px",
                  }}
                >
                  <Box px="10px">
                    {" "}
                    <Typography
                      sx={{
                        color: "white",
                        fontFamily: "'Roboto'",
                        fontStyle: "normal",
                        fontWeight: 500,
                        fontSize: "16px",
                      }}
                    >
                      {name} {symbol.toUpperCase()}
                    </Typography>{" "}
                  </Box>
                  <Box px="10px">
                    {" "}
                    <Typography
                      sx={{
                        color: "white",
                        fontFamily: "'Roboto'",
                        fontStyle: "normal",
                        fontWeight: 700,
                        fontSize: "12px",
                      }}
                    >
                      ${current_price}
                    </Typography>{" "}
                  </Box>
                  <Box px="10px">
                    {" "}
                    <Typography
                      sx={{
                        color: "white",
                        fontFamily: "'Roboto'",
                        fontStyle: "normal",
                        fontWeight: 700,
                        fontSize: "12px",
                        color:
                          +price_change_percentage_24h_in_currency < 0
                            ? "#FF2954"
                            : "#4C8119",
                      }}
                    >
                      {+price_change_percentage_24h_in_currency.toFixed(2)}%
                    </Typography>{" "}
                  </Box>
                  <Box px="10px">
                    {" "}
                    <Typography
                      sx={{
                        color: "white",
                        fontFamily: "'Roboto'",
                        fontStyle: "normal",
                        fontWeight: 700,
                        fontSize: "12px",
                        color:
                          +price_change_percentage_7d_in_currency < 0
                            ? "#FF2954"
                            : "#4C8119",
                      }}
                    >
                      {price_change_percentage_7d_in_currency.toFixed(2)}%
                    </Typography>{" "}
                  </Box>
                  <Box px="10px">
                    {" "}
                    <Typography
                      sx={{
                        color: "white",
                        fontFamily: "'Roboto'",
                        fontStyle: "normal",
                        fontWeight: 700,
                        fontSize: "12px",
                        color:
                          +price_change_percentage_30d_in_currency < 0
                            ? "#FF2954"
                            : "#4C8119",
                      }}
                    >
                      {price_change_percentage_30d_in_currency.toFixed(2)}%
                    </Typography>{" "}
                  </Box>
                </Box>
              )
            )
          ) : (
            <CircularProgress />
          )}
        </Marquee>
      </Container>
    </div>
  );
};

export default TopBanner;
