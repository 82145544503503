import { Box, Container, useTheme } from "@mui/material";

import React from "react";

import { Field } from "../../../state/mint/actions";
import CurrencyLogo from "../../small components/CurrencyLogo/CurrencyLogo";
import CustomizedButton from "../../small components/CustomButton/CustomButton";

function ConfirmAddModalBottom({
  noLiquidity,
  price,
  currencies,
  parsedAmounts,
  poolTokenPercentage,
  onAdd,
}) {
  const theme = useTheme();
  return (
    <>
      <Container maxWidth="sm">
        <Box display="flex" flexDirection="column" p={2}>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Box
              fontFamily="Segoe UI"
              fontSize="15px"
              fontWeight="400"
              color={theme.palette.common.black}
            >
              {currencies[Field.CURRENCY_A]?.symbol} Deposited
            </Box>
            <Box
              display="flex"
              alignItems="center"
              fontFamily="Segoe UI"
              fontSize="15px"
              fontWeight="400"
              color={theme.palette.common.black}
            >
              <CurrencyLogo
                currency={currencies[Field.CURRENCY_A]}
                style={{ marginRight: "8px" }}
                size={20}
              />
              {parsedAmounts[Field.CURRENCY_A]?.toSignificant(6)}
            </Box>
          </Box>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            mt={1}
          >
            <Box
              fontFamily="Segoe UI"
              fontSize="15px"
              fontWeight="400"
              color={theme.palette.common.black}
            >
              {currencies[Field.CURRENCY_B]?.symbol} Deposited
            </Box>
            <Box
              display="flex"
              alignItems="center"
              fontFamily="Segoe UI"
              fontSize="15px"
              fontWeight="400"
              color={theme.palette.common.black}
            >
              <CurrencyLogo
                currency={currencies[Field.CURRENCY_B]}
                style={{ marginRight: "8px" }}
                size={20}
              />
              {parsedAmounts[Field.CURRENCY_B]?.toSignificant(6)}
            </Box>
          </Box>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            mt={1}
          >
            <Box
              fontFamily="Segoe UI"
              fontSize="15px"
              fontWeight="400"
              color={theme.palette.common.black}
            >
              Rates
            </Box>
            <Box
              display="flex"
              alignItems="center"
              fontFamily="Segoe UI"
              fontSize="15px"
              fontWeight="400"
              color={theme.palette.common.black}
            >
              {`1 ${
                currencies[Field.CURRENCY_A]?.symbol
              } = ${price?.toSignificant(4)} ${
                currencies[Field.CURRENCY_B]?.symbol
              }`}
            </Box>
          </Box>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            mt={1}
          >
            <Box
              fontFamily="Segoe UI"
              fontSize="15px"
              fontWeight="400"
              color={theme.palette.common.black}
            ></Box>
            <Box
              display="flex"
              alignItems="center"
              fontFamily="Segoe UI"
              fontSize="15px"
              fontWeight="400"
              color={theme.palette.common.black}
            >
              {`1 ${currencies[Field.CURRENCY_B]?.symbol} = ${price
                ?.invert()
                .toSignificant(4)} ${currencies[Field.CURRENCY_A]?.symbol}`}
            </Box>
          </Box>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            mt={1}
          >
            <Box
              fontFamily="Segoe UI"
              fontSize="15px"
              fontWeight="400"
              color={theme.palette.common.black}
            >
              Share of pool:
            </Box>
            <Box
              display="flex"
              alignItems="center"
              fontFamily="Segoe UI"
              fontSize="15px"
              fontWeight="400"
              color={theme.palette.common.black}
            >
              {noLiquidity ? "100" : poolTokenPercentage?.toSignificant(4)}%
            </Box>
          </Box>
        </Box>
        <CustomizedButton fun={onAdd}>
          {noLiquidity ? "Create Pool & Supply" : "Confirm Supply"}
        </CustomizedButton>
      </Container>
    </>
  );
}

export default ConfirmAddModalBottom;
