import {
  Box,
  Button,
  CircularProgress,
  Container,
  Grid,
  IconButton,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { withStyles } from "@mui/styles";
import { styles } from "../styles/style";
import {
  boxheaderimg,
  CachedIcon,
  stylelines,
  logo,
  Line,
} from "../../../utils/images";
import { INITIAL_ALLOWED_SLIPPAGE } from "../../../constants/index";
import { Field } from "../../../state/swap/actions";
import { ApprovalState } from "../../../hooks/useApproveCallback";
import { useSwapCallback } from "../../../hooks/useSwapCallback";
import confirmPriceImpactWithoutFee from "./confirmPriceImpactWithoutFee";

import { JSBI, Token } from "seek-swap-sdk";
import { useLocation } from "react-router-dom";
import { useTheme } from "@mui/material";
import SlippingTolrance from "../../models/Slippage";
import useWrapCallback, { WrapType } from "../../../hooks/useWrapCallback";
import { TopNav } from "../../small components/top nav/TopNav";
import {
  useDefaultsFromURLSearch,
  useDerivedSwapInfo,
  useSwapActionHandlers,
  useSwapState,
} from "../../../state/swap/hooks";
import { useCurrency } from "../../../hooks/Tokens";

import {
  useExpertModeManager,
  useUserDeadline,
  useUserSlippageTolerance,
} from "../../../state/user/hooks";
import { useApproveCallbackFromTrade } from "../../../hooks/useApproveCallback";
import maxAmountSpend from "../../../utils/maxAmountSpend";
import {
  computeTradePriceBreakdown,
  warningSeverity,
} from "../../../utils/prices";
import CurrencyInput from "../../small components/currency input/CurrencyInput";
import TradePrice from "../../small components/swap/TradePrice";
import CustomizedButton from "../../small components/CustomButton/CustomButton";
import ConfirmSwapModal from "../../small components/swap/ConfirmSwapModal";
import AdvancedSwapDetailsDropdown from "../../small components/AdvancedSwapDetailsDropdown/AdvancedSwapDetailsDropdown";
import SwapStepper from "../../small components/Stepper/Stepper";
import { AiOutlineClockCircle } from "react-icons/ai";
import { AiOutlineSetting } from "react-icons/ai";
import { IoMdSwap } from "react-icons/io";
import icon from "./assets/swap.png";
import TopBanner from "../TopBanner/TopBanner";
import CustomChart from "./CustomChart";
import HistoryTable from "../History/HistoryTable";
import { AppContext } from "../../../utils/Utils";
export const UniSwap = withStyles(styles)(({ classes, setbg }) => {
  // const [active, setActive] = useState(true);
  const [rotaion, setRotation] = useState(false);
  const [openSlippage, setopenSlippage] = useState(false);
  const matches = useMediaQuery("(max-width:600px)");
  const loc = useLocation();
  const rotationHandler = () => {
    if (rotaion) {
      setRotation(false);
    } else {
      setRotation(true);
    }
  };
  useEffect(() => {
    setbg(false);
  }, []);
  const loadedUrlParams = useDefaultsFromURLSearch();
  // const TranslateString = useI18n();
  // token warning stuff
  const [loadedInputCurrency, loadedOutputCurrency] = [
    useCurrency(loadedUrlParams?.inputCurrencyId),
    useCurrency(loadedUrlParams?.outputCurrencyId),
  ];
  const [dismissTokenWarning, setDismissTokenWarning] = useState(false);
  const [transactionWarning, setTransactionWarning] = useState({
    selectedToken: null,
    purchaseType: null,
  });
  const urlLoadedTokens = useMemo(
    () =>
      [loadedInputCurrency, loadedOutputCurrency]?.filter(
        (c) => c instanceof Token
      ) ?? [],
    [loadedInputCurrency, loadedOutputCurrency]
  );
  const handleConfirmTokenWarning = useCallback(() => {
    setDismissTokenWarning(true);
  }, []);
  const handleConfirmWarning = () => {
    setTransactionWarning({
      selectedToken: null,
      purchaseType: null,
    });
  };

  const { account, signer, connect } = useContext(AppContext);
  const theme = useTheme();
  const [isExpertMode] = useExpertModeManager();
  // get custom setting values for user
  const [deadline] = useUserDeadline();
  const [allowedSlippage] = useUserSlippageTolerance();

  // swap state
  const { independentField, typedValue, recipient } = useSwapState();
  const {
    v2Trade,
    currencyBalances,
    parsedAmount,
    currencies,
    inputError: swapInputError,
  } = useDerivedSwapInfo();
  const {
    wrapType,
    execute: onWrap,
    inputError: wrapInputError,
  } = useWrapCallback(
    currencies[Field.INPUT],
    currencies[Field.OUTPUT],
    typedValue
  );
  const showWrap = wrapType !== WrapType.NOT_APPLICABLE;
  const trade = showWrap ? undefined : v2Trade;

  const parsedAmounts = showWrap
    ? {
        [Field.INPUT]: parsedAmount,
        [Field.OUTPUT]: parsedAmount,
      }
    : {
        [Field.INPUT]:
          independentField === Field.INPUT ? parsedAmount : trade?.inputAmount,
        [Field.OUTPUT]:
          independentField === Field.OUTPUT
            ? parsedAmount
            : trade?.outputAmount,
      };

  const {
    onSwitchTokens,
    onCurrencySelection,
    onUserInput,
    onChangeRecipient,
  } = useSwapActionHandlers();
  const isValid = !swapInputError;
  const dependentField =
    independentField === Field.INPUT ? Field.OUTPUT : Field.INPUT;

  const handleTypeInput = useCallback(
    (value) => {
      onUserInput(Field.INPUT, value);
    },
    [onUserInput]
  );
  const handleTypeOutput = useCallback(
    (value) => {
      onUserInput(Field.OUTPUT, value);
    },
    [onUserInput]
  );

  // modal and loading
  const [
    { showConfirm, tradeToConfirm, swapErrorMessage, attemptingTxn, txHash },
    setSwapState,
  ] = useState({
    showConfirm: false,
    tradeToConfirm: undefined,
    attemptingTxn: false,
    swapErrorMessage: undefined,
    txHash: undefined,
  });

  const formattedAmounts = {
    [independentField]: typedValue,
    [dependentField]: showWrap
      ? parsedAmounts[independentField]?.toExact() ?? ""
      : parsedAmounts[dependentField]?.toSignificant(6) ?? "",
  };

  const route = trade?.route;
  const userHasSpecifiedInputOutput = Boolean(
    currencies[Field.INPUT] &&
      currencies[Field.OUTPUT] &&
      parsedAmounts[independentField]?.greaterThan(JSBI.BigInt(0))
  );
  const noRoute = !route;
  const [openConnectModal, setopenConnectModal] = useState(false);
  // check whether the user has approved the router on the input token
  const [approval, approveCallback] = useApproveCallbackFromTrade(
    trade,
    allowedSlippage
  );

  // check if user has gone through approval process, used to show two step buttons, reset on token change
  const [approvalSubmitted, setApprovalSubmitted] = useState(false);

  // mark when a user has submitted an approval, reset onTokenSelection for input field
  useEffect(() => {
    if (approval === ApprovalState.PENDING) {
      setApprovalSubmitted(true);
    }
  }, [approval, approvalSubmitted]);

  const maxAmountInput = maxAmountSpend(currencyBalances[Field.INPUT]);
  const atMaxAmountInput = Boolean(
    maxAmountInput && parsedAmounts[Field.INPUT]?.equalTo(maxAmountInput)
  );

  // the callback to execute the swap
  const { callback: swapCallback, error: swapCallbackError } = useSwapCallback(
    trade,
    allowedSlippage,
    deadline,
    recipient
  );

  const { priceImpactWithoutFee } = computeTradePriceBreakdown(trade);

  const handleSwap = useCallback(() => {
    if (
      priceImpactWithoutFee &&
      !confirmPriceImpactWithoutFee(priceImpactWithoutFee)
    ) {
      return;
    }
    if (!swapCallback) {
      return;
    }
    setSwapState((prevState) => ({
      ...prevState,
      attemptingTxn: true,
      swapErrorMessage: undefined,
      txHash: undefined,
    }));
    swapCallback()
      .then((hash) => {
        setSwapState((prevState) => ({
          ...prevState,
          attemptingTxn: false,
          swapErrorMessage: undefined,
          txHash: hash,
        }));
      })
      .catch((error) => {
        setSwapState((prevState) => ({
          ...prevState,
          attemptingTxn: false,
          swapErrorMessage: error.message,
          txHash: undefined,
        }));
      });
  }, [priceImpactWithoutFee, swapCallback, setSwapState]);

  // errors
  const [showInverted, setShowInverted] = useState(false);

  // warnings on slippage
  const priceImpactSeverity = warningSeverity(priceImpactWithoutFee);

  // show approve flow when: no error on inputs, not approved or pending, or approved in current session
  // never show if price impact is above threshold in non expert mode
  const showApproveFlow =
    !swapInputError &&
    (approval === ApprovalState.NOT_APPROVED ||
      approval === ApprovalState.PENDING ||
      (approvalSubmitted && approval === ApprovalState.APPROVED)) &&
    !(priceImpactSeverity > 3 && !isExpertMode);

  const handleConfirmDismiss = useCallback(() => {
    setSwapState((prevState) => ({ ...prevState, showConfirm: false }));

    // if there was a tx hash, we want to clear the input
    if (txHash) {
      onUserInput(Field.INPUT, "");
    }
  }, [onUserInput, txHash, setSwapState]);

  const handleAcceptChanges = useCallback(() => {
    setSwapState((prevState) => ({ ...prevState, tradeToConfirm: trade }));
  }, [trade]);

  // This will check to see if the user has selected Syrup or SafeMoon to either buy or sell.
  // If so, they will be alerted with a warning message.
  const checkForWarning = useCallback(
    (selected, purchaseType) => {
      if (["SYRUP", "SAFEMOON"].includes(selected)) {
        setTransactionWarning({
          selectedToken: selected,
          purchaseType,
        });
      }
    },
    [setTransactionWarning]
  );

  const handleInputSelect = useCallback(
    (inputCurrency) => {
      setApprovalSubmitted(false); // reset 2 step UI for approvals
      onCurrencySelection(Field.INPUT, inputCurrency);
      if (inputCurrency.symbol === "SYRUP") {
        checkForWarning(inputCurrency.symbol, "Selling");
      }
      if (inputCurrency.symbol === "SAFEMOON") {
        checkForWarning(inputCurrency.symbol, "Selling");
      }
    },
    [onCurrencySelection, setApprovalSubmitted, checkForWarning]
  );

  const handleMaxInput = useCallback(() => {
    if (maxAmountInput) {
      onUserInput(Field.INPUT, maxAmountInput.toExact());
    }
  }, [maxAmountInput, onUserInput]);

  const handleOutputSelect = useCallback(
    (outputCurrency) => {
      onCurrencySelection(Field.OUTPUT, outputCurrency);
      if (outputCurrency.symbol === "SYRUP") {
        checkForWarning(outputCurrency.symbol, "Buying");
      }
      if (outputCurrency.symbol === "SAFEMOON") {
        checkForWarning(outputCurrency.symbol, "Buying");
      }
    },
    [onCurrencySelection, checkForWarning]
  );

  return (
    <>
      <Box
      // style={{
      //   backgroundImage: `url(${bg})`,
      //   backgroundRepeat: "no-repeat",
      //   backgroundSize: "cover",
      // }}
      >
        <SlippingTolrance
          openSlippage={openSlippage}
          setopenSlippage={setopenSlippage}
        />
        <Container>
          <Box mt="50px" style={{ width: "100%" }}>
            {" "}
            <TopBanner />{" "}
          </Box>
          <Grid container style={{ marginTop: "50px" }}>
            <Grid item xs={12} sm={12} md={6} lg={5}>
              <Container maxWidth="xs">
                <Box
                  mt={0}
                  // className={classes.tradeContainer}

                  align="center"
                >
                  {/* <TopNav /> */}
                  <Box
                    // className={classes.ExchangeBox}

                    sx={{
                      background: "#202A80",
                      borderRadius: "15.6877px",
                      padding: "30px 20px",
                      boxShadow: "2",
                    }}
                  >
                    <ConfirmSwapModal
                      isOpen={showConfirm}
                      trade={trade}
                      originalTrade={tradeToConfirm}
                      onAcceptChanges={handleAcceptChanges}
                      attemptingTxn={attemptingTxn}
                      txHash={txHash}
                      recipient={recipient}
                      allowedSlippage={allowedSlippage}
                      onConfirm={handleSwap}
                      swapErrorMessage={swapErrorMessage}
                      onDismiss={handleConfirmDismiss}
                    />

                    <Typography
                      sx={{
                        fontFamily: "'Russo One'",
                        fontStyle: "normal",
                        fontWeight: 400,
                        fontSize: "25.1003px",
                        textAlign: "center",
                        color: "white",
                      }}
                    >
                      SEEK SWAP
                    </Typography>

                    <Box
                      style={{
                        display: "flex ",
                        align: "center",
                        justifyContent: "flex-end",
                        marginTop: "-40px",
                      }}
                    >
                      <IconButton
                        sx={{
                          color: "#5865F2",
                          "&:hover": {
                            color: "white",
                          },
                          transition: "0.3s ease",
                        }}
                      >
                        <AiOutlineClockCircle />{" "}
                      </IconButton>
                      <IconButton
                        onClick={() => setopenSlippage(true)}
                        sx={{
                          color: "#5865F2",
                          padding: "0px",
                          "&:hover": {
                            color: "white",
                          },
                          transition: "0.3s ease",
                        }}
                      >
                        <AiOutlineSetting />
                      </IconButton>
                    </Box>
                    <Typography
                      sx={{
                        fontFamily: "'Roboto'",
                        fontStyle: "normal",
                        fontWeight: 400,
                        fontSize: "16.7335px",
                        textAlign: "center",
                        color: "white",
                      }}
                    >
                      Trade tokens in an instant
                    </Typography>

                    {/* new Box  */}
                    <Box sx={{ padding: "10px", background: "#060C3B" }}>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                          width: "100%",
                        }}
                      >
                        {/* API and box  */}
                        <Box sx={{ width: "100%" }}>
                          <CurrencyInput
                            label={
                              independentField === Field.OUTPUT &&
                              !showWrap &&
                              trade
                                ? "From (estimated)"
                                : "From"
                            }
                            value={formattedAmounts[Field.INPUT]}
                            showMaxButton={!atMaxAmountInput}
                            currency={currencies[Field.INPUT]}
                            otherCurrency={currencies[Field.OUTPUT]}
                            onUserInput={handleTypeInput}
                            onMax={handleMaxInput}
                            onCurrencySelect={handleInputSelect}
                            id="swap-currency-input"
                            from={true}
                          />
                        </Box>
                      </Box>

                      {/* ---------------center ------------- */}

                      <Box
                        sx={{
                          width: "100%",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                      >
                        <Box
                          sx={{
                            width: "40%",
                            background: "#060C3B",
                            borderTopRightRadius: "40px",
                            borderBottomRightRadius: "40px",
                            height: "50px",
                            // borderRadius: "50px",
                          }}
                        ></Box>

                        <Box
                          sx={{
                            height: "50px",
                            width: "20%",
                            background: "#010524",
                          }}
                        >
                          <IconButton
                            sx={{ color: "white", fontSize: "30px" }}
                            onClick={() => onSwitchTokens()}
                          >
                            {/* <IoMdSwap /> */}
                            <img src={icon} alt="" />
                          </IconButton>
                        </Box>
                        <Box
                          sx={{
                            width: "40%",
                            background: "#060C3B",
                            borderTopLeftRadius: "40px",
                            borderBottomLeftRadius: "40px",
                            height: "50px",
                          }}
                        ></Box>
                      </Box>
                      {/* ----------------to box ------------- */}

                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                          width: "100%",
                        }}
                      >
                        {/* API and box  */}

                        <Box style={{ width: "100%" }}>
                          <CurrencyInput
                            value={formattedAmounts[Field.OUTPUT]}
                            onUserInput={handleTypeOutput}
                            label={
                              independentField === Field.INPUT &&
                              !showWrap &&
                              trade
                                ? "To (estimated)"
                                : "To"
                            }
                            showMaxButton={false}
                            currency={currencies[Field.OUTPUT]}
                            onCurrencySelect={handleOutputSelect}
                            otherCurrency={currencies[Field.INPUT]}
                            id="swap-currency-output"
                          />
                        </Box>
                      </Box>
                    </Box>

                    {/* <Box position="relative">
                    <Box
                      position="absolute"
                      right={{ xs: "-8rem", sm: "-9rem" }}
                      top={{ xs: "-3.5rem", sm: "-4.2rem" }}
                    >
                      <img
                        style={{}}
                        src={logo}
                        alt=""
                        width={matches ? "20%" : "20%"}
                      />
                    </Box>
                  </Box> */}
                    {/* <Box
                    style={{ cursor: "pointer" }}
                    display="flex"
                    alignItems="flex-start"
                    marginBottom={matches ? "0px" : "-20px"}
                  >
                    <i
                      style={{
                        textAlign: "left",
                        fontSize: "30px",
                        color: theme.palette.secondary.main,
                      }}
                      className="fad fa-cogs"
                      onClick={() => setopenSlippage(true)}
                    ></i>
                  </Box> */}

                    {/* <Grid order={2}>
                  
                  </Grid> */}
                    {/* <Grid>
                    <Box mt={4}>
                      <Box className={classes.Middline}>
                        <Box
                          width="50px"
                          height="30px"
                          // bgcolor={theme.palette.primary.main}
                          position="absolute"
                          top="-1rem"
                          display="flex"
                          justifyContent="center"
                        >
                          <Box className={classes.circleBox}>
                            <CachedIcon
                              onClick={() => {
                                setApprovalSubmitted(false); // reset 2 step UI for approvals
                                onSwitchTokens();
                              }}
                              // transform: "rotate(180deg)"
                              style={{
                                margin: ".1rem",
                                transform:
                                  rotaion === true
                                    ? "rotate(360deg)"
                                    : "rotate(0deg)",
                                color: "#fff",
                                cursor: "pointer",
                              }}
                            />
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                  </Grid> */}

                    {/* <Grid order={2}>
                    <CurrencyInput
                      value={formattedAmounts[Field.OUTPUT]}
                      onUserInput={handleTypeOutput}
                      label={
                        independentField === Field.INPUT && !showWrap && trade
                          ? "To (estimated)"
                          : "To"
                      }
                      showMaxButton={false}
                      currency={currencies[Field.OUTPUT]}
                      onCurrencySelect={handleOutputSelect}
                      otherCurrency={currencies[Field.INPUT]}
                      id="swap-currency-output"
                    />
                  </Grid> */}
                    {recipient !== null && !showWrap ? (
                      <>
                        <Box
                          justify="space-between"
                          style={{ padding: "0 1rem" }}
                        >
                          <Box clickable={false}>
                            <Box size="16" color="primary.contrastText" />
                          </Box>
                          <CustomizedButton
                            id="remove-recipient-button"
                            fun={() => onChangeRecipient(null)}
                          >
                            - Remove send
                          </CustomizedButton>
                        </Box>
                        <input
                          id="recipient"
                          value={recipient}
                          onChange={onChangeRecipient}
                        />
                      </>
                    ) : null}
                    {showWrap ? null : (
                      <Box padding=".25rem .75rem 0 .75rem" borderRadius="20px">
                        <Box>
                          {Boolean(trade) && (
                            <Box
                              mt={1}
                              display="flex"
                              alignItems="center"
                              justifyContent="space-between"
                            >
                              <Box
                                color={theme.palette.common.black}
                                fontSize="14px"
                              >
                                Price
                              </Box>
                              <TradePrice
                                price={trade?.executionPrice}
                                showInverted={showInverted}
                                setShowInverted={setShowInverted}
                              />
                            </Box>
                          )}
                          {allowedSlippage !== INITIAL_ALLOWED_SLIPPAGE && (
                            <Box
                              display="flex"
                              alignItems="center"
                              justifyContent="space-between"
                              mt={1}
                            >
                              <Box
                                fontSize="14px"
                                color={theme.palette.common.black}
                              >
                                Slippage Tolerance
                              </Box>
                              <Box
                                color={theme.palette.common.black}
                                fontSize="14px"
                              >
                                {allowedSlippage / 100}%
                              </Box>
                            </Box>
                          )}
                        </Box>
                      </Box>
                    )}
                    <Box mt={2}>
                      {/* <Button
                        sx={{
                          fontFamily: "'Russo One'",
                          fontStyle: "normal",
                          fontWeight: 400,
                          fontSize: "12.7153px",
                          lineHeight: "15px",
                          background: "#F1620A",
                          borderRadius: "47.063px",
                          width: "70%",
                          color: "white",
                          height: "42px",
                        }}
                      >
                        Buy SEEK COIN
                      </Button> */}

                      {!account ? (
                        <CustomizedButton fun={() => connect()}>
                          Connect Wallet
                        </CustomizedButton>
                      ) : showWrap ? (
                        <CustomizedButton
                          disabled={Boolean(wrapInputError)}
                          fun={onWrap}
                          width="100%"
                          variant="contained"
                        >
                          {wrapInputError ??
                            (wrapType === WrapType.WRAP
                              ? "Wrap"
                              : wrapType === WrapType.UNWRAP
                              ? "Unwrap"
                              : null)}
                        </CustomizedButton>
                      ) : noRoute && userHasSpecifiedInputOutput ? (
                        <Box style={{ textAlign: "center" }}>
                          <Box mb="4px" color={theme.palette.common.black}>
                            Insufficient liquidity for this trade.
                          </Box>
                        </Box>
                      ) : showApproveFlow ? (
                        <Box>
                          <CustomizedButton
                            fun={approveCallback}
                            disabled={
                              approval !== ApprovalState.NOT_APPROVED ||
                              approvalSubmitted
                            }
                          >
                            {approval === ApprovalState.PENDING ? (
                              <Box
                                width="100%"
                                display="flex"
                                gap="6px"
                                justify="center"
                              >
                                Approving <CircularProgress size={20} />
                              </Box>
                            ) : approvalSubmitted &&
                              approval === ApprovalState.APPROVED ? (
                              "Approved"
                            ) : (
                              `Approve ${currencies[Field.INPUT]?.symbol}`
                            )}
                          </CustomizedButton>
                          <CustomizedButton
                            fun={() => {
                              if (isExpertMode) {
                                handleSwap();
                              } else {
                                setSwapState({
                                  tradeToConfirm: trade,
                                  attemptingTxn: false,
                                  swapErrorMessage: undefined,
                                  showConfirm: true,
                                  txHash: undefined,
                                });
                              }
                            }}
                            id="swap-button"
                            disabled={
                              !isValid ||
                              approval !== ApprovalState.APPROVED ||
                              (priceImpactSeverity > 3 && !isExpertMode)
                            }
                          >
                            {priceImpactSeverity > 3 && !isExpertMode
                              ? `Price Impact High`
                              : `Swap${
                                  priceImpactSeverity > 2 ? " Anyway" : ""
                                }`}
                          </CustomizedButton>
                        </Box>
                      ) : (
                        <CustomizedButton
                          fun={() => {
                            if (isExpertMode) {
                              handleSwap();
                            } else {
                              setSwapState({
                                tradeToConfirm: trade,
                                attemptingTxn: false,
                                swapErrorMessage: undefined,
                                showConfirm: true,
                                txHash: undefined,
                              });
                            }
                          }}
                          id="swap-button"
                          disabled={
                            !isValid ||
                            (priceImpactSeverity > 3 && !isExpertMode) ||
                            !!swapCallbackError
                          }
                        >
                          {swapInputError ||
                            (priceImpactSeverity > 3 && !isExpertMode
                              ? `Price Impact Too High`
                              : `Swap${
                                  priceImpactSeverity > 2 ? " Anyway" : ""
                                }`)}
                        </CustomizedButton>
                      )}

                      {showApproveFlow && (
                        <SwapStepper
                          steps={[approval === ApprovalState.APPROVED]}
                        />
                      )}
                    </Box>
                  </Box>

                  <AdvancedSwapDetailsDropdown trade={trade} />
                </Box>
              </Container>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={7}>
              <CustomChart
                data={{
                  currency1: currencies[Field.INPUT],
                  currency2: currencies[Field.OUTPUT],
                }}
              />
            </Grid>
          </Grid>
          <Box mt={5}>
            <HistoryTable />
          </Box>
        </Container>
      </Box>
    </>
  );
});
