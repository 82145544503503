import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import "./css/index.css";
import reportWebVitals from "./reportWebVitals";

import "./fonts/Inter-Bold.ttf";
import "./fonts/segoeui.ttf";
import "./fonts/segoeuib.ttf";
import "./fonts/segoeuii.ttf";
import "./fonts/segoeuil.ttf";
import "./fonts/segoeuisl.ttf";
import "./fonts/segoeuiz.ttf";
import "./fonts/seguibl.ttf";
import "./fonts/seguibli.ttf";
import "./fonts/seguili.ttf";
import "./fonts/seguisb.ttf";
import "./fonts/seguisb.ttf";
import "./fonts/seguisbi.ttf";
import "./fonts/seguisli.ttf";
import theme from "./theme";
import { ThemeProvider } from "@mui/material/styles";
import { CssBaseline } from "@mui/material";
import Main from "./main";
import { AppContextProvider } from "./uniswap/utils/Utils";
ReactDOM.render(
  <ThemeProvider theme={theme}>
    <AppContextProvider>
      <CssBaseline />
      <Main />
    </AppContextProvider>
  </ThemeProvider>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function

// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
