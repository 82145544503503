import { Box } from "@mui/material";
import React from "react";
import CurrencyLogo from "../CurrencyLogo/CurrencyLogo";

export default function DoubleCurrencyLogo({
  currency0,
  currency1,
  size = 20,
  margin,
}) {
  return (
    <Box
      display="flex"
      alignItems="center"
      mr={margin ? "0px" : `${(size / 3 + 8).toString()}px`}
      sx={{
        fontFamily: "'Roboto'",
        fontStyle: "normal",
        fontWeight: 400,
        fontSize: "16.7335px",
        // textAlign: "center",
        color: "white",
      }}
    >
      {currency0 && (
        <CurrencyLogo currency={currency0} size={`${size.toString()}px`} />
      )}
      {currency1 && (
        <CurrencyLogo currency={currency1} size={`${size.toString()}px`} />
      )}
    </Box>
  );
}
