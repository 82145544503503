import {
  Box,
  Button,
  Grid,
  IconButton,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React, { useCallback, useContext, useState } from "react";

import { useCurrencyBalance } from "../../../state/wallet/hooks";
import { escapeRegExp } from "../../../utils";
import { ExpandMoreIcon } from "../../../utils/images";
import { AppContext } from "../../../utils/Utils";
import CurrencyLogo from "../CurrencyLogo/CurrencyLogo";
import DoubleCurrencyLogo from "../DoubleCurrencyLogo/DoubleCurrencyLogo";
import CurrencySearchModal from "../SearchModal/CurrencySearchModal";
import i1 from "./assets/i1.png";
import i2 from "./assets/i2.png";

export default function CurrencyInput({
  value,
  onUserInput,
  onMax,
  showMaxButton,
  label,
  onCurrencySelect,
  currency,
  disableCurrencySelect = false,
  hideBalance = false,
  pair = null, // used for double token logo
  hideInput = false,
  otherCurrency,
  id,
  showCommonBases,
  from,
}) {
  const matches = useMediaQuery("(max-width:600px)");
  const theme = useTheme();
  const [modalOpen, setModalOpen] = useState(false);
  const { account, chainId } = useContext(AppContext);

  const selectedCurrencyBalance = useCurrencyBalance(
    account ?? undefined,
    currency ?? undefined
  );
  const inputRegex = RegExp(`^\\d*(?:\\\\[.])?\\d*$`);
  const enforcer = (nextUserInput) => {
    if (nextUserInput === "" || inputRegex.test(escapeRegExp(nextUserInput))) {
      onUserInput(nextUserInput);
    }
  };
  const translatedLabel = label || "Input";
  const handleDismissSearch = useCallback(() => {
    setModalOpen(false);
  }, [setModalOpen]);
  return (
    <Box
      width="100%"
      padding="5px"
      style={{
        backgroundColor: "#010524",
        width: "100%",
      }}
      py="10px"
      px="20px"
    >
      <Box
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          width: "100%",
        }}
      >
        <Box>
          <Typography
            style={{
              fontFamily: "'Roboto'",
              fontStyle: "normal",
              fontWeight: 400,
              fontSize: "16.5501px",
              textAlign: "left",
              color: "white",
            }}
          >
            {" "}
            {from ? "From " : "To"}
          </Typography>
          <Box mt="20px">
            <input
              value={value}
              onChange={(event) => {
                // replace commas with periods, because uniswap exclusively uses period as the decimal separator
                enforcer(event.target.value.replace(/,/g, "."));
              }}
              // universal input options
              inputMode="decimal"
              title="Token Amount"
              autoComplete="off"
              autoCorrect="off"
              // text-specific options
              type="text"
              pattern="^[0-9]*[.,]?[0-9]*$"
              placeholder={"0.0"}
              minLength={1}
              maxLength={79}
              spellCheck="false"
              style={{
                backgroundColor: "transparent",
                outline: "none",
                border: "none",
                fontSize: "20px",
                width: "100%",
                color: theme.palette.common.black,
                flexBasis: 2,
              }}
            />
          </Box>
        </Box>

        <Box>
          {/* {from ? <img src={i1} alt="" /> : <img src={i2} alt="" />} */}
          {/* --------------------API BOX MODAL---------------------------------- */}
          {account && (
            <Box
              onClick={onMax}
              fontSize="12px"
              style={{ display: "inline", cursor: "pointer" }}
              textAlign="right"
              color="common.black"
            >
              {!hideBalance && !!currency && selectedCurrencyBalance
                ? `Balance: ${selectedCurrencyBalance?.toSignificant(6)}`
                : " -"}
            </Box>
          )}
          <Box width="100%" align="right">
            <IconButton
              style={{
                borderRadius: "5px",
                padding: "5px",
                color: theme.palette.common.black,
                width: "100%",
              }}
              onClick={() => {
                if (!disableCurrencySelect) {
                  setModalOpen(true);
                }
              }}
            >
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                width="100%"
              >
                <Box>
                  {pair ? (
                    <DoubleCurrencyLogo
                      currency0={pair.token0}
                      currency1={pair.token1}
                      size={16}
                      margin={true}
                    />
                  ) : currency ? (
                    <CurrencyLogo currency={currency} size="20px" />
                  ) : null}
                </Box>{" "}
                &nbsp;
                <Box fontSize={{ xs: 13, sm: 17 }} color="common.black">
                  {pair ? (
                    <>
                      {pair?.token0.symbol}:{pair?.token1.symbol}
                    </>
                  ) : (
                    <>
                      {currency &&
                      currency.symbol &&
                      currency.symbol.length > 20
                        ? `${currency.symbol.slice(
                            0,
                            4
                          )}...${currency.symbol.slice(
                            currency.symbol.length - 5,
                            currency.symbol.length
                          )}`
                        : currency?.symbol || "Select a currency"}
                    </>
                  )}
                </Box>
                {!disableCurrencySelect && (
                  <ExpandMoreIcon
                    fontSize={"medium"}
                    style={{ color: theme.palette.common.black }}
                  />
                )}
              </Box>
            </IconButton>
            {!disableCurrencySelect && onCurrencySelect && (
              <CurrencySearchModal
                isOpen={modalOpen}
                onDismiss={handleDismissSearch}
                onCurrencySelect={onCurrencySelect}
                selectedCurrency={currency}
                otherSelectedCurrency={otherCurrency}
                showCommonBases={showCommonBases}
              />
            )}
          </Box>
        </Box>
      </Box>
      {/* <Grid container spacing={2} alignItems="center">
        <Grid item xs={2}>
          <Box
            my={0}
            color="common.black"
            style={{
              fontFamily: "'Roboto'",
              fontStyle: "normal",
              fontWeight: 400,
              fontSize: "12.5501px",
            }}
            textAlign="left"
          >
            {translatedLabel}
          </Box>
        </Grid>
        <Grid item xs={10}>
          <Box textAlign="right">
            {account && (
              <Box
                onClick={onMax}
                fontSize="14px"
                style={{ display: "inline", cursor: "pointer" }}
                textAlign="right"
                color="common.black"
              >
                {!hideBalance && !!currency && selectedCurrencyBalance
                  ? `Balance: ${selectedCurrencyBalance?.toSignificant(6)}`
                  : " -"}
              </Box>
            )}
          </Box>
        </Grid>
        <Grid item xs={8} sm={7}>
          <Box display="flex" alignItems="center" width="100%">
            <Box>
              <input
                value={value}
                onChange={(event) => {
                  // replace commas with periods, because uniswap exclusively uses period as the decimal separator
                  enforcer(event.target.value.replace(/,/g, "."));
                }}
                // universal input options
                inputMode="decimal"
                title="Token Amount"
                autoComplete="off"
                autoCorrect="off"
                // text-specific options
                type="text"
                pattern="^[0-9]*[.,]?[0-9]*$"
                placeholder={"0.0"}
                minLength={1}
                maxLength={79}
                spellCheck="false"
                style={{
                  backgroundColor: "transparent",
                  outline: "none",
                  border: "none",
                  fontSize: "20px",
                  width: "100%",
                  color: theme.palette.common.black,
                  flexBasis: 2,
                }}
              />
            </Box>
            {account && currency && showMaxButton && label !== "To" && (
              <Button
                onClick={onMax}
                scale="sm"
                flexBasis={1}
                variant="text"
                style={{ color: theme.palette.secondary.main }}
              >
                MAX
              </Button>
            )}
          </Box>
        </Grid>
        <Grid item xs={3} sm={5}>
          <Box width="100%" align="right">
            <IconButton
              style={{
                borderRadius: "5px",
                padding: "5px",
                color: theme.palette.common.black,
                width: "100%",
              }}
              onClick={() => {
                if (!disableCurrencySelect) {
                  setModalOpen(true);
                }
              }}
            >
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                width="100%"
              >
                <Box>
                  {pair ? (
                    <DoubleCurrencyLogo
                      currency0={pair.token0}
                      currency1={pair.token1}
                      size={16}
                      margin={true}
                    />
                  ) : currency ? (
                    <CurrencyLogo currency={currency} size="20px" />
                  ) : null}
                </Box>{" "}
                &nbsp;
                <Box fontSize={{ xs: 13, sm: 17 }} color="common.black">
                  {pair ? (
                    <>
                      {pair?.token0.symbol}:{pair?.token1.symbol}
                    </>
                  ) : (
                    <>
                      {currency &&
                      currency.symbol &&
                      currency.symbol.length > 20
                        ? `${currency.symbol.slice(
                            0,
                            4
                          )}...${currency.symbol.slice(
                            currency.symbol.length - 5,
                            currency.symbol.length
                          )}`
                        : currency?.symbol || "Select a currency"}
                    </>
                  )}
                </Box>
                {!disableCurrencySelect && (
                  <ExpandMoreIcon
                    fontSize={"medium"}
                    style={{ color: theme.palette.common.black }}
                  />
                )}
              </Box>
            </IconButton>
            {!disableCurrencySelect && onCurrencySelect && (
              <CurrencySearchModal
                isOpen={modalOpen}
                onDismiss={handleDismissSearch}
                onCurrencySelect={onCurrencySelect}
                selectedCurrency={currency}
                otherSelectedCurrency={otherCurrency}
                showCommonBases={showCommonBases}
              />
            )}
          </Box>
        </Grid>
      </Grid> */}
    </Box>
  );
}
