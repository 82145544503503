export const styles = (theme) => ({
  tradeContainer: {
    textAlign: "center",
  },
  heading: {
    color: "#e20553",
    marginBottom: "1rem",
    fontSize: "40px",
    fontWeight: 600,
  },
  box: {
    backgroundColor: theme.palette.primary.light,
    borderRadius: 30,
    display: "inline",
    padding: "13px 0px",
  },
  activeBtn: {
    border: "none",
    borderRadius: "30px",
    width: "23%",
    padding: "9px",
    marginBottom: "2px",
    color: theme.trade.box.textColor,
    fontFamily: "Segoe UI",
    fontWeight: "bold",
    background: "#010524",
    "&:hover": {
      background: "#010524",
    },
    [theme.breakpoints.down("md")]: {
      fontSize: ".7rem",
      padding: "13px",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: ".6rem",
      padding: "13px",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: ".5rem",
    },
  },

  btn: {
    border: "none",
    color: "#010524",
    fontWeight: "bold",
    borderRadius: "30px",
    width: "24%",
    fontFamily: "Segoe UI",
    "&:hover": {
      background: theme.palette.primary.light,
    },
    [theme.breakpoints.down("md")]: {
      fontSize: ".7rem",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: ".6rem",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: ".5rem",
    },
  },

  ExchangeBox: {
    height: "auto",
    background: "linear-gradient(180deg, #b31b4e 0%, #dd4274 77.29%)",
    // backgroundColor: "#ae0101",
    margin: "2rem auto",
    borderRadius: 20,
    padding: "2rem 2rem",
    overflow: "hidden",
    width: "80%",
    border: "1px solid",
    borderColor: "#dd4274",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      padding: "1.5rem 0.5rem",
    },
  },
  Exinput: {
    width: "100%",
    height: "100%",
    border: "none",
    fontSize: "2rem",
    paddingLeft: "1rem",
    color: theme.trade.box.textColor,
    [theme.breakpoints.down("sm")]: {
      fontSize: "1rem",
    },
    backgroundColor: "transparent",
    "&:focus": {
      outline: "none",
    },
    "&[type=number]": {
      "-moz-appearance": "textfield",
    },
    "&::-webkit-outer-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
    "&::-webkit-inner-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
  },
  Middline: {
    width: "100%",
    textAlign: "center",
    borderBottom: `1px solid `,
    display: "flex",
    justifyContent: "center",
    position: "relative",
    margin: "auto",
    lineHeight: "2rem",
  },
  circleBox: {
    width: "30px",
    height: "30px",
    borderRadius: "100%",
    background: "#010524",
    position: "absolute",
    margin: "auto",
  },
});
