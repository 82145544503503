import {
  Box,
  Button,
  CircularProgress,
  Container,
  Divider,
  Grid,
  IconButton,
  Tooltip,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React, { useContext, useEffect, useMemo, useState } from "react";

import { withStyles } from "@mui/styles";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import { styles } from "./styles/style";
import { boxheaderimg, stylelines, Line } from "../../utils/images";
import theme from "../../../theme";
import { Link, useLocation, useHistory } from "react-router-dom";
import { TopNav } from "../small components/top nav/TopNav";
import AddRemoveLiquidityAccordian from "../AddRemoveLiquidity/AddRemoveLiquidityAccordian";
import { usePairs } from "../../data/Reserves";
import { useTokenBalancesWithLoadingIndicator } from "../../state/wallet/hooks";
import {
  toV2LiquidityToken,
  useTrackedTokenPairs,
} from "../../state/user/hooks";
import { AiOutlineClockCircle, AiOutlineSetting } from "react-icons/ai";

import SlippingTolrance from "../models/Slippage";
import { AppContext } from "../../utils/Utils";
import AddIcon from "@mui/icons-material/Add";

const LightTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: "white",
    color: "#ffffff",
    boxShadow: theme.shadows[1],
    fontSize: 14,
    fontWeight: 400,
    padding: 20,
  },
}))(Tooltip);
export const UniswapPool = withStyles(styles)(
  ({ classes, setopenConnect, adding, setbg }) => {
    const [active, setActive] = useState(true);
    const matches = useMediaQuery("(max-width:600px)");
    const loc = useLocation();
    const [openSlippage, setopenSlippage] = useState(false);
    const history = useHistory();
    const { account, connect } = useContext(AppContext);
    // const TranslateString = useI18n();
    useEffect(() => {
      setbg(true);
    }, []);

    // fetch the user's balances of all tracked V2 LP tokens
    const trackedTokenPairs = useTrackedTokenPairs();
    const tokenPairsWithLiquidityTokens = useMemo(
      () =>
        trackedTokenPairs.map((tokens) => ({
          liquidityToken: toV2LiquidityToken(tokens),
          tokens,
        })),
      [trackedTokenPairs]
    );
    const liquidityTokens = useMemo(
      () => tokenPairsWithLiquidityTokens.map((tpwlt) => tpwlt.liquidityToken),
      [tokenPairsWithLiquidityTokens]
    );
    const [v2PairsBalances, fetchingV2PairBalances] =
      useTokenBalancesWithLoadingIndicator(
        account ?? undefined,
        liquidityTokens
      );

    // fetch the reserves for all V2 pools in which the user has a balance
    const liquidityTokensWithBalances = useMemo(
      () =>
        tokenPairsWithLiquidityTokens.filter(({ liquidityToken }) =>
          v2PairsBalances[liquidityToken.address]?.greaterThan("0")
        ),
      [tokenPairsWithLiquidityTokens, v2PairsBalances]
    );

    const v2Pairs = usePairs(
      liquidityTokensWithBalances.map(({ tokens }) => tokens)
    );
    const v2IsLoading =
      fetchingV2PairBalances ||
      v2Pairs?.length < liquidityTokensWithBalances.length ||
      v2Pairs?.some((V2Pair) => !V2Pair);

    const allV2PairsWithLiquidity = v2Pairs
      .map(([, pair]) => pair)
      .filter((v2Pair) => Boolean(v2Pair));

    return (
      <Container maxWidth="sm">
        <SlippingTolrance
          openSlippage={openSlippage}
          setopenSlippage={setopenSlippage}
        />
        {/* ---------------------old Box -------------------- */}

        <Box
          mt={0}
          //  className={classes.tradeContainer}

          align="center"
          sx={{ background: "#202A80", borderRadius: "20px" }}
        >
          <Box>
            <Box mt={5}>
              {account ? (
                <Box mt={3}>
                  <Box py="20px" px="30px">
                    <Typography
                      sx={{
                        fontFamily: "'Russo One'",
                        fontStyle: "normal",
                        fontWeight: 400,
                        fontSize: "24px",
                        color: "white",
                        textAlign: "center",
                      }}
                    >
                      {" "}
                      Your Liquidity{" "}
                    </Typography>

                    <Box
                      style={{
                        display: "flex ",
                        align: "center",
                        justifyContent: "flex-end",
                        marginTop: "-35px",
                      }}
                    >
                      <IconButton
                        sx={{
                          color: "#5865F2",
                          "&:hover": {
                            color: "white",
                          },
                          transition: "0.3s ease",
                        }}
                      >
                        <AiOutlineClockCircle />{" "}
                      </IconButton>
                      <IconButton
                        onClick={() => setopenSlippage(true)}
                        sx={{
                          color: "#5865F2",
                          padding: "0px",
                          "&:hover": {
                            color: "white",
                          },
                          transition: "0.3s ease",
                        }}
                      >
                        <AiOutlineSetting />
                      </IconButton>
                    </Box>
                    <Typography
                      sx={{
                        fontFamily: "'Roboto'",
                        fontStyle: "normal",
                        fontWeight: 400,
                        fontSize: "16.7335px",
                        textAlign: "center",
                        color: "white",
                      }}
                    >
                      Remove liquidity to receive tokens back
                    </Typography>
                  </Box>
                  <Box
                    sx={{ backgroundColor: "#060C3B", px: "30px", py: "20px" }}
                  >
                    <Grid>
                      <Box
                        mt={2}
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                      >
                        <Box
                          sx={{
                            fontFamily: "'Roboto'",
                            fontStyle: "normal",
                            fontWeight: 400,
                            fontSize: "16px",
                            color: "white",
                          }}
                        >
                          Your liquidity
                        </Box>
                        <Box>
                          <LightTooltip
                            title={
                              adding
                                ? "When you add liquidity, you are given pool tokens representing your position. These tokens automatically earn fees proportional to your share of the pool, and can be redeemed at any time."
                                : "Removing pool tokens converts your position back into underlying tokens at the current rate, proportional to your share of the pool. Accrued fees are included in the amounts you receive."
                            }
                          >
                            <IconButton aria-label="delete">
                              <HelpOutlineIcon
                                style={{
                                  color: "white",
                                }}
                              />
                            </IconButton>
                          </LightTooltip>
                        </Box>
                      </Box>
                    </Grid>

                    {!account ? (
                      <Box padding="40px">
                        <Box
                          fontWeight="500"
                          fontSize="17px"
                          color="common.black"
                          textAlign="center"
                        >
                          Connect to a wallet to view your liquidity.
                        </Box>
                      </Box>
                    ) : v2IsLoading ? (
                      <Box
                        display="flex"
                        justifyContent="center"
                        padding="40px"
                      >
                        <Box
                          display="flex"
                          alignItems="center"
                          color="white"
                          textAlign="center"
                        >
                          Loading..
                          <CircularProgress
                            style={{ marginLeft: "5px" }}
                            size={20}
                          />
                        </Box>
                      </Box>
                    ) : allV2PairsWithLiquidity?.length > 0 ? (
                      <>
                        {allV2PairsWithLiquidity.map((v2Pair) => (
                          <AddRemoveLiquidityAccordian
                            key={v2Pair.liquidityToken.address}
                            pair={v2Pair}
                          />
                        ))}
                      </>
                    ) : (
                      <Box padding="40px">
                        <Box color="white" textAlign="center">
                          No liquidity found.
                        </Box>
                      </Box>
                    )}

                    <Box pt={5} color={"white"} fontSize={{ xs: 11, sm: 14 }}>
                      Don't see a pool you joined?{" "}
                      <Link
                        to="/findpools"
                        style={{
                          color: theme.palette.white,
                          fontWeight: "500",
                          cursor: "pointer",
                          fontSize: "16px",
                        }}
                      >
                        Import it
                      </Link>
                    </Box>
                  </Box>
                  <Box sx={{ py: "30px" }}>
                    <Button
                      onClick={() => history.push("/add/BNB")}
                      sx={{
                        fontFamily: "'Russo One'",
                        fontStyle: "normal",
                        fontWeight: 400,
                        fontSize: "12.7153px",
                        lineHeight: "15px",
                        background: "#F1620A",
                        borderRadius: "47.063px",
                        width: "70%",
                        color: "white",
                        height: "42px",
                      }}
                    >
                      <IconButton style={{ color: "#ffffff" }}>
                        <AddIcon style={{ color: "#ffffff" }} />
                      </IconButton>
                      Add Liquidity
                    </Button>{" "}
                  </Box>
                </Box>
              ) : (
                <Box mt={2}>
                  <Box sx={{ py: "30px" }}>
                    <Button
                      onClick={connect}
                      sx={{
                        fontFamily: "'Russo One'",
                        fontStyle: "normal",
                        fontWeight: 400,
                        fontSize: "12.7153px",
                        lineHeight: "15px",
                        background: "#F1620A",
                        borderRadius: "47.063px",
                        width: "70%",
                        color: "white",
                        height: "42px",
                      }}
                    >
                      Connect Wallet
                    </Button>
                  </Box>
                </Box>
              )}
            </Box>
          </Box>
        </Box>
      </Container>
    );
  }
);
