import {
  Button,
  Divider,
  FormControl,
  MenuItem,
  Pagination,
  Paper,
  Select,
  Stack,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { Box, Container } from "@mui/system";
import React, { useState } from "react";
import SettingsIcon from "@mui/icons-material/Settings";
import styled from "@emotion/styled";
import { useEffect } from "react";
import axios from "axios";

const StyledMenuItems = ({ children, ...props }) => {
  return (
    <MenuItem {...props} sx={{ color: "#7C5EB9", fontSize: "14px !important" }}>
      {children}
    </MenuItem>
  );
};

const StyledTableCell = styled(TableCell)(() => ({
  [`&.${tableCellClasses.body}`]: {
    // padding: "0px",
    borderBottom: "none",
    color: "white",
    textAlign: "left",
    height: "30px",
    marginBottom: "20px",
  },
}));

const borderstyle = {
  minWidth: "max-content",
  // borderRadius: "20px",
};

const HistoryTable = () => {
  const [age, setAge] = React.useState("");
  const [rawdata, setrawdata] = useState([]);
  const [postsPerPage, setPostsPerPage] = useState(4);
  const [api, setApi] = useState([]);

  const handleChange = (event) => {
    setAge(event.target.value);
  };

  useEffect(() => {
    const apidata = async () => {
      try {
        let { data } = await axios.get(
          `https://api.coingecko.com/api/v3/coins/markets?per_page=10&page=1&order=market_cap_desc&vs_currency=usd&price_change_percentage=24h,7d,30d&sparkline=true`
        );
        setApi(data);
      } catch (error) {
        console.log(error);
      }
    };
    apidata();
  }, []);
  return (
    <Box>
      <Box>
        <TableContainer
          sx={{
            overflowX: "scroll",
            // maxHeight: 500,
            "::-webkit-scrollbar": {
              width: "0px",
              height: "0px",
            },
          }}
          component={Paper}
        >
          <Table
            sx={{
              minWidth: 650,
              borderCollapse: "separate",
              borderSpacing: "0px 5px",
            }}
            aria-label="simple table"
          >
            {/* <TableHead>
                  <TableRow
                    sx={{
                      background: "#E6E3EF",
                    }}
                  >
                    <TableCell align="center">
                      <Typography variant="subtitle2">DATE</Typography>
                    </TableCell>
                    <TableCell align="center">
                      <Typography variant="subtitle2">DIRECT SALES</Typography>
                    </TableCell>
                    <TableCell align="center">
                      <Typography variant="subtitle2">INCOME</Typography>
                    </TableCell>
                    <TableCell align="center">
                      <Typography variant="subtitle2">PASSUP</Typography>
                    </TableCell>
                    <TableCell align="center">
                      <Typography variant="subtitle2">PASSUP</Typography>
                    </TableCell>
                    <TableCell align="center">
                      <Typography variant="subtitle2">INCOME</Typography>
                    </TableCell>
                    <TableCell align="center">
                      <Typography variant="subtitle2">PASSUP</Typography>
                    </TableCell>
                    <TableCell align="center">
                      <Typography variant="subtitle2">PASSUP</Typography>
                    </TableCell>
                  </TableRow>
                </TableHead> */}
            <TableBody>
              {api?.map(
                (
                  {
                    id,
                    name,
                    symbol,
                    current_price,
                    currencySymbol,
                    price_change_percentage_24h_in_currency,
                    price_change_percentage_7d_in_currency,
                    price_change_percentage_30d_in_currency,
                    market_cap,
                    total_volume,
                    circulating_supply,
                  },
                  index
                ) => (
                  <TableRow
                    key={id}
                    sx={{
                      "&:last-child td, &:last-child th": {
                        border: 0,
                      },
                      backgroundColor: "#202A80",
                      marginBottom: "10px",
                      borderRadius: "20px",
                    }}
                  >
                    <StyledTableCell
                      style={{
                        borderStartStartRadius: "20px",
                        borderEndStartRadius: "20px",
                      }}
                    >
                      <Typography variant="subtitle2" style={borderstyle}>
                        {index + 1}
                      </Typography>
                    </StyledTableCell>{" "}
                    <StyledTableCell>
                      <Typography variant="subtitle2" style={borderstyle}>
                        {name} {symbol.toUpperCase()}
                      </Typography>
                    </StyledTableCell>
                    <StyledTableCell component="th" scope="row" align="center">
                      <Typography variant="subtitle2" style={borderstyle}>
                        ${currencySymbol ?? ""}{" "}
                        {current_price?.toLocaleString()}
                      </Typography>
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <Typography
                        color={
                          price_change_percentage_24h_in_currency.toString()[0] ==
                          "-"
                            ? "green"
                            : "red"
                        }
                        variant="subtitle2"
                        style={borderstyle}
                      >
                        {+price_change_percentage_24h_in_currency.toFixed(2)}%
                      </Typography>
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <Typography
                        color={
                          price_change_percentage_7d_in_currency.toString()[0] ==
                          "-"
                            ? "green"
                            : "red"
                        }
                        variant="subtitle2"
                        style={borderstyle}
                      >
                        {+price_change_percentage_7d_in_currency.toFixed(2)}%
                      </Typography>
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <Typography
                        color={
                          price_change_percentage_30d_in_currency.toString()[0] ==
                          "-"
                            ? "green"
                            : "red"
                        }
                        variant="subtitle2"
                        style={borderstyle}
                      >
                        {+price_change_percentage_30d_in_currency.toFixed(2)}
                      </Typography>
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <Typography
                        color="primary.light"
                        variant="subtitle2"
                        style={borderstyle}
                      >
                        ${currencySymbol ?? ""}{" "}
                        {Math.round(market_cap)?.toLocaleString()}
                      </Typography>
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <Typography
                        color="primary.light"
                        variant="subtitle2"
                        style={borderstyle}
                      >
                        ${currencySymbol ?? ""}{" "}
                        {Math.round(total_volume)?.toLocaleString()}
                      </Typography>
                    </StyledTableCell>
                    <StyledTableCell
                      align="center"
                      style={{
                        borderEndEndRadius: "20px",
                        borderStartEndRadius: "20px",
                      }}
                    >
                      <Typography
                        color="primary.light"
                        variant="subtitle2"
                        style={borderstyle}
                      >
                        {Math.round(circulating_supply)} {symbol.toUpperCase()}
                      </Typography>
                    </StyledTableCell>
                    {/* <TableCell align="center">
                        <Typography color="primary.light" variant="subtitle2">
                          {row.PASSUPINCOMERECEIVED}
                        </Typography>
                      </TableCell> */}
                  </TableRow>
                )
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Box>
  );
};

export default HistoryTable;
