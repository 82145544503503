import { ETHER } from "seek-swap-sdk";
import { ArrowDownward } from "@mui/icons-material";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useSelector } from "react-redux";
import { Search } from "@mui/icons-material";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";

import { useAllTokens, useToken } from "../../../hooks/Tokens";
import { useSelectedListInfo } from "../../../state/lists/hooks";
import { isAddress } from "../../../utils";
import CurrencyList from "./CurrencyList";
import { filterTokens } from "./filtering";
import { useTokenComparator } from "./sorting";
import {
  Box,
  DialogContent,
  Divider,
  IconButton,
  InputBase,
  useMediaQuery,
} from "@mui/material";
import { useTheme } from "@mui/material";
import { LightTooltip } from "../swap/AdvancedSwapDetails";
import { AppContext } from "../../../utils/Utils";

export function CurrencySearch({
  selectedCurrency,
  onCurrencySelect,
  otherSelectedCurrency,
  onDismiss,
  isOpen,
}) {
  const matches = useMediaQuery("(max-width:760px)");
  const { account, chainId } = useContext(AppContext);

  const theme = useTheme();

  const fixedList = useRef();
  const [searchQuery, setSearchQuery] = useState("");
  const [invertSearchOrder, setInvertSearchOrder] = useState(false);
  const allTokens = useAllTokens();

  // if they input an address, use it
  const isAddressSearch = isAddress(searchQuery);
  const searchToken = useToken(searchQuery);

  const showETH = useMemo(() => {
    const s = searchQuery.toLowerCase().trim();
    return s === "" || s === "b" || s === "bn" || s === "bnb";
  }, [searchQuery]);

  const tokenComparator = useTokenComparator(invertSearchOrder);

  const audioPlay = useSelector((state) => state.user.audioPlay);

  const filteredTokens = useMemo(() => {
    if (isAddressSearch) return searchToken ? [searchToken] : [];
    return filterTokens(Object.values(allTokens), searchQuery);
  }, [isAddressSearch, searchToken, allTokens, searchQuery]);

  const filteredSortedTokens = useMemo(() => {
    if (searchToken) return [searchToken];
    const sorted = filteredTokens.sort(tokenComparator);
    const symbolMatch = searchQuery
      .toLowerCase()
      .split(/\s+/)
      .filter((s) => s.length > 0);
    if (symbolMatch.length > 1) return sorted;

    return [
      ...(searchToken ? [searchToken] : []),
      // sort any exact symbol matches first
      ...sorted.filter(
        (token) => token.symbol?.toLowerCase() === symbolMatch[0]
      ),
      ...sorted.filter(
        (token) => token.symbol?.toLowerCase() !== symbolMatch[0]
      ),
    ];
  }, [filteredTokens, searchQuery, searchToken, tokenComparator]);

  const handleCurrencySelect = useCallback(
    (currency) => {
      onCurrencySelect(currency);
      onDismiss();
      if (audioPlay) {
        const audio = document.getElementById("bgMusic");
        if (audio) {
          audio.play();
        }
      }
    },
    [onDismiss, onCurrencySelect, audioPlay]
  );

  // clear the input on open
  useEffect(() => {
    if (isOpen) setSearchQuery("");
  }, [isOpen]);

  // manage focus on modal show
  const inputRef = useRef();
  const handleInput = useCallback((event) => {
    const input = event.target.value;
    const checksummedInput = isAddress(input);
    setSearchQuery(checksummedInput || input);
    fixedList.current?.scrollTo(0);
  }, []);

  const handleEnter = useCallback(
    (e) => {
      if (e.key === "Enter") {
        const s = searchQuery.toLowerCase().trim();
        if (s === "bnb") {
          handleCurrencySelect(ETHER);
        } else if (filteredSortedTokens.length > 0) {
          if (
            filteredSortedTokens[0].symbol?.toLowerCase() ===
              searchQuery.trim().toLowerCase() ||
            filteredSortedTokens.length === 1
          ) {
            handleCurrencySelect(filteredSortedTokens[0]);
          }
        }
      }
    },
    [filteredSortedTokens, handleCurrencySelect, searchQuery]
  );

  const selectedListInfo = useSelectedListInfo();
  return (
    <DialogContent className="dialoge__content__section">
      <div
        style={{
          textAlign: "center",
          paddingBottom: "10px",
          width: matches ? "295px" : "412px",
        }}
      >
        <Box
          paddingLeft="20px"
          paddingRight="20px"
          paddingTop="25px"
          display="flex"
          justifyContent="space-between"
        >
          <Box
            color={theme.palette.common.black}
            fontSize="17px"
            textAlign="left"
            fontFamily="Segoe UI"
          >
            Select a token
            <LightTooltip title="Use this tool to find pairs that do not automatically appear in the interface.">
              <IconButton aria-label="delete">
                <HelpOutlineIcon
                  style={{ color: theme.palette.common.black }}
                />
              </IconButton>
            </LightTooltip>
          </Box>
          <Box>
            <IconButton onClick={onDismiss} aria-label="delete">
              <i
                style={{
                  color: theme.palette.common.black,
                  textAlign: "right",
                  fontSize: "25px",
                }}
                className="fal fa-times"
              ></i>
            </IconButton>
          </Box>
        </Box>
        <Box paddingLeft="20px" paddingRight="20px">
          <Box
            display="flex"
            alignItems="center"
            borderRadius="25px"
            border="1px solid"
            borderColor={theme.palette.secondary.main}
            px={2}
            py={1}
            mt={3}
          >
            <Search style={{ color: "#64748B", fontSize: "25px" }} />
            <InputBase
              style={{
                fontSize: matches ? "14px" : "15px",
                fontFamily: "Segoe UI",
                textAlign: "center",
                width: "100%",
                paddingLeft: "10px",
                color: theme.palette.common.black,
              }}
              type="text"
              id="token-search-input"
              placeholder="Search token"
              value={searchQuery}
              ref={inputRef}
              onChange={handleInput}
              onKeyDown={handleEnter}
              inputProps={{ "aria-label": "search" }}
            />
          </Box>
        </Box>
        <Box
          paddingLeft="20px"
          paddingRight="20px"
          mt={3}
          display="flex"
          alignItems="center"
          justifyContent="space-between"
        >
          <Box
            color={theme.palette.common.black}
            fontFamily="Segoe UI"
            fontSize="17px"
          >
            Token Name
          </Box>
          <Box
            borderRadius="10px"
            paddingTop="2px"
            paddingLeft="3px"
            paddingRight="3px"
            paddingBottom="3px"
            bgcolor={theme.palette.secondary.main}
            fontFamily="Segoe UI"
            fontSize="20px"
          >
            <IconButton
              style={{
                padding: "2px",
              }}
              onClick={() => setInvertSearchOrder((iso) => !iso)}
            >
              {invertSearchOrder ? (
                <ArrowUpwardIcon
                  style={{ color: theme.palette.secondary.contrastText }}
                />
              ) : (
                <ArrowDownward
                  style={{ color: theme.palette.secondary.contrastText }}
                />
              )}
            </IconButton>
          </Box>
        </Box>
        <Divider
          style={{
            width: "100%",
            backgroundColor: theme.palette.secondary.main,
            marginTop: "10px",
            height: "1px",
          }}
        />
        <div style={{ flex: "1" }}>
          <CurrencyList
            height={100}
            showETH={showETH}
            currencies={filteredSortedTokens}
            onCurrencySelect={handleCurrencySelect}
            otherCurrency={otherSelectedCurrency}
            selectedCurrency={selectedCurrency}
            fixedListRef={fixedList}
          />
        </div>
      </div>
    </DialogContent>
  );
}

export default CurrencySearch;
