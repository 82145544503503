import { Box, Button, Container, Grid, Typography } from "@mui/material";
import { Stack } from "@mui/system";
import React from "react";
import treasurebg from "./assets/treasure-coins.png";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

function FinishedRounds() {
  return (
    <Container maxWidth="md">
      <Typography
        variant="h5"
        fontFamily="Russo One"
        mt={3}
        color="white"
        textAlign="center"
      >
        Finished Rounds
      </Typography>

      <Box bgcolor="#202A80" my={2} borderRadius="15px" p={2}>
        <Box border="1px solid #F1620A" borderRadius="15px">
          <Stack alignItems="center" p={2}>
            <Stack
              alignItems="center"
              direction="row"
              justifyContent="space-between"
              width="100%"
              borderBottom="2px solid rgba(0,0,0,0.4)"
              pb={1}
            >
              {" "}
              <Stack alignItems="center" direction="row" spacing={2}>
                <Typography
                  variant="body1"
                  fontWeight="bold"
                  color="#7381EB"
                  fontSize={{ xs: "0.8rem", sm: "1rem" }}
                >
                  Round
                </Typography>
                <Typography
                  variant="body1"
                  bgcolor="#F1620A"
                  borderRadius="47px"
                  color="white"
                  px={1}
                  py={0.2}
                  fontSize={{ xs: "0.8rem", sm: "1rem" }}
                >
                  695
                </Typography>
              </Stack>
              <Typography
                variant="body1"
                color="#F1620A"
                fontSize={{ xs: "0.8rem", sm: "1rem" }}
              >
                Drawn Oct 22, 2022, 5:00 PM{" "}
              </Typography>
            </Stack>
            <Typography variant="body1" mt={1} color="white">
              Winning Number
            </Typography>
            <Grid
              container
              justifyContent={{ xs: "center", sm: "space-around" }}
              my={4}
            >
              <Grid item xs={4} sm={3} md={2}>
                <Stack
                  alignItems="center"
                  justifyContent="center"
                  sx={{
                    background:
                      "radial-gradient(122.73% 122.73% at 90.91% 122.73%, #7A0000 33.09%, #F65F64 100%)",
                    width: "100px",
                    height: "100px",
                    borderRadius: "100%",
                    boxShadow: "2px 2px 13px 0px rgba(0,0,0,0.75)",
                  }}
                >
                  <Stack
                    alignItems="center"
                    justifyContent="center"
                    border="1px solid white"
                    borderRadius="100%"
                    sx={{ width: "70px", height: "70px" }}
                  >
                    <Stack
                      alignItems="center"
                      justifyContent="center"
                      bgcolor="white"
                      borderRadius="100%"
                      sx={{
                        background:
                          "radial-gradient(122.73% 122.73% at 90.91% 122.73%, #EC9396 33.09%, #ffffff 100%)",
                        width: "60px",
                        height: "60px",
                        p: "5px",
                      }}
                    >
                      <Typography
                        variant="h5"
                        fontFamily="Russo One"
                        color="black"
                        textAlign="center"
                      >
                        9
                      </Typography>
                    </Stack>
                  </Stack>
                </Stack>
              </Grid>
              <Grid item xs={4} sm={3} md={2}>
                <Stack
                  alignItems="center"
                  justifyContent="center"
                  sx={{
                    background:
                      "radial-gradient(86.36% 86.36% at 22.73% 13.64%, #5EE9CE 0%, #03563A 100%)",
                    width: "100px",
                    height: "100px",
                    borderRadius: "100%",
                    boxShadow: "2px 2px 13px 0px rgba(0,0,0,0.75)",
                  }}
                >
                  <Stack
                    alignItems="center"
                    justifyContent="center"
                    border="1px solid white"
                    borderRadius="100%"
                    sx={{ width: "70px", height: "70px" }}
                  >
                    <Stack
                      alignItems="center"
                      justifyContent="center"
                      bgcolor="white"
                      borderRadius="100%"
                      sx={{
                        background:
                          "radial-gradient(122.73% 122.73% at 90.91% 122.73%, #5EE9CE 33.09%, #ffffff 100%)",
                        width: "60px",
                        height: "60px",
                        p: "5px",
                      }}
                    >
                      <Typography
                        variant="h5"
                        fontFamily="Russo One"
                        color="black"
                        textAlign="center"
                      >
                        5
                      </Typography>
                    </Stack>
                  </Stack>
                </Stack>
              </Grid>
              <Grid item xs={4} sm={3} md={2}>
                <Stack
                  alignItems="center"
                  justifyContent="center"
                  sx={{
                    background:
                      "radial-gradient(90.91% 90.91% at 24.24% 9.09%, #FAB668 0%, #B02301 100%)",
                    width: "100px",
                    height: "100px",
                    borderRadius: "100%",
                    boxShadow: "2px 2px 13px 0px rgba(0,0,0,0.75)",
                  }}
                >
                  <Stack
                    alignItems="center"
                    justifyContent="center"
                    border="1px solid white"
                    borderRadius="100%"
                    sx={{ width: "70px", height: "70px" }}
                  >
                    <Stack
                      alignItems="center"
                      justifyContent="center"
                      bgcolor="white"
                      borderRadius="100%"
                      sx={{
                        background:
                          "radial-gradient(122.73% 122.73% at 90.91% 122.73%, #FAB668 33.09%, #ffffff 100%)",
                        width: "60px",
                        height: "60px",
                        p: "5px",
                      }}
                    >
                      <Typography
                        variant="h5"
                        fontFamily="Russo One"
                        color="black"
                        textAlign="center"
                      >
                        4
                      </Typography>
                    </Stack>
                  </Stack>
                </Stack>
              </Grid>
              <Grid item xs={4} sm={3} md={2}>
                <Stack
                  alignItems="center"
                  justifyContent="center"
                  sx={{
                    background:
                      "radial-gradient(83.33% 83.33% at 21.21% 16.67%, #3CBEF4 0%, #003680 100%)",
                    width: "100px",
                    height: "100px",
                    borderRadius: "100%",
                    boxShadow: "2px 2px 13px 0px rgba(0,0,0,0.75)",
                  }}
                >
                  <Stack
                    alignItems="center"
                    justifyContent="center"
                    border="1px solid white"
                    borderRadius="100%"
                    sx={{ width: "70px", height: "70px" }}
                  >
                    <Stack
                      alignItems="center"
                      justifyContent="center"
                      bgcolor="white"
                      borderRadius="100%"
                      sx={{
                        background:
                          "radial-gradient(122.73% 122.73% at 90.91% 122.73%, #3CBEF4 33.09%, #ffffff 100%)",
                        width: "60px",
                        height: "60px",
                        p: "5px",
                      }}
                    >
                      <Typography
                        variant="h5"
                        fontFamily="Russo One"
                        color="black"
                        textAlign="center"
                      >
                        1
                      </Typography>
                    </Stack>
                  </Stack>
                </Stack>
              </Grid>
              <Grid item xs={4} sm={3} md={2}>
                <Stack
                  alignItems="center"
                  justifyContent="center"
                  sx={{
                    background:
                      "radial-gradient(83.33% 83.33% at 24.24% 16.67%, #BFE88C 0%, #1A5302 100%)",
                    width: "100px",
                    height: "100px",
                    borderRadius: "100%",
                    boxShadow: "2px 2px 13px 0px rgba(0,0,0,0.75)",
                  }}
                >
                  <Stack
                    alignItems="center"
                    justifyContent="center"
                    border="1px solid white"
                    borderRadius="100%"
                    sx={{ width: "70px", height: "70px" }}
                  >
                    <Stack
                      alignItems="center"
                      justifyContent="center"
                      bgcolor="white"
                      borderRadius="100%"
                      sx={{
                        background:
                          "radial-gradient(122.73% 122.73% at 90.91% 122.73%, #BFE88C 33.09%, #ffffff 100%)",
                        width: "60px",
                        height: "60px",
                        p: "5px",
                      }}
                    >
                      <Typography
                        variant="h5"
                        fontFamily="Russo One"
                        color="black"
                        textAlign="center"
                      >
                        0
                      </Typography>
                    </Stack>
                  </Stack>
                </Stack>
              </Grid>
            </Grid>
          </Stack>
        </Box>
      </Box>
    </Container>
  );
}

export default FinishedRounds;
