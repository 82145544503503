import { useTheme } from "@mui/material";
import React, { useMemo, useState } from "react";
import { Currency, ETHER, Token } from "seek-swap-sdk";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import useHttpLocations from "../../../hooks/useHttpLocations";
import { WrappedTokenInfo } from "../../../state/lists/hooks";
const getTokenLogoURL = (address) =>
  `https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/smartchain/assets/${address}/logo.png`;
const BAD_SRCS = {};
function CoinLogo({ srcs, alt, size, ...rest }) {
  const [, refresh] = useState(0);
  const theme = useTheme();
  const src = srcs.find((s) => !BAD_SRCS[s]);
  if (src) {
    return (
      <img
        {...rest}
        alt={alt}
        src={src}
        width={size}
        height={size}
        onError={() => {
          if (src) BAD_SRCS[src] = true;
          refresh((i) => i + 1);
        }}
      />
    );
  }

  return <HelpOutlineIcon style={{ color: theme.palette.common.black }} />;
}

export default function CurrencyLogo({ currency, size = "20px" }) {
  const uriLocations = useHttpLocations(
    currency instanceof WrappedTokenInfo ? currency.logoURI : undefined
  );

  const srcs = useMemo(() => {
    if (currency === ETHER) return [];

    if (currency instanceof Token) {
      if (currency instanceof WrappedTokenInfo) {
        return [
          ...uriLocations,
          `/images/coins/${currency?.address ?? "token"}.png`,
          getTokenLogoURL(currency.address),
          "/favicon.png",
        ];
      }

      return [
        `/images/coins/${currency?.address ?? "token"}.png`,
        getTokenLogoURL(currency.address),
        "/favicon.png",
      ];
    }
    return [];
  }, [currency, uriLocations]);

  if (currency === ETHER) {
    return (
      <img
        src="/images/coins/bnb.png"
        width={size}
        style={{ marginLeft: "3px", marginRight: "5px" }}
      />
    );
  }
  return currency?.symbol ? (
    <CoinLogo
      size={size}
      srcs={srcs}
      alt={`${currency?.symbol ?? "token"} logo`}
    />
  ) : (
    <CoinLogo
      size={size}
      srcs={srcs}
      alt={`${currency?.symbol ?? "token"} logo`}
    />
  );
}
