import { Box, Button, Container, Grid, Typography } from "@mui/material";
import { Stack } from "@mui/system";
import React from "react";
import treasurebg from "./assets/treasure-coins.png";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

function HowToPlay() {
  return (
    <Container maxWidth="md">
      <Typography
        variant="h5"
        fontFamily="Russo One"
        mt={5}
        color="white"
        textAlign="center"
      >
        How to Play
      </Typography>
      <Typography variant="body1" mt={1} color="white" textAlign="center">
        If the digits on your tickets match the winning numbers in the correct
        order, you win a portion of the prize pool. Simple!
      </Typography>

      <Grid container my={4} spacing={4}>
        <Grid item xs={12} sm={6} md={4}>
          <Box
            bgcolor="#202A80"
            height="100%"
            my={2}
            borderRadius="15px"
            px={4}
            pt={4}
            position="relative"
          >
            <Typography
              variant="h3"
              fontFamily="Russo One"
              color="#F1620A"
              position="absolute"
              top="-20px"
            >
              01
            </Typography>
            <Typography
              variant="h6"
              sx={{ fontSize: "10px" }}
              fontFamily="Russo One"
              color="white"
            >
              Buy Tickets
            </Typography>
            <Typography
              variant="body1"
              mt={1}
              color="white"
              textAlign="justify"
            >
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry. Lorem Ipsum has been the industry's standard dummy text
              ever since the 1500s, when an unknown printer took a galley of
              type and scrambled it to make a type specimen book
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Box
            bgcolor="#202A80"
            height="100%"
            my={2}
            borderRadius="15px"
            px={4}
            pt={4}
            position="relative"
          >
            <Typography
              variant="h3"
              fontFamily="Russo One"
              color="#F1620A"
              position="absolute"
              top="-20px"
            >
              02
            </Typography>
            <Typography
              variant="h6"
              sx={{ fontSize: "10px" }}
              fontFamily="Russo One"
              color="white"
            >
              Wait for the Draw
            </Typography>
            <Typography
              variant="body1"
              mt={1}
              color="white"
              textAlign="justify"
            >
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry. Lorem Ipsum has been the industry's standard dummy text
              ever since the 1500s, when an unknown printer took a galley of
              type and scrambled it to make a type specimen book
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Box
            bgcolor="#202A80"
            height="100%"
            my={2}
            borderRadius="15px"
            px={4}
            pt={4}
            position="relative"
          >
            <Typography
              variant="h3"
              fontFamily="Russo One"
              color="#F1620A"
              position="absolute"
              top="-20px"
            >
              03
            </Typography>
            <Typography
              variant="h6"
              sx={{ fontSize: "10px" }}
              fontFamily="Russo One"
              color="white"
            >
              Check for Prizes
            </Typography>
            <Typography
              variant="body1"
              mt={1}
              color="white"
              textAlign="justify"
            >
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry. Lorem Ipsum has been the industry's standard dummy text
              ever since the 1500s, when an unknown printer took a galley of
              type and scrambled it to make a type specimen book
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
}

export default HowToPlay;
