import { Box, Button } from "@mui/material";
import { withStyles } from "@mui/styles";
import React from "react";
import { useLocation } from "react-router";
import { Link } from "react-router-dom";
import { styles } from "../../trade/styles/style";

export const TopNav = withStyles(styles)(({ classes }) => {
  const loc = useLocation();

  return (
    <>
      {" "}
      <Box my={4} className={classes.heading}>
        SHUNA SWAP
      </Box>
      <Box className={classes.box}>
        <Button
          component={Link}
          to="/"
          className={loc.pathname === "/" ? classes.activeBtn : classes.btn}
        >
          <span>Exchange</span>
        </Button>
        <Button
          component={Link}
          to="/synergyswap/pool"
          className={loc.pathname !== "/" ? classes.activeBtn : classes.btn}
        >
          {" "}
          <span>Liquidity</span>
        </Button>
      </Box>
    </>
  );
});
