import { useCallback, useContext, useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import useDebounce from "../../hooks/useDebounce";
import useIsWindowVisible from "../../hooks/useIsWindowVisible";
import { AppContext } from "../../utils/Utils";
import { updateBlockNumber } from "./actions";

export default function Updater() {
  const { chainId, web3Provider } = useContext(AppContext);

  const dispatch = useDispatch();

  const windowVisible = useIsWindowVisible();

  const [state, setState] = useState({
    chainId,
    blockNumber: null,
  });

  const blockNumberCallback = useCallback(
    (blockNumber) => {
      setState((s) => {
        if (chainId === s.chainId) {
          if (typeof s.blockNumber !== "number")
            return { chainId, blockNumber };
          return { chainId, blockNumber: Math.max(blockNumber, s.blockNumber) };
        }
        return s;
      });
    },
    [chainId, setState]
  );

  // attach/detach listeners
  useEffect(() => {
    if (!web3Provider || !chainId || !windowVisible) return undefined;

    setState({ chainId, blockNumber: null });

    web3Provider
      .getBlockNumber()
      .then(blockNumberCallback)
      .catch((error) =>
        console.error(
          `Failed to get block number for chainId: ${chainId}`,
          error
        )
      );

    web3Provider.on("block", blockNumberCallback);
    return () => {
      web3Provider.removeListener("block", blockNumberCallback);
    };
  }, [dispatch, chainId, web3Provider, blockNumberCallback, windowVisible]);

  const debouncedState = useDebounce(state, 100);

  useEffect(() => {
    if (
      !debouncedState.chainId ||
      !debouncedState.blockNumber ||
      !windowVisible
    )
      return;
    dispatch(
      updateBlockNumber({
        chainId: debouncedState.chainId,
        blockNumber: debouncedState.blockNumber,
      })
    );
  }, [
    windowVisible,
    dispatch,
    debouncedState.blockNumber,
    debouncedState.chainId,
  ]);

  return null;
}
